import { jsx as s } from "react/jsx-runtime";
import { forwardRef as r } from "react";
import { S as c } from "../../index-DoOsnuB6.js";
import { c as i } from "../../index-DFo-3WCJ.js";
import { c as p } from "../../index-Bm_zk_a7.js";
const m = i(
  "inline-flex h-s-4 items-center justify-center gap-sp-1 whitespace-nowrap rounded-full px-sp-2 text-caption-highlight",
  {
    variants: {
      variant: {
        danger: "bg-tag-bg-danger text-tag-content-danger",
        warning: "bg-tag-bg-warning text-tag-content-warning",
        success: "bg-tag-bg-success text-tag-content-success",
        status: "bg-tag-bg-status text-tag-content-status",
        pending: "bg-tag-bg-1 text-tag-content-1",
        promotion1: "bg-tag-bg-2 text-tag-content-2",
        promotion2: "bg-tag-bg-3 text-tag-content-3"
      }
    }
  }
), b = r(
  ({ children: t, className: a, variant: n = "status", asChild: g, ...e }, o) => /* @__PURE__ */ s(
    g ? c : "span",
    {
      className: p(m({ variant: n, className: a })),
      ...e,
      ref: o,
      children: t
    }
  )
);
b.displayName = "Tag";
export {
  b as Tag
};
