const t = "group inline-grid items-center justify-items-center whitespace-nowrap transition disabled:pointer-events-none", o = {
  primary: "bg-[--color-button-primary-bg] text-[--color-button-primary-content] hover:bg-[--color-button-primary-bg-hover] active:bg-[--color-button-primary-bg-pressed] disabled:bg-[--color-button-primary-bg-disabled] disabled:text-[--color-button-primary-content-disabled]",
  danger: "bg-[--color-button-danger-bg] text-[--color-button-danger-content] hover:bg-[--color-button-danger-bg-hover] active:bg-[--color-button-danger-bg-pressed] disabled:bg-[--color-button-danger-bg-disabled] disabled:text-[--color-button-danger-content-disabled]",
  outline: "border border-[--color-button-outline-stroke] bg-[--color-button-outline-bg] text-[--color-button-outline-content] hover:bg-[--color-button-outline-bg-hover] active:bg-[--color-button-outline-bg-pressed] disabled:border-[--color-button-outline-stroke-disabled] disabled:bg-[--color-button-outline-bg-disabled] disabled:text-[--color-button-outline-content-disabled]",
  extra: "bg-[--color-button-extra-bg] text-[--color-button-extra-content] hover:bg-[--color-button-extra-bg-hover] active:bg-[--color-button-extra-bg-pressed] disabled:bg-[--color-button-extra-bg-disabled] disabled:text-[--color-button-extra-content-disabled]",
  ghost: "bg-[--color-button-ghost-bg] text-[--color-button-ghost-content] hover:bg-[--color-button-ghost-bg-hover] active:bg-[--color-button-ghost-bg-pressed] disabled:bg-[--color-button-ghost-bg-disabled] disabled:text-[--color-button-ghost-content-disabled]"
};
export {
  t as buttonBase,
  o as buttonSharedVariants
};
