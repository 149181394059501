import { jsxs as n, jsx as e } from "react/jsx-runtime";
import { forwardRef as b, useState as h } from "react";
import { c } from "../../index-Bm_zk_a7.js";
import { focusRing as m } from "../../utils/focus-ring.js";
import { Icon as t } from "../icon/icon.js";
const f = b(
  ({ className: i, hasError: l, type: r, ...p }, d) => {
    const [o, u] = h(r), a = r === "search", s = r === "password";
    return /* @__PURE__ */ n("div", { className: "relative", children: [
      /* @__PURE__ */ e(
        "input",
        {
          type: s ? o : r,
          className: c(
            // Base
            "relative h-s-6 w-full appearance-none rounded-sm border bg-transparent pl-sp-4 pr-sp-3 text-body1 text-[--color-input-content] outline-none transition placeholder:text-[--color-input-placeholder]",
            // Border colors
            "border-[--color-input-stroke] hover:border-[--color-input-stroke-hover] focus:border-[--color-input-stroke-active]",
            // Disabled
            "disabled:border-[--color-input-stroke-disabled] disabled:text-[--color-input-content-disabled] placeholder:disabled:text-[--color-input-placeholder-disabled]",
            // Error
            {
              "border-[--color-input-stroke-danger] hover:border-[--color-input-stroke-danger] focus:border-[--color-input-stroke-danger]": l
            },
            {
              // Padding for search icon
              "pl-[calc(var(--fui-sp-3)+var(--fui-s-3)+var(--fui-sp-2))]": a
            },
            {
              // Padding for password icon
              "pr-[calc(var(--fui-sp-3)+var(--fui-s-3)+var(--fui-sp-2))]": s
            },
            // Add custom xmark button when type is set to "search"
            "[&::-webkit-search-cancel-button]:size-s-3 [&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-cancel-button]:bg-search-input",
            i
          ),
          ref: d,
          ...p
        }
      ),
      a && /* @__PURE__ */ e("div", { className: "pointer-events-none absolute left-sp-3 top-1/2 -translate-y-1/2", children: /* @__PURE__ */ e(t, { name: "search" }) }),
      s && /* @__PURE__ */ e("div", { className: "absolute right-sp-3 top-1/2 flex -translate-y-1/2 items-center justify-center", children: /* @__PURE__ */ n(
        "button",
        {
          className: c("size-fit rounded-xs", m),
          onClick: () => u(o === "password" ? "text" : "password"),
          children: [
            /* @__PURE__ */ e("span", { className: "sr-only", children: o === "password" ? "Show password" : "Hide password" }),
            o === "password" ? /* @__PURE__ */ e(t, { name: "eye" }) : /* @__PURE__ */ e(t, { name: "eye-close" })
          ]
        }
      ) })
    ] });
  }
);
f.displayName = "Input";
export {
  f as Input
};
