import { differenceInMilliseconds } from "date-fns";

import { analytics } from "@flare/analytics";

import { getFips } from "@/components/Funnel/utils/get-fips";
import { BiEventsNames } from "@/services/analytics/event-names";
import { getAnonymousId } from "@/services/analytics/helpers";
import { CAMPAIGN_DATA_STORAGE_KEY, getParsedItem } from "@/services/storage";
import { DEFAULT_CAMPAIGN_DATA } from "@/services/store/campaign-data-context";

import type { FunnelAnswers, Steps } from "../config";
import { getFunnelMeta } from "../funnel.utils";
import { getLeadScoreParams } from "../utils/getLeadScoreParams";

import { predictBiddingModelScore } from "./predictBiddingModelScore";
import { saveModelResultToMeta } from "./saveModelResultToMeta";
import { sendWebLeadScoreResultEvent } from "./sendWebLeadScoreResultEvent";

interface Context {
  answers: FunnelAnswers;
  step: Steps;
}

function getDayPart(date: Date): string {
  const hour = date.getHours();

  if (hour >= 0 && hour <= 3) {
    return "LATE_NIGHT";
  } else if (hour >= 4 && hour <= 7) {
    return "EARLY_MORNING";
  } else if (hour >= 8 && hour <= 10) {
    return "MID_MORNING";
  } else if (hour >= 11 && hour <= 13) {
    return "MID_DAY";
  } else if (hour >= 14 && hour <= 17) {
    return "AFTERNOON";
  } else if (hour >= 18 && hour <= 21) {
    return "EVENING";
  } else {
    return "LATE_NIGHT";
  }
}

function getDayPartBusiness(date: Date): string {
  const hour = date.getHours();

  if (hour >= 6 && hour <= 11) {
    return "MORNING";
  } else if (hour >= 12 && hour <= 18) {
    return "AFTERNOON";
  } else {
    return "OFF";
  }
}

function isWeekend(date: Date): boolean {
  return [0, 6].includes(date.getDay());
}

export async function calculateBiddingModel(context: Context) {
  const meta = getFunnelMeta();

  try {
    const leadScoreParams = getLeadScoreParams(context.answers);
    const currentCampaignData =
      getParsedItem(CAMPAIGN_DATA_STORAGE_KEY) ?? DEFAULT_CAMPAIGN_DATA;

    const analyticsPersistentData = analytics.getPersistentData();

    const sessionDuration = leadScoreParams.sessionStartTime
      ? differenceInMilliseconds(
          new Date(),
          new Date(leadScoreParams.sessionStartTime),
        )
      : undefined;

    const anonymousId = await getAnonymousId();

    const biddingModelScore = await predictBiddingModelScore({
      browser: leadScoreParams.browser,
      deviceName: leadScoreParams.deviceModel,
      deviceBrand: leadScoreParams.deviceVendor,
      deviceClass: leadScoreParams.deviceType,
      subPractice: context.answers.subPractice,
      serviceType: context.answers.serviceType,
      caseUrgency: context.answers.caseUrgency,
      moreInfoLength: context.answers.moreInfo?.length,
      zipcode: context.answers.zipCode,
      emailDomain: context.answers.email?.split("@")[1],
      sessionDow: context.answers.sessionStartTime
        ? new Date(context.answers.sessionStartTime).getDay()
        : undefined,
      os: leadScoreParams.osName,
      countyFips: Number(
        getFips({
          county: context.answers.county,
          stateCode: context.answers.state,
        }),
      ),
      browserVersion: leadScoreParams.browserVersion,
      hourOfDay:
        context.answers.sessionStartTime &&
        new Date(context.answers.sessionStartTime).getHours(),
      isWeekend:
        context.answers.sessionStartTime &&
        isWeekend(new Date(context.answers.sessionStartTime)),
      dayPartFine: context.answers.sessionStartTime
        ? getDayPart(new Date(context.answers.sessionStartTime))
        : undefined,
      dayPartBusiness: context.answers.sessionStartTime
        ? getDayPartBusiness(new Date(context.answers.sessionStartTime))
        : undefined,
      contextPagePath: window.location.pathname,
      utmSource: currentCampaignData.source,
      subPracticeFromMatter: context.answers.subPractice,
      msFromSessionStartToCalendarSuccessEvent: sessionDuration,
      pageType: analyticsPersistentData.page_type as string,
      stateByLeadSelection: context.answers.state,
      ipCity: analyticsPersistentData.ip_city as string,
      ipState: analyticsPersistentData.ip_state as string,
      msFromFirstPresentedSlotToSelectedSlot:
        context.answers.eventStartTime && meta.firstPresentedTimeSlotStartTime
          ? Math.abs(
              differenceInMilliseconds(
                new Date(meta.firstPresentedTimeSlotStartTime),
                new Date(context.answers.eventStartTime),
              ),
            )
          : undefined,
      hostname: window.location.hostname,
      userAgent: navigator.userAgent,
      anonymousId: anonymousId || undefined,
      step: context.step,
    });

    sendWebLeadScoreResultEvent({
      practice: context.answers.practice,
      subPractice: context.answers.subPractice,
      currentStepKey: context.step,
      modelName: "Roni",
      version: "v1",
      leadScoreParams,
      intent: "medium",
      score: biddingModelScore.conversionScore,
      modelScore: biddingModelScore,
    });

    saveModelResultToMeta({
      name: "Roni",
      score: biddingModelScore.conversionScore,
      version: "v1",
      step: context.step,
      modelScore: biddingModelScore,
    });
  } catch (error) {
    analytics.track(BiEventsNames.WebFunnelError, {
      current_step_key: context.step,
      error_type: "book_slot",
      error_message:
        error instanceof Error
          ? error.message
          : "calculateBiddingModel unknown error",
      practice: context.answers.practice,
    });
  }
}
