import { jsxs as M, jsx as a } from "react/jsx-runtime";
import * as r from "react";
import { forwardRef as O } from "react";
import { u as A } from "../../index-DoOsnuB6.js";
import { c as B } from "../../index-CwaZVody.js";
import { u as H, c as E } from "../../index-47DXPxf5.js";
import { u as K } from "../../index-BZPx6jYI.js";
import { u as L } from "../../index-CI9iLup9.js";
import { P as q } from "../../index-BvsBDqN-.js";
import { P as S } from "../../index-C8BDXGwJ.js";
import { c as T } from "../../index-Bm_zk_a7.js";
import { focusRing as X } from "../../utils/focus-ring.js";
import { Icon as I } from "../icon/icon.js";
var P = "Checkbox", [F, se] = B(P), [$, G] = F(P), w = r.forwardRef(
  (e, i) => {
    const {
      __scopeCheckbox: t,
      name: n,
      checked: b,
      defaultChecked: c,
      required: u,
      disabled: l,
      value: f = "on",
      onCheckedChange: x,
      form: h,
      ...C
    } = e, [d, p] = r.useState(null), v = A(i, (o) => p(o)), g = r.useRef(!1), R = d ? h || !!d.closest("form") : !0, [k = !1, y] = H({
      prop: b,
      defaultProp: c,
      onChange: x
    }), j = r.useRef(k);
    return r.useEffect(() => {
      const o = d == null ? void 0 : d.form;
      if (o) {
        const m = () => y(j.current);
        return o.addEventListener("reset", m), () => o.removeEventListener("reset", m);
      }
    }, [d, y]), /* @__PURE__ */ M($, { scope: t, state: k, disabled: l, children: [
      /* @__PURE__ */ a(
        S.button,
        {
          type: "button",
          role: "checkbox",
          "aria-checked": s(k) ? "mixed" : k,
          "aria-required": u,
          "data-state": z(k),
          "data-disabled": l ? "" : void 0,
          disabled: l,
          value: f,
          ...C,
          ref: v,
          onKeyDown: E(e.onKeyDown, (o) => {
            o.key === "Enter" && o.preventDefault();
          }),
          onClick: E(e.onClick, (o) => {
            y((m) => s(m) ? !0 : !m), R && (g.current = o.isPropagationStopped(), g.current || o.stopPropagation());
          })
        }
      ),
      R && /* @__PURE__ */ a(
        J,
        {
          control: d,
          bubbles: !g.current,
          name: n,
          value: f,
          checked: k,
          required: u,
          disabled: l,
          form: h,
          style: { transform: "translateX(-100%)" },
          defaultChecked: s(c) ? !1 : c
        }
      )
    ] });
  }
);
w.displayName = P;
var N = "CheckboxIndicator", _ = r.forwardRef(
  (e, i) => {
    const { __scopeCheckbox: t, forceMount: n, ...b } = e, c = G(N, t);
    return /* @__PURE__ */ a(q, { present: n || s(c.state) || c.state === !0, children: /* @__PURE__ */ a(
      S.span,
      {
        "data-state": z(c.state),
        "data-disabled": c.disabled ? "" : void 0,
        ...b,
        ref: i,
        style: { pointerEvents: "none", ...e.style }
      }
    ) });
  }
);
_.displayName = N;
var J = (e) => {
  const { control: i, checked: t, bubbles: n = !0, defaultChecked: b, ...c } = e, u = r.useRef(null), l = K(t), f = L(i);
  r.useEffect(() => {
    const h = u.current, C = window.HTMLInputElement.prototype, p = Object.getOwnPropertyDescriptor(C, "checked").set;
    if (l !== t && p) {
      const v = new Event("click", { bubbles: n });
      h.indeterminate = s(t), p.call(h, s(t) ? !1 : t), h.dispatchEvent(v);
    }
  }, [l, t, n]);
  const x = r.useRef(s(t) ? !1 : t);
  return /* @__PURE__ */ a(
    "input",
    {
      type: "checkbox",
      "aria-hidden": !0,
      defaultChecked: b ?? x.current,
      ...c,
      tabIndex: -1,
      ref: u,
      style: {
        ...e.style,
        ...f,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function s(e) {
  return e === "indeterminate";
}
function z(e) {
  return s(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
var D = w, Q = _;
const U = O(({ checked: e, className: i, hasError: t, ...n }, b) => /* @__PURE__ */ a(
  D,
  {
    ref: b,
    checked: e,
    className: T(
      // Base
      "peer flex size-s-2 shrink-0 items-center justify-center rounded-xs border border-[--color-checkbox-stroke-unchecked] bg-[--color-checkbox-bg-unchecked]",
      // Checked
      "enabled:data-[state=checked]:border-[--color-checkbox-bg-checked] enabled:data-[state=checked]:bg-[--color-checkbox-bg-checked] enabled:data-[state=checked]:text-[--color-checkbox-content-checked]",
      // Indeterminate
      "enabled:data-[state=indeterminate]:border-[--color-checkbox-bg-checked]  enabled:data-[state=indeterminate]:bg-[--color-checkbox-bg-checked]  enabled:data-[state=indeterminate]:text-[--color-checkbox-content-checked]",
      // Hover
      "enabled:hover:bg-[--color-checkbox-bg-unchecked-hover] enabled:hover:data-[state=checked]:border-[--color-checkbox-bg-checked-hover] enabled:hover:data-[state=indeterminate]:border-[--color-checkbox-bg-checked-hover] enabled:hover:data-[state=checked]:bg-[--color-checkbox-bg-checked-hover] enabled:hover:data-[state=indeterminate]:bg-[--color-checkbox-bg-checked-hover]",
      // Disabled
      "disabled:border-[--color-checkbox-stroke-unchecked-disabled] disabled:bg-[--color-checkbox-bg-unchecked-disabled] disabled:text-[--color-checkbox-content-checked-disabled] disabled:data-[state=checked]:border-[--color-checkbox-bg-unchecked-disabled] disabled:data-[state=indeterminate]:border-[--color-checkbox-bg-unchecked-disabled]",
      // Error
      {
        "border-[--color-checkbox-stroke-danger] enabled:data-[state=indeterminate]:border-[--color-checkbox-stroke-danger] enabled:data-[state=checked]:border-[--color-checkbox-stroke-danger]": t
      },
      // Focus
      X,
      i
    ),
    ...n,
    children: /* @__PURE__ */ a(Q, { children: e === "indeterminate" ? /* @__PURE__ */ a(I, { size: "sm", name: "minus" }) : /* @__PURE__ */ a(I, { size: "sm", name: "check" }) })
  }
));
U.displayName = D.displayName;
export {
  U as Checkbox
};
