/**
 * @file components-sections.ts
 *
 * @desc This file is used to define the sections and items that will be displayed in the builder sidebar.
 */

export const V2_HEADING = "v2: Heading";
export const V2_TEXT = "v2: Text";
export const FUNNEL_BACK_BUTTON = "Back Button";
export const FUNNEL_NEXT_BUTTON = "Next Button";
export const FUNNEL_NEXT_BUTTON_WITH_DISABLED = "Next Button With Disabled";
export const FUNNEL_SKIP_BUTTON = "Skip Button";
export const FUNNEL_TEXT_FIELD = "Text Field";
export const FUNNEL_CHOICE_BOX = "Choice Box";
export const FUNNEL_CHOICE_BOX_ITEM = "Choice Box Item";
export const FUNNEL_CHECKBOX = "v2: Checkbox";
export const LP_FUNNEL_STEP_FORM = "LP Funnel Step Form";
export const FUNNEL_DATE_PICKER = "Date Picker";
export const LIMITED_SCOPE_DRAWER_TRIGGER = "Limited Scope Drawer Trigger";
export const LIMITED_SCOPE_DIALOG_TRIGGER = "Limited Scope Dialog Trigger";

export const builderComponentsSections = [
  {
    name: "Typography",
    items: [{ name: V2_HEADING }, { name: V2_TEXT }],
  },
  {
    name: "Funnel - V2",
    items: [
      { name: FUNNEL_BACK_BUTTON },
      { name: FUNNEL_NEXT_BUTTON },
      { name: FUNNEL_NEXT_BUTTON_WITH_DISABLED },
      { name: FUNNEL_SKIP_BUTTON },
      { name: FUNNEL_TEXT_FIELD },
      { name: FUNNEL_CHOICE_BOX },
      { name: FUNNEL_CHOICE_BOX_ITEM },
      { name: FUNNEL_CHECKBOX },
      { name: LP_FUNNEL_STEP_FORM },
      { name: FUNNEL_DATE_PICKER },
      { name: LIMITED_SCOPE_DRAWER_TRIGGER },
      { name: LIMITED_SCOPE_DIALOG_TRIGGER },
    ],
  },
];
