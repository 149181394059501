import { isBrowser } from "@/utils/isBrowser";

export const getItem = (key: string) => {
  if (!isBrowser()) {
    return null;
  }

  return window?.localStorage.getItem(key);
};

export const setItem = (key: string, value: string) => {
  if (!isBrowser()) {
    return null;
  }

  return window?.localStorage.setItem(key, value);
};

export const getParsedItem = (key: string) => {
  const itemData = getItem(key);
  if (!itemData) {
    return itemData;
  }
  try {
    return JSON.parse(itemData);
  } catch (err) {
    console.error(err);
  }

  return itemData;
};

export const setParsedItem = (key: string, value: Record<string, unknown>) => {
  return setItem(key, JSON.stringify(value));
};
