import { i18n } from "@/services/locale/i18n";
import { promiseWithMinimumDelay } from "@/utils/promiseWithMinimumDelay";

import { IntakeConfirmationInterlude } from "../../intake-confirmation-interlude";
import { Interlude } from "../../Interlude";
import { calculateBiddingModel } from "../../visitor-checks/calculateBiddingModel";
import { calculateLeadScore } from "../../visitor-checks/calculateLeadScore";
import { calculateLssQualification } from "../../visitor-checks/calculateLssQualification";
import { checkAttorneyAvailability } from "../../visitor-checks/checkAttorneyAvailability";
import { checkCmnEligibility } from "../../visitor-checks/checkCmnEligibility";
import { getVisitorStatusCode } from "../../visitor-checks/getVisitorStatusCode";
import { interludeContent } from "../config.common";
import { DELAY_MS, stepsNames } from "../config.const";
import type { RenderInterludeArgs, SubPractices } from "../config.types";

import { isFamilyDirectToLssFlow } from "./common-family";

export const familyChildSupport: SubPractices = {
  firstScreen: "service-type",
  progressBarSections: [
    {
      name: "case-details",
      range: [20, 40],
    },
    {
      name: "preferences",
      range: [50, 70],
    },
    {
      name: "book-call",
      range: [80, 100],
    },
  ],
  screens: {
    "service-type": {
      next: async () => "zip-code",
      progressBarSection: "case-details",
    },
    "zip-code": {
      next: async ({ answers, experiments, meta }) => {
        const { abTestAttorneyAvailability, abTestMarathon2 } = experiments;
        const { state, county } = answers;
        const { skip } = meta;

        if (skip) {
          return "state-selection";
        }

        try {
          const isAttorneyAvailable =
            abTestAttorneyAvailability && county
              ? await checkAttorneyAvailability(answers)
              : true;

          if (state === "unsupported" || !isAttorneyAvailable) {
            return "unsupported-county";
          }

          if (abTestMarathon2) {
            return "main-goal";
          }
        } catch (e) {
          return abTestMarathon2 ? "case-urgency" : "location-confirmation";
        }

        return abTestMarathon2 ? "case-urgency" : "location-confirmation";
      },
      progressBarSection: "preferences",
    },
    "state-selection": {
      next: async ({ answers, experiments }) => {
        const { abTestMarathon2 } = experiments;
        const { state } = answers;

        if (state === "unsupported") {
          return "unsupported";
        }

        return abTestMarathon2 ? "case-urgency" : "location-confirmation";
      },
      progressBarSection: "preferences",
    },
    unsupported: {
      next: async () => "unsupported-confirmed",
      sanitize: true,
    },
    "unsupported-confirmed": {
      next: async () => "unsupported-confirmed",
    },
    "location-confirmation": {
      next: async () => "case-urgency",
      progressBarSection: "preferences",
    },
    "main-goal": {
      next: async () => "case-urgency",
      progressBarSection: "preferences",
    },
    "case-urgency": {
      next: async () => "more-info",
      progressBarSection: "preferences",
    },
    "more-info": {
      next: async ({ answers, experiments }) => {
        const { abTestCmnMidIntent } = experiments;
        calculateLeadScore({ answers, step: stepsNames["more-info"] });
        calculateBiddingModel({
          answers,
          step: stepsNames["more-info"],
        });

        if (abTestCmnMidIntent) {
          checkCmnEligibility({ answers });
        }

        if (
          isFamilyDirectToLssFlow({
            answers,
            experiments,
          })
        ) {
          const isQualifiedForLss = await promiseWithMinimumDelay(
            calculateLssQualification(answers),
            DELAY_MS,
          );

          if (isQualifiedForLss) return "has-minor-children";
        }

        return "create-meeting";
      },
      renderInterlude: ({ answers, experiments }: RenderInterludeArgs) => {
        return isFamilyDirectToLssFlow({ answers, experiments }) ? (
          <Interlude heading={i18n.t("FUNNEL_INTERLUDE_JUST_A_FEW_MOMENTS")} />
        ) : null;
      },
      progressBarSection: "preferences",
    },
    "create-meeting": {
      next: async ({ answers }) =>
        answers?.eventStartTime === "call_me_now"
          ? "contact-info-call-now"
          : "contact-info",
      progressBarSection: "book-call",
    },
    "contact-info": {
      next: async ({ answers }) => {
        calculateBiddingModel({
          answers,
          step: "contact-info",
        });

        await promiseWithMinimumDelay(
          calculateLssQualification(answers),
          DELAY_MS,
        );

        return "intake-confirmation";
      },
      sanitize: true,
      renderInterlude: () => (
        <IntakeConfirmationInterlude
          heading={i18n.t("FUNNEL_INTERLUDE_CONFIRMING_YOUR_CALL")}
        />
      ),
      isAuth: true,
      progressBarSection: "book-call",
    },
    "contact-info-call-now": {
      next: async () => "intake-confirmation-call-now",
      sanitize: true,
      renderInterlude: () => (
        <IntakeConfirmationInterlude
          heading={i18n.t("FUNNEL_INTERLUDE_CONFIRMING_YOUR_CALL")}
        />
      ),
      isAuth: true,
    },
    "contact-info-dlss": {
      next: async () => {
        return "lss-checkout";
      },
      sanitize: true,
      isAuth: true,
    },
    "intake-confirmation": {
      next: async () => "lss-intro",
    },
    "intake-confirmation-call-now": {
      next: async () => undefined,
    },
    "lss-intro": {
      next: async () => "has-minor-children",
    },
    // Digital Intake steps
    "has-minor-children": {
      next: async ({ answers }) => {
        const { hasMinorChildren } = answers;
        if (hasMinorChildren === "Yes") return "children-info";
        if (hasMinorChildren === "No") return "opposing-party";
      },
      isUpdate: true,
    },
    "children-info": {
      next: async () => "children-safety",
    },
    "children-safety": {
      next: async () => "child-protective-services",
    },
    "child-protective-services": {
      next: async () => "opposing-party",
    },
    "opposing-party": {
      next: async () => "has-upcoming-court-event",
    },
    "has-upcoming-court-event": {
      next: async ({ answers }) => {
        const { hasUpcomingCourtEvent } = answers;
        if (hasUpcomingCourtEvent === "Yes") return "upcoming-event-date";
        if (hasUpcomingCourtEvent === "No") return "currently-represented";
        if (hasUpcomingCourtEvent === "I have a different event")
          return "upcoming-event-date";
      },
    },
    "upcoming-event-date": {
      next: async () => "currently-represented",
    },
    "currently-represented": {
      next: async ({ answers }) => {
        const statusCodePromise = getVisitorStatusCode({
          answers,
          currentStep: stepsNames["currently-represented"],
        });

        const statusCode = await promiseWithMinimumDelay(
          statusCodePromise,
          answers?.moreInfo ? DELAY_MS : 0,
        );

        if (statusCode) {
          answers.visitorStatusCode = statusCode;
          if (statusCode !== "ELG1" && statusCode !== "ERROR") {
            return "out-of-scope";
          }
        }

        return answers.moreInfo ? "book-lss" : "additional-info";
      },
      isUpdate: true,
      renderInterlude: ({ answers, experiments }: RenderInterludeArgs) => {
        const { heading, subHeading } = isFamilyDirectToLssFlow({
          answers,
          experiments,
        })
          ? interludeContent.directToLss
          : interludeContent.default;

        return answers?.moreInfo ? (
          <Interlude heading={heading} subHeading={subHeading} />
        ) : null;
      },
    },
    "additional-info": {
      next: async ({ answers }) => {
        const statusCodePromise = getVisitorStatusCode({
          answers,
          currentStep: stepsNames["additional-info"],
        });

        const statusCode = await promiseWithMinimumDelay(
          statusCodePromise,
          answers?.moreInfo ? DELAY_MS : 0,
        );
        if (statusCode) {
          answers.visitorStatusCode = statusCode;
        }

        return "book-lss";
      },
      isUpdate: true,
      renderInterlude: ({ answers, experiments }: RenderInterludeArgs) => {
        const { heading, subHeading } = isFamilyDirectToLssFlow({
          answers,
          experiments,
        })
          ? interludeContent.directToLss
          : interludeContent.default;

        return <Interlude heading={heading} subHeading={subHeading} />;
      },
    },
    // Direct LSS steps
    "book-lss": {
      next: async ({ answers, experiments }) => {
        if (isFamilyDirectToLssFlow({ answers, experiments })) {
          return "contact-info-dlss";
        }
        return "lss-checkout";
      },
    },
    "lss-checkout": {
      next: async ({ meta }) => {
        const { checkoutStatus } = meta;
        return checkoutStatus;
      },
    },
    "slot-is-taken": {
      next: async () => "book-lss",
    },
    "payment-declined": {
      next: async () => "lss-checkout",
    },
    "lss-confirmation": {
      next: async () => undefined,
    },
    "out-of-scope": {
      next: async () => undefined,
    },
    "unsupported-county": {
      next: async () => undefined,
    },
  },
};
