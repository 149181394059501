import { i18n } from "@/services/locale/i18n";
import { promiseWithMinimumDelay } from "@/utils/promiseWithMinimumDelay";

import { Interlude } from "../../Interlude";
import { calculateLssQualification } from "../../visitor-checks/calculateLssQualification";
import { DELAY_MS } from "../config.const";
import type { SubPractices } from "../config.types";

import {
  immigrationBookLssScreens,
  immigrationEligibleForLssScreens,
  intakeConfirmationScreens,
} from "./common-immigration";

export const immigrationCitizenship: SubPractices = {
  firstScreen: "service-type",
  screens: {
    "service-type": {
      next: async ({ answers, experiments }) => {
        const { serviceType } = answers;
        const { abTestImmigrationEligibility, abTestMarathon1 } = experiments;
        if (abTestMarathon1) {
          if (serviceType === "I’m a green card holder") {
            return "married-to-citizen";
          }

          if (serviceType === "Something else") {
            return "out-of-scope";
          }

          return "deportation-or-removal";
        }

        if (abTestImmigrationEligibility) {
          if (serviceType === "I’m a green card holder") {
            return "married-to-citizen";
          }

          if (serviceType === "Something else") {
            return "out-of-scope";
          }

          return "case-urgency";
        }

        return "applicant-type";
      },
    },
    "married-to-citizen": {
      next: async () => "green-card-duration",
    },
    "green-card-duration": {
      next: async ({ answers, experiments }) => {
        const { marriedToCitizen, greenCardDuration } = answers;
        const { abTestMarathon1 } = experiments;

        let isInScope = greenCardDuration === "4.5+ years";
        if (marriedToCitizen === "Yes") {
          isInScope = ["2-4.5 years", "4.5+ years"].includes(
            greenCardDuration!,
          );
        }
        return isInScope
          ? abTestMarathon1
            ? "deportation-or-removal"
            : "case-urgency"
          : "out-of-scope";
      },
    },
    "applicant-type": {
      next: async () => "case-urgency",
    },
    "case-urgency": {
      next: async ({ experiments, answers }) => {
        const {
          abTestImmigrationEligibility,
          abTestIsQualifiedForLssImmigration,
          abTestMarathon1,
        } = experiments;
        if (abTestMarathon1) {
          return "more-info";
        }

        if (abTestIsQualifiedForLssImmigration) {
          const isQualifiedForLss = await promiseWithMinimumDelay(
            calculateLssQualification(answers),
            DELAY_MS,
          );

          if (!isQualifiedForLss) {
            return "more-info";
          }

          return "currently-represented";
        }

        return abTestImmigrationEligibility ? "more-info" : "funding-source";
      },
      renderInterlude: ({ experiments }) => {
        const { abTestIsQualifiedForLssImmigration } = experiments;
        return abTestIsQualifiedForLssImmigration ? (
          <Interlude
            heading={i18n.t("FUNNEL_INTERLUDE_JUST_A_FEW_MORE_MOMENTS")}
          />
        ) : null;
      },
    },

    ...immigrationEligibleForLssScreens,

    "funding-source": {
      next: async () => "beneficiary-region",
    },
    "beneficiary-region": {
      next: async () => "more-info",
    },

    ...intakeConfirmationScreens,
    ...immigrationBookLssScreens,

    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
