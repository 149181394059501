import { i18n } from "@/services/locale/i18n";

import { IntakeConfirmationInterlude } from "../../intake-confirmation-interlude";
import { calculateBiddingModel } from "../../visitor-checks/calculateBiddingModel";
import { calculateLeadScore } from "../../visitor-checks/calculateLeadScore";
import { checkCmnEligibility } from "../../visitor-checks/checkCmnEligibility";
import { stepsNames } from "../config.const";
import type { SubPractices } from "../config.types";

export const familyElse: SubPractices = {
  firstScreen: "service-type",
  progressBarSections: [
    {
      name: "case-details",
      range: [20, 40],
    },
    {
      name: "preferences",
      range: [50, 70],
    },
    {
      name: "book-call",
      range: [80, 100],
    },
  ],
  screens: {
    "service-type": {
      next: async ({ answers }) => {
        const { serviceType } = answers;

        switch (serviceType?.toLowerCase()) {
          case "none of the above":
            return "zip-code";

          case "child protective services involvement":
            return "child-protective-services";

          default:
            return "out-of-scope";
        }
      },
      progressBarSection: "case-details",
    },
    "zip-code": {
      next: async ({ answers, meta }) => {
        const { state } = answers;
        const { skip } = meta;

        if (skip) {
          return "state-selection";
        }

        if (state === "unsupported") {
          return "unsupported-county";
        }

        return "location-confirmation";
      },
      progressBarSection: "preferences",
    },
    "state-selection": {
      next: async ({ answers, experiments }) => {
        const { abTestMarathon2 } = experiments;
        const { state } = answers;

        if (state === "unsupported") {
          return "unsupported";
        }

        return abTestMarathon2 ? "case-urgency" : "location-confirmation";
      },
      progressBarSection: "preferences",
    },
    unsupported: {
      next: async () => "unsupported-confirmed",
      sanitize: true,
    },
    "unsupported-confirmed": {
      next: async () => "unsupported-confirmed",
    },
    "location-confirmation": {
      next: async () => "case-urgency",
      progressBarSection: "preferences",
    },
    "case-urgency": {
      next: async () => "more-info",
      progressBarSection: "preferences",
    },
    "more-info": {
      next: async ({ answers, experiments }) => {
        const { abTestCmnMidIntent } = experiments;
        calculateLeadScore({
          answers,
          step: stepsNames["more-info"],
        });
        calculateBiddingModel({
          answers,
          step: stepsNames["more-info"],
        });

        if (abTestCmnMidIntent) {
          checkCmnEligibility({ answers });
        }

        return "create-meeting";
      },
      progressBarSection: "preferences",
    },
    "create-meeting": {
      next: async ({ answers }) =>
        answers?.eventStartTime === "call_me_now"
          ? "contact-info-call-now"
          : "contact-info",
      progressBarSection: "book-call",
    },
    "contact-info": {
      next: async ({ answers }) => {
        calculateBiddingModel({
          answers,
          step: "contact-info",
        });

        return "intake-confirmation";
      },
      sanitize: true,
      isAuth: true,
      renderInterlude: () => (
        <IntakeConfirmationInterlude
          heading={i18n.t("FUNNEL_INTERLUDE_CONFIRMING_YOUR_CALL")}
        />
      ),
      progressBarSection: "book-call",
    },

    "contact-info-call-now": {
      next: async () => "intake-confirmation-call-now",
      sanitize: true,
      isAuth: true,
      renderInterlude: () => (
        <IntakeConfirmationInterlude
          heading={i18n.t("FUNNEL_INTERLUDE_CONFIRMING_YOUR_CALL")}
        />
      ),
    },

    "intake-confirmation": {
      next: async () => undefined,
    },

    "intake-confirmation-call-now": {
      next: async () => undefined,
    },

    "child-protective-services": {
      next: async ({ answers }) => {
        const { childProtectiveServices } = answers;
        return childProtectiveServices?.toLowerCase() === "yes"
          ? "out-of-scope"
          : "zip-code";
      },
      progressBarSection: "case-details",
    },
    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
