import * as i from "react";
import { jsx as c, Fragment as m } from "react/jsx-runtime";
function p(e, n) {
  if (typeof e == "function")
    return e(n);
  e != null && (e.current = n);
}
function d(...e) {
  return (n) => {
    let r = !1;
    const o = e.map((t) => {
      const l = p(t, n);
      return !r && typeof l == "function" && (r = !0), l;
    });
    if (r)
      return () => {
        for (let t = 0; t < o.length; t++) {
          const l = o[t];
          typeof l == "function" ? l() : p(e[t], null);
        }
      };
  };
}
function S(...e) {
  return i.useCallback(d(...e), e);
}
var y = i.forwardRef((e, n) => {
  const { children: r, ...o } = e, t = i.Children.toArray(r), l = t.find(h);
  if (l) {
    const s = l.props.children, a = t.map((f) => f === l ? i.Children.count(s) > 1 ? i.Children.only(null) : i.isValidElement(s) ? s.props.children : null : f);
    return /* @__PURE__ */ c(u, { ...o, ref: n, children: i.isValidElement(s) ? i.cloneElement(s, void 0, a) : null });
  }
  return /* @__PURE__ */ c(u, { ...o, ref: n, children: r });
});
y.displayName = "Slot";
var u = i.forwardRef((e, n) => {
  const { children: r, ...o } = e;
  if (i.isValidElement(r)) {
    const t = R(r);
    return i.cloneElement(r, {
      ...C(o, r.props),
      // @ts-ignore
      ref: n ? d(n, t) : t
    });
  }
  return i.Children.count(r) > 1 ? i.Children.only(null) : null;
});
u.displayName = "SlotClone";
var g = ({ children: e }) => /* @__PURE__ */ c(m, { children: e });
function h(e) {
  return i.isValidElement(e) && e.type === g;
}
function C(e, n) {
  const r = { ...n };
  for (const o in n) {
    const t = e[o], l = n[o];
    /^on[A-Z]/.test(o) ? t && l ? r[o] = (...a) => {
      l(...a), t(...a);
    } : t && (r[o] = t) : o === "style" ? r[o] = { ...t, ...l } : o === "className" && (r[o] = [t, l].filter(Boolean).join(" "));
  }
  return { ...e, ...r };
}
function R(e) {
  var o, t;
  let n = (o = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : o.get, r = n && "isReactWarning" in n && n.isReactWarning;
  return r ? e.ref : (n = (t = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : t.get, r = n && "isReactWarning" in n && n.isReactWarning, r ? e.props.ref : e.props.ref || e.ref);
}
export {
  y as S,
  g as a,
  S as u
};
