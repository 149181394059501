import { jsx as u, jsxs as H } from "react/jsx-runtime";
import * as s from "react";
import { forwardRef as K } from "react";
import { c as g, u as W } from "../../index-47DXPxf5.js";
import { u as S, a as z } from "../../index-DoOsnuB6.js";
import { c as J } from "../../index-CwaZVody.js";
import { c as G, A as Q, D as Z, C as ee, R as te, a as oe, b as re, P as ne } from "../../index-BsJMTImk.js";
import { u as se } from "../../index-uPFSw64O.js";
import { P as F } from "../../index-BvsBDqN-.js";
import { P as ae } from "../../index-C8BDXGwJ.js";
import { c as ie } from "../../index-Bm_zk_a7.js";
import { focusRing as ce } from "../../utils/focus-ring.js";
var [_, Be] = J("Tooltip", [
  G
]), D = G(), j = "TooltipProvider", le = 700, A = "tooltip.open", [ue, k] = _(j), B = (t) => {
  const {
    __scopeTooltip: o,
    delayDuration: e = le,
    skipDelayDuration: r = 300,
    disableHoverableContent: n = !1,
    children: i
  } = t, [l, v] = s.useState(!0), a = s.useRef(!1), d = s.useRef(0);
  return s.useEffect(() => {
    const c = d.current;
    return () => window.clearTimeout(c);
  }, []), /* @__PURE__ */ u(
    ue,
    {
      scope: o,
      isOpenDelayed: l,
      delayDuration: e,
      onOpen: s.useCallback(() => {
        window.clearTimeout(d.current), v(!1);
      }, []),
      onClose: s.useCallback(() => {
        window.clearTimeout(d.current), d.current = window.setTimeout(
          () => v(!0),
          r
        );
      }, [r]),
      isPointerInTransitRef: a,
      onPointerInTransitChange: s.useCallback((c) => {
        a.current = c;
      }, []),
      disableHoverableContent: n,
      children: i
    }
  );
};
B.displayName = j;
var O = "Tooltip", [pe, R] = _(O), U = (t) => {
  const {
    __scopeTooltip: o,
    children: e,
    open: r,
    defaultOpen: n = !1,
    onOpenChange: i,
    disableHoverableContent: l,
    delayDuration: v
  } = t, a = k(O, t.__scopeTooltip), d = D(o), [c, f] = s.useState(null), m = se(), p = s.useRef(0), h = l ?? a.disableHoverableContent, x = v ?? a.delayDuration, T = s.useRef(!1), [C = !1, y] = W({
    prop: r,
    defaultProp: n,
    onChange: (N) => {
      N ? (a.onOpen(), document.dispatchEvent(new CustomEvent(A))) : a.onClose(), i == null || i(N);
    }
  }), w = s.useMemo(() => C ? T.current ? "delayed-open" : "instant-open" : "closed", [C]), P = s.useCallback(() => {
    window.clearTimeout(p.current), p.current = 0, T.current = !1, y(!0);
  }, [y]), E = s.useCallback(() => {
    window.clearTimeout(p.current), p.current = 0, y(!1);
  }, [y]), M = s.useCallback(() => {
    window.clearTimeout(p.current), p.current = window.setTimeout(() => {
      T.current = !0, y(!0), p.current = 0;
    }, x);
  }, [x, y]);
  return s.useEffect(() => () => {
    p.current && (window.clearTimeout(p.current), p.current = 0);
  }, []), /* @__PURE__ */ u(re, { ...d, children: /* @__PURE__ */ u(
    pe,
    {
      scope: o,
      contentId: m,
      open: C,
      stateAttribute: w,
      trigger: c,
      onTriggerChange: f,
      onTriggerEnter: s.useCallback(() => {
        a.isOpenDelayed ? M() : P();
      }, [a.isOpenDelayed, M, P]),
      onTriggerLeave: s.useCallback(() => {
        h ? E() : (window.clearTimeout(p.current), p.current = 0);
      }, [E, h]),
      onOpen: P,
      onClose: E,
      disableHoverableContent: h,
      children: e
    }
  ) });
};
U.displayName = O;
var L = "TooltipTrigger", V = s.forwardRef(
  (t, o) => {
    const { __scopeTooltip: e, ...r } = t, n = R(L, e), i = k(L, e), l = D(e), v = s.useRef(null), a = S(o, v, n.onTriggerChange), d = s.useRef(!1), c = s.useRef(!1), f = s.useCallback(() => d.current = !1, []);
    return s.useEffect(() => () => document.removeEventListener("pointerup", f), [f]), /* @__PURE__ */ u(Q, { asChild: !0, ...l, children: /* @__PURE__ */ u(
      ae.button,
      {
        "aria-describedby": n.open ? n.contentId : void 0,
        "data-state": n.stateAttribute,
        ...r,
        ref: a,
        onPointerMove: g(t.onPointerMove, (m) => {
          m.pointerType !== "touch" && !c.current && !i.isPointerInTransitRef.current && (n.onTriggerEnter(), c.current = !0);
        }),
        onPointerLeave: g(t.onPointerLeave, () => {
          n.onTriggerLeave(), c.current = !1;
        }),
        onPointerDown: g(t.onPointerDown, () => {
          d.current = !0, document.addEventListener("pointerup", f, { once: !0 });
        }),
        onFocus: g(t.onFocus, () => {
          d.current || n.onOpen();
        }),
        onBlur: g(t.onBlur, n.onClose),
        onClick: g(t.onClick, n.onClose)
      }
    ) });
  }
);
V.displayName = L;
var I = "TooltipPortal", [de, fe] = _(I, {
  forceMount: void 0
}), Y = (t) => {
  const { __scopeTooltip: o, forceMount: e, children: r, container: n } = t, i = R(I, o);
  return /* @__PURE__ */ u(de, { scope: o, forceMount: e, children: /* @__PURE__ */ u(F, { present: e || i.open, children: /* @__PURE__ */ u(ne, { asChild: !0, container: n, children: r }) }) });
};
Y.displayName = I;
var b = "TooltipContent", q = s.forwardRef(
  (t, o) => {
    const e = fe(b, t.__scopeTooltip), { forceMount: r = e.forceMount, side: n = "top", ...i } = t, l = R(b, t.__scopeTooltip);
    return /* @__PURE__ */ u(F, { present: r || l.open, children: l.disableHoverableContent ? /* @__PURE__ */ u(X, { side: n, ...i, ref: o }) : /* @__PURE__ */ u(ve, { side: n, ...i, ref: o }) });
  }
), ve = s.forwardRef((t, o) => {
  const e = R(b, t.__scopeTooltip), r = k(b, t.__scopeTooltip), n = s.useRef(null), i = S(o, n), [l, v] = s.useState(null), { trigger: a, onClose: d } = e, c = n.current, { onPointerInTransitChange: f } = r, m = s.useCallback(() => {
    v(null), f(!1);
  }, [f]), p = s.useCallback(
    (h, x) => {
      const T = h.currentTarget, C = { x: h.clientX, y: h.clientY }, y = xe(C, T.getBoundingClientRect()), w = ye(C, y), P = Ce(x.getBoundingClientRect()), E = be([...w, ...P]);
      v(E), f(!0);
    },
    [f]
  );
  return s.useEffect(() => () => m(), [m]), s.useEffect(() => {
    if (a && c) {
      const h = (T) => p(T, c), x = (T) => p(T, a);
      return a.addEventListener("pointerleave", h), c.addEventListener("pointerleave", x), () => {
        a.removeEventListener("pointerleave", h), c.removeEventListener("pointerleave", x);
      };
    }
  }, [a, c, p, m]), s.useEffect(() => {
    if (l) {
      const h = (x) => {
        const T = x.target, C = { x: x.clientX, y: x.clientY }, y = (a == null ? void 0 : a.contains(T)) || (c == null ? void 0 : c.contains(T)), w = !ge(C, l);
        y ? m() : w && (m(), d());
      };
      return document.addEventListener("pointermove", h), () => document.removeEventListener("pointermove", h);
    }
  }, [a, c, l, d, m]), /* @__PURE__ */ u(X, { ...t, ref: i });
}), [he, me] = _(O, { isInside: !1 }), X = s.forwardRef(
  (t, o) => {
    const {
      __scopeTooltip: e,
      children: r,
      "aria-label": n,
      onEscapeKeyDown: i,
      onPointerDownOutside: l,
      ...v
    } = t, a = R(b, e), d = D(e), { onClose: c } = a;
    return s.useEffect(() => (document.addEventListener(A, c), () => document.removeEventListener(A, c)), [c]), s.useEffect(() => {
      if (a.trigger) {
        const f = (m) => {
          const p = m.target;
          p != null && p.contains(a.trigger) && c();
        };
        return window.addEventListener("scroll", f, { capture: !0 }), () => window.removeEventListener("scroll", f, { capture: !0 });
      }
    }, [a.trigger, c]), /* @__PURE__ */ u(
      Z,
      {
        asChild: !0,
        disableOutsidePointerEvents: !1,
        onEscapeKeyDown: i,
        onPointerDownOutside: l,
        onFocusOutside: (f) => f.preventDefault(),
        onDismiss: c,
        children: /* @__PURE__ */ H(
          ee,
          {
            "data-state": a.stateAttribute,
            ...d,
            ...v,
            ref: o,
            style: {
              ...v.style,
              "--radix-tooltip-content-transform-origin": "var(--radix-popper-transform-origin)",
              "--radix-tooltip-content-available-width": "var(--radix-popper-available-width)",
              "--radix-tooltip-content-available-height": "var(--radix-popper-available-height)",
              "--radix-tooltip-trigger-width": "var(--radix-popper-anchor-width)",
              "--radix-tooltip-trigger-height": "var(--radix-popper-anchor-height)"
            },
            children: [
              /* @__PURE__ */ u(z, { children: r }),
              /* @__PURE__ */ u(he, { scope: e, isInside: !0, children: /* @__PURE__ */ u(te, { id: a.contentId, role: "tooltip", children: n || r }) })
            ]
          }
        )
      }
    );
  }
);
q.displayName = b;
var $ = "TooltipArrow", Te = s.forwardRef(
  (t, o) => {
    const { __scopeTooltip: e, ...r } = t, n = D(e);
    return me(
      $,
      e
    ).isInside ? null : /* @__PURE__ */ u(oe, { ...n, ...r, ref: o });
  }
);
Te.displayName = $;
function xe(t, o) {
  const e = Math.abs(o.top - t.y), r = Math.abs(o.bottom - t.y), n = Math.abs(o.right - t.x), i = Math.abs(o.left - t.x);
  switch (Math.min(e, r, n, i)) {
    case i:
      return "left";
    case n:
      return "right";
    case e:
      return "top";
    case r:
      return "bottom";
    default:
      throw new Error("unreachable");
  }
}
function ye(t, o, e = 5) {
  const r = [];
  switch (o) {
    case "top":
      r.push(
        { x: t.x - e, y: t.y + e },
        { x: t.x + e, y: t.y + e }
      );
      break;
    case "bottom":
      r.push(
        { x: t.x - e, y: t.y - e },
        { x: t.x + e, y: t.y - e }
      );
      break;
    case "left":
      r.push(
        { x: t.x + e, y: t.y - e },
        { x: t.x + e, y: t.y + e }
      );
      break;
    case "right":
      r.push(
        { x: t.x - e, y: t.y - e },
        { x: t.x - e, y: t.y + e }
      );
      break;
  }
  return r;
}
function Ce(t) {
  const { top: o, right: e, bottom: r, left: n } = t;
  return [
    { x: n, y: o },
    { x: e, y: o },
    { x: e, y: r },
    { x: n, y: r }
  ];
}
function ge(t, o) {
  const { x: e, y: r } = t;
  let n = !1;
  for (let i = 0, l = o.length - 1; i < o.length; l = i++) {
    const v = o[i].x, a = o[i].y, d = o[l].x, c = o[l].y;
    a > r != c > r && e < (d - v) * (r - a) / (c - a) + v && (n = !n);
  }
  return n;
}
function be(t) {
  const o = t.slice();
  return o.sort((e, r) => e.x < r.x ? -1 : e.x > r.x ? 1 : e.y < r.y ? -1 : e.y > r.y ? 1 : 0), we(o);
}
function we(t) {
  if (t.length <= 1) return t.slice();
  const o = [];
  for (let r = 0; r < t.length; r++) {
    const n = t[r];
    for (; o.length >= 2; ) {
      const i = o[o.length - 1], l = o[o.length - 2];
      if ((i.x - l.x) * (n.y - l.y) >= (i.y - l.y) * (n.x - l.x)) o.pop();
      else break;
    }
    o.push(n);
  }
  o.pop();
  const e = [];
  for (let r = t.length - 1; r >= 0; r--) {
    const n = t[r];
    for (; e.length >= 2; ) {
      const i = e[e.length - 1], l = e[e.length - 2];
      if ((i.x - l.x) * (n.y - l.y) >= (i.y - l.y) * (n.x - l.x)) e.pop();
      else break;
    }
    e.push(n);
  }
  return e.pop(), o.length === 1 && e.length === 1 && o[0].x === e[0].x && o[0].y === e[0].y ? o : o.concat(e);
}
var Pe = B, Ee = U, Re = V, _e = Y, De = q;
const Oe = K(
  ({
    children: t,
    className: o,
    content: e,
    defaultOpen: r,
    open: n,
    onClick: i,
    onOpenChange: l,
    side: v,
    asChild: a = !1,
    align: d = "center",
    ...c
  }, f) => /* @__PURE__ */ u(Pe, { delayDuration: 150, children: /* @__PURE__ */ H(
    Ee,
    {
      open: n,
      defaultOpen: r,
      onOpenChange: l,
      children: [
        /* @__PURE__ */ u(
          Re,
          {
            className: ce,
            onClick: i,
            asChild: a,
            children: t
          }
        ),
        /* @__PURE__ */ u(_e, { children: /* @__PURE__ */ u(
          De,
          {
            ref: f,
            side: v,
            sideOffset: 4,
            align: d,
            className: ie(
              // base
              "min-w-s-6 max-w-[calc(var(--fui-baseline)*8+var(--fui-subline)*2)] rounded-xs bg-[--color-tooltip-bg] px-sp-2 py-sp-1 text-center text-caption text-[--color-tooltip-content]",
              // transition
              "data-[side=top]:animate-slide-up-and-fade data-[state=closed]:animate-hide",
              o
            ),
            ...c,
            children: e
          }
        ) })
      ]
    }
  ) })
);
Oe.displayName = "Tooltip";
export {
  Oe as Tooltip
};
