import type { DeprecatedColorKeys } from "@/styles/deprecated-colors";

import type { BuilderAttributes } from "../types";

export enum TextTypes {
  BodyL = "BodyL",
  BodyM = "BodyM",
  BodyS = "BodyS",
  // These are the old names and should be removed after the migration is done
  BodyXS = "BodyXS",
  Label = "Label",
  Text1 = "Text1",
  Text2 = "Text2",
  Text3 = "Text3",
  Text4 = "Text4",
  Text5 = "Text5",
  Text6 = "Text6",
}

export type ComponentList = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in TextTypes]: React.ComponentType<any>;
};

export type TextVariant = keyof typeof TextTypes;

export interface OverrideProps {
  color?: DeprecatedColorKeys;
  textAlign?: string;
  whiteSpace?: string;
}

export interface TextProps {
  attributes?: BuilderAttributes;
  balanceText?: boolean;
  color?: DeprecatedColorKeys;
  text?: string;
  type: TextVariant;
}
