import { jsx as m, Fragment as Xe, jsxs as te } from "react/jsx-runtime";
import * as c from "react";
import { useState as Qt, forwardRef as oe } from "react";
import * as lt from "react-dom";
import { c as M, u as qe } from "../../index-47DXPxf5.js";
import { c as Jt, u as en } from "../../index-DBpAfTyY.js";
import { u as F, S as tn } from "../../index-DoOsnuB6.js";
import { u as He, c as nn, a as $ } from "../../index-CwaZVody.js";
import { c as ut, A as rn, D as on, C as an, a as cn, V as sn, b as ln, P as un } from "../../index-BsJMTImk.js";
import { P as O } from "../../index-C8BDXGwJ.js";
import { u as $e } from "../../index-uPFSw64O.js";
import { u as dn } from "../../index-BZPx6jYI.js";
import { c as ne } from "../../index-Bm_zk_a7.js";
import { Icon as We } from "../icon/icon.js";
function Qe(e, [n, t]) {
  return Math.min(t, Math.max(n, e));
}
var _e = 0;
function fn() {
  c.useEffect(() => {
    const e = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", e[0] ?? Je()), document.body.insertAdjacentElement("beforeend", e[1] ?? Je()), _e++, () => {
      _e === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach((n) => n.remove()), _e--;
    };
  }, []);
}
function Je() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.outline = "none", e.style.opacity = "0", e.style.position = "fixed", e.style.pointerEvents = "none", e;
}
var Oe = "focusScope.autoFocusOnMount", Le = "focusScope.autoFocusOnUnmount", et = { bubbles: !1, cancelable: !0 }, pn = "FocusScope", dt = c.forwardRef((e, n) => {
  const {
    loop: t = !1,
    trapped: r = !1,
    onMountAutoFocus: a,
    onUnmountAutoFocus: l,
    ...s
  } = e, [o, u] = c.useState(null), d = He(a), g = He(l), h = c.useRef(null), y = F(n, (i) => u(i)), w = c.useRef({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  c.useEffect(() => {
    if (r) {
      let i = function(v) {
        if (w.paused || !o) return;
        const E = v.target;
        o.contains(E) ? h.current = E : X(h.current, { select: !0 });
      }, f = function(v) {
        if (w.paused || !o) return;
        const E = v.relatedTarget;
        E !== null && (o.contains(E) || X(h.current, { select: !0 }));
      }, S = function(v) {
        if (document.activeElement === document.body)
          for (const x of v)
            x.removedNodes.length > 0 && X(o);
      };
      document.addEventListener("focusin", i), document.addEventListener("focusout", f);
      const p = new MutationObserver(S);
      return o && p.observe(o, { childList: !0, subtree: !0 }), () => {
        document.removeEventListener("focusin", i), document.removeEventListener("focusout", f), p.disconnect();
      };
    }
  }, [r, o, w.paused]), c.useEffect(() => {
    if (o) {
      nt.add(w);
      const i = document.activeElement;
      if (!o.contains(i)) {
        const S = new CustomEvent(Oe, et);
        o.addEventListener(Oe, d), o.dispatchEvent(S), S.defaultPrevented || (vn(yn(ft(o)), { select: !0 }), document.activeElement === i && X(o));
      }
      return () => {
        o.removeEventListener(Oe, d), setTimeout(() => {
          const S = new CustomEvent(Le, et);
          o.addEventListener(Le, g), o.dispatchEvent(S), S.defaultPrevented || X(i ?? document.body, { select: !0 }), o.removeEventListener(Le, g), nt.remove(w);
        }, 0);
      };
    }
  }, [o, d, g, w]);
  const C = c.useCallback(
    (i) => {
      if (!t && !r || w.paused) return;
      const f = i.key === "Tab" && !i.altKey && !i.ctrlKey && !i.metaKey, S = document.activeElement;
      if (f && S) {
        const p = i.currentTarget, [v, E] = mn(p);
        v && E ? !i.shiftKey && S === E ? (i.preventDefault(), t && X(v, { select: !0 })) : i.shiftKey && S === v && (i.preventDefault(), t && X(E, { select: !0 })) : S === p && i.preventDefault();
      }
    },
    [t, r, w.paused]
  );
  return /* @__PURE__ */ m(O.div, { tabIndex: -1, ...s, ref: y, onKeyDown: C });
});
dt.displayName = pn;
function vn(e, { select: n = !1 } = {}) {
  const t = document.activeElement;
  for (const r of e)
    if (X(r, { select: n }), document.activeElement !== t) return;
}
function mn(e) {
  const n = ft(e), t = tt(n, e), r = tt(n.reverse(), e);
  return [t, r];
}
function ft(e) {
  const n = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (r) => {
      const a = r.tagName === "INPUT" && r.type === "hidden";
      return r.disabled || r.hidden || a ? NodeFilter.FILTER_SKIP : r.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); ) n.push(t.currentNode);
  return n;
}
function tt(e, n) {
  for (const t of e)
    if (!hn(t, { upTo: n })) return t;
}
function hn(e, { upTo: n }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (n !== void 0 && e === n) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function gn(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function X(e, { select: n = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({ preventScroll: !0 }), e !== t && gn(e) && n && e.select();
  }
}
var nt = Sn();
function Sn() {
  let e = [];
  return {
    add(n) {
      const t = e[0];
      n !== t && (t == null || t.pause()), e = rt(e, n), e.unshift(n);
    },
    remove(n) {
      var t;
      e = rt(e, n), (t = e[0]) == null || t.resume();
    }
  };
}
function rt(e, n) {
  const t = [...e], r = t.indexOf(n);
  return r !== -1 && t.splice(r, 1), t;
}
function yn(e) {
  return e.filter((n) => n.tagName !== "A");
}
var wn = function(e) {
  if (typeof document > "u")
    return null;
  var n = Array.isArray(e) ? e[0] : e;
  return n.ownerDocument.body;
}, ce = /* @__PURE__ */ new WeakMap(), he = /* @__PURE__ */ new WeakMap(), ge = {}, De = 0, pt = function(e) {
  return e && (e.host || pt(e.parentNode));
}, bn = function(e, n) {
  return n.map(function(t) {
    if (e.contains(t))
      return t;
    var r = pt(t);
    return r && e.contains(r) ? r : (console.error("aria-hidden", t, "in not contained inside", e, ". Doing nothing"), null);
  }).filter(function(t) {
    return !!t;
  });
}, Cn = function(e, n, t, r) {
  var a = bn(n, Array.isArray(e) ? e : [e]);
  ge[t] || (ge[t] = /* @__PURE__ */ new WeakMap());
  var l = ge[t], s = [], o = /* @__PURE__ */ new Set(), u = new Set(a), d = function(h) {
    !h || o.has(h) || (o.add(h), d(h.parentNode));
  };
  a.forEach(d);
  var g = function(h) {
    !h || u.has(h) || Array.prototype.forEach.call(h.children, function(y) {
      if (o.has(y))
        g(y);
      else
        try {
          var w = y.getAttribute(r), C = w !== null && w !== "false", i = (ce.get(y) || 0) + 1, f = (l.get(y) || 0) + 1;
          ce.set(y, i), l.set(y, f), s.push(y), i === 1 && C && he.set(y, !0), f === 1 && y.setAttribute(t, "true"), C || y.setAttribute(r, "true");
        } catch (S) {
          console.error("aria-hidden: cannot operate on ", y, S);
        }
    });
  };
  return g(n), o.clear(), De++, function() {
    s.forEach(function(h) {
      var y = ce.get(h) - 1, w = l.get(h) - 1;
      ce.set(h, y), l.set(h, w), y || (he.has(h) || h.removeAttribute(r), he.delete(h)), w || h.removeAttribute(t);
    }), De--, De || (ce = /* @__PURE__ */ new WeakMap(), ce = /* @__PURE__ */ new WeakMap(), he = /* @__PURE__ */ new WeakMap(), ge = {});
  };
}, En = function(e, n, t) {
  t === void 0 && (t = "data-aria-hidden");
  var r = Array.from(Array.isArray(e) ? e : [e]), a = wn(e);
  return a ? (r.push.apply(r, Array.from(a.querySelectorAll("[aria-live]"))), Cn(r, a, t, "aria-hidden")) : function() {
    return null;
  };
}, H = function() {
  return H = Object.assign || function(n) {
    for (var t, r = 1, a = arguments.length; r < a; r++) {
      t = arguments[r];
      for (var l in t) Object.prototype.hasOwnProperty.call(t, l) && (n[l] = t[l]);
    }
    return n;
  }, H.apply(this, arguments);
};
function vt(e, n) {
  var t = {};
  for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && n.indexOf(r) < 0 && (t[r] = e[r]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var a = 0, r = Object.getOwnPropertySymbols(e); a < r.length; a++)
      n.indexOf(r[a]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[a]) && (t[r[a]] = e[r[a]]);
  return t;
}
function xn(e, n, t) {
  if (t || arguments.length === 2) for (var r = 0, a = n.length, l; r < a; r++)
    (l || !(r in n)) && (l || (l = Array.prototype.slice.call(n, 0, r)), l[r] = n[r]);
  return e.concat(l || Array.prototype.slice.call(n));
}
var we = "right-scroll-bar-position", be = "width-before-scroll-bar", Tn = "with-scroll-bars-hidden", In = "--removed-body-scroll-bar-size";
function Be(e, n) {
  return typeof e == "function" ? e(n) : e && (e.current = n), e;
}
function Pn(e, n) {
  var t = Qt(function() {
    return {
      // value
      value: e,
      // last callback
      callback: n,
      // "memoized" public interface
      facade: {
        get current() {
          return t.value;
        },
        set current(r) {
          var a = t.value;
          a !== r && (t.value = r, t.callback(r, a));
        }
      }
    };
  })[0];
  return t.callback = n, t.facade;
}
var Nn = typeof window < "u" ? c.useLayoutEffect : c.useEffect, ot = /* @__PURE__ */ new WeakMap();
function Rn(e, n) {
  var t = Pn(null, function(r) {
    return e.forEach(function(a) {
      return Be(a, r);
    });
  });
  return Nn(function() {
    var r = ot.get(t);
    if (r) {
      var a = new Set(r), l = new Set(e), s = t.current;
      a.forEach(function(o) {
        l.has(o) || Be(o, null);
      }), l.forEach(function(o) {
        a.has(o) || Be(o, s);
      });
    }
    ot.set(t, e);
  }, [e]), t;
}
function Mn(e) {
  return e;
}
function An(e, n) {
  n === void 0 && (n = Mn);
  var t = [], r = !1, a = {
    read: function() {
      if (r)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return t.length ? t[t.length - 1] : e;
    },
    useMedium: function(l) {
      var s = n(l, r);
      return t.push(s), function() {
        t = t.filter(function(o) {
          return o !== s;
        });
      };
    },
    assignSyncMedium: function(l) {
      for (r = !0; t.length; ) {
        var s = t;
        t = [], s.forEach(l);
      }
      t = {
        push: function(o) {
          return l(o);
        },
        filter: function() {
          return t;
        }
      };
    },
    assignMedium: function(l) {
      r = !0;
      var s = [];
      if (t.length) {
        var o = t;
        t = [], o.forEach(l), s = t;
      }
      var u = function() {
        var g = s;
        s = [], g.forEach(l);
      }, d = function() {
        return Promise.resolve().then(u);
      };
      d(), t = {
        push: function(g) {
          s.push(g), d();
        },
        filter: function(g) {
          return s = s.filter(g), t;
        }
      };
    }
  };
  return a;
}
function kn(e) {
  e === void 0 && (e = {});
  var n = An(null);
  return n.options = H({ async: !0, ssr: !1 }, e), n;
}
var mt = function(e) {
  var n = e.sideCar, t = vt(e, ["sideCar"]);
  if (!n)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var r = n.read();
  if (!r)
    throw new Error("Sidecar medium not found");
  return c.createElement(r, H({}, t));
};
mt.isSideCarExport = !0;
function _n(e, n) {
  return e.useMedium(n), mt;
}
var ht = kn(), Fe = function() {
}, Ee = c.forwardRef(function(e, n) {
  var t = c.useRef(null), r = c.useState({
    onScrollCapture: Fe,
    onWheelCapture: Fe,
    onTouchMoveCapture: Fe
  }), a = r[0], l = r[1], s = e.forwardProps, o = e.children, u = e.className, d = e.removeScrollBar, g = e.enabled, h = e.shards, y = e.sideCar, w = e.noIsolation, C = e.inert, i = e.allowPinchZoom, f = e.as, S = f === void 0 ? "div" : f, p = e.gapMode, v = vt(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as", "gapMode"]), E = y, x = Rn([t, n]), L = H(H({}, v), a);
  return c.createElement(
    c.Fragment,
    null,
    g && c.createElement(E, { sideCar: ht, removeScrollBar: d, shards: h, noIsolation: w, inert: C, setCallbacks: l, allowPinchZoom: !!i, lockRef: t, gapMode: p }),
    s ? c.cloneElement(c.Children.only(o), H(H({}, L), { ref: x })) : c.createElement(S, H({}, L, { className: u, ref: x }), o)
  );
});
Ee.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
Ee.classNames = {
  fullWidth: be,
  zeroRight: we
};
var On = function() {
  if (typeof __webpack_nonce__ < "u")
    return __webpack_nonce__;
};
function Ln() {
  if (!document)
    return null;
  var e = document.createElement("style");
  e.type = "text/css";
  var n = On();
  return n && e.setAttribute("nonce", n), e;
}
function Dn(e, n) {
  e.styleSheet ? e.styleSheet.cssText = n : e.appendChild(document.createTextNode(n));
}
function Bn(e) {
  var n = document.head || document.getElementsByTagName("head")[0];
  n.appendChild(e);
}
var Fn = function() {
  var e = 0, n = null;
  return {
    add: function(t) {
      e == 0 && (n = Ln()) && (Dn(n, t), Bn(n)), e++;
    },
    remove: function() {
      e--, !e && n && (n.parentNode && n.parentNode.removeChild(n), n = null);
    }
  };
}, Vn = function() {
  var e = Fn();
  return function(n, t) {
    c.useEffect(function() {
      return e.add(n), function() {
        e.remove();
      };
    }, [n && t]);
  };
}, gt = function() {
  var e = Vn(), n = function(t) {
    var r = t.styles, a = t.dynamic;
    return e(r, a), null;
  };
  return n;
}, Hn = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, Ve = function(e) {
  return parseInt(e || "", 10) || 0;
}, Wn = function(e) {
  var n = window.getComputedStyle(document.body), t = n[e === "padding" ? "paddingLeft" : "marginLeft"], r = n[e === "padding" ? "paddingTop" : "marginTop"], a = n[e === "padding" ? "paddingRight" : "marginRight"];
  return [Ve(t), Ve(r), Ve(a)];
}, Un = function(e) {
  if (e === void 0 && (e = "margin"), typeof window > "u")
    return Hn;
  var n = Wn(e), t = document.documentElement.clientWidth, r = window.innerWidth;
  return {
    left: n[0],
    top: n[1],
    right: n[2],
    gap: Math.max(0, r - t + n[2] - n[0])
  };
}, Kn = gt(), le = "data-scroll-locked", jn = function(e, n, t, r) {
  var a = e.left, l = e.top, s = e.right, o = e.gap;
  return t === void 0 && (t = "margin"), `
  .`.concat(Tn, ` {
   overflow: hidden `).concat(r, `;
   padding-right: `).concat(o, "px ").concat(r, `;
  }
  body[`).concat(le, `] {
    overflow: hidden `).concat(r, `;
    overscroll-behavior: contain;
    `).concat([
    n && "position: relative ".concat(r, ";"),
    t === "margin" && `
    padding-left: `.concat(a, `px;
    padding-top: `).concat(l, `px;
    padding-right: `).concat(s, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(o, "px ").concat(r, `;
    `),
    t === "padding" && "padding-right: ".concat(o, "px ").concat(r, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(we, ` {
    right: `).concat(o, "px ").concat(r, `;
  }
  
  .`).concat(be, ` {
    margin-right: `).concat(o, "px ").concat(r, `;
  }
  
  .`).concat(we, " .").concat(we, ` {
    right: 0 `).concat(r, `;
  }
  
  .`).concat(be, " .").concat(be, ` {
    margin-right: 0 `).concat(r, `;
  }
  
  body[`).concat(le, `] {
    `).concat(In, ": ").concat(o, `px;
  }
`);
}, at = function() {
  var e = parseInt(document.body.getAttribute(le) || "0", 10);
  return isFinite(e) ? e : 0;
}, Gn = function() {
  c.useEffect(function() {
    return document.body.setAttribute(le, (at() + 1).toString()), function() {
      var e = at() - 1;
      e <= 0 ? document.body.removeAttribute(le) : document.body.setAttribute(le, e.toString());
    };
  }, []);
}, Yn = function(e) {
  var n = e.noRelative, t = e.noImportant, r = e.gapMode, a = r === void 0 ? "margin" : r;
  Gn();
  var l = c.useMemo(function() {
    return Un(a);
  }, [a]);
  return c.createElement(Kn, { styles: jn(l, !n, a, t ? "" : "!important") });
}, Ue = !1;
if (typeof window < "u")
  try {
    var Se = Object.defineProperty({}, "passive", {
      get: function() {
        return Ue = !0, !0;
      }
    });
    window.addEventListener("test", Se, Se), window.removeEventListener("test", Se, Se);
  } catch {
    Ue = !1;
  }
var ie = Ue ? { passive: !1 } : !1, zn = function(e) {
  return e.tagName === "TEXTAREA";
}, St = function(e, n) {
  if (!(e instanceof Element))
    return !1;
  var t = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    t[n] !== "hidden" && // contains scroll inside self
    !(t.overflowY === t.overflowX && !zn(e) && t[n] === "visible")
  );
}, Xn = function(e) {
  return St(e, "overflowY");
}, $n = function(e) {
  return St(e, "overflowX");
}, ct = function(e, n) {
  var t = n.ownerDocument, r = n;
  do {
    typeof ShadowRoot < "u" && r instanceof ShadowRoot && (r = r.host);
    var a = yt(e, r);
    if (a) {
      var l = wt(e, r), s = l[1], o = l[2];
      if (s > o)
        return !0;
    }
    r = r.parentNode;
  } while (r && r !== t.body);
  return !1;
}, Zn = function(e) {
  var n = e.scrollTop, t = e.scrollHeight, r = e.clientHeight;
  return [
    n,
    t,
    r
  ];
}, qn = function(e) {
  var n = e.scrollLeft, t = e.scrollWidth, r = e.clientWidth;
  return [
    n,
    t,
    r
  ];
}, yt = function(e, n) {
  return e === "v" ? Xn(n) : $n(n);
}, wt = function(e, n) {
  return e === "v" ? Zn(n) : qn(n);
}, Qn = function(e, n) {
  return e === "h" && n === "rtl" ? -1 : 1;
}, Jn = function(e, n, t, r, a) {
  var l = Qn(e, window.getComputedStyle(n).direction), s = l * r, o = t.target, u = n.contains(o), d = !1, g = s > 0, h = 0, y = 0;
  do {
    var w = wt(e, o), C = w[0], i = w[1], f = w[2], S = i - f - l * C;
    (C || S) && yt(e, o) && (h += S, y += C), o instanceof ShadowRoot ? o = o.host : o = o.parentNode;
  } while (
    // portaled content
    !u && o !== document.body || // self content
    u && (n.contains(o) || n === o)
  );
  return (g && (Math.abs(h) < 1 || !a) || !g && (Math.abs(y) < 1 || !a)) && (d = !0), d;
}, ye = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, it = function(e) {
  return [e.deltaX, e.deltaY];
}, st = function(e) {
  return e && "current" in e ? e.current : e;
}, er = function(e, n) {
  return e[0] === n[0] && e[1] === n[1];
}, tr = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, nr = 0, se = [];
function rr(e) {
  var n = c.useRef([]), t = c.useRef([0, 0]), r = c.useRef(), a = c.useState(nr++)[0], l = c.useState(gt)[0], s = c.useRef(e);
  c.useEffect(function() {
    s.current = e;
  }, [e]), c.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(a));
      var i = xn([e.lockRef.current], (e.shards || []).map(st), !0).filter(Boolean);
      return i.forEach(function(f) {
        return f.classList.add("allow-interactivity-".concat(a));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(a)), i.forEach(function(f) {
          return f.classList.remove("allow-interactivity-".concat(a));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var o = c.useCallback(function(i, f) {
    if ("touches" in i && i.touches.length === 2 || i.type === "wheel" && i.ctrlKey)
      return !s.current.allowPinchZoom;
    var S = ye(i), p = t.current, v = "deltaX" in i ? i.deltaX : p[0] - S[0], E = "deltaY" in i ? i.deltaY : p[1] - S[1], x, L = i.target, R = Math.abs(v) > Math.abs(E) ? "h" : "v";
    if ("touches" in i && R === "h" && L.type === "range")
      return !1;
    var A = ct(R, L);
    if (!A)
      return !0;
    if (A ? x = R : (x = R === "v" ? "h" : "v", A = ct(R, L)), !A)
      return !1;
    if (!r.current && "changedTouches" in i && (v || E) && (r.current = x), !x)
      return !0;
    var D = r.current || x;
    return Jn(D, f, i, D === "h" ? v : E, !0);
  }, []), u = c.useCallback(function(i) {
    var f = i;
    if (!(!se.length || se[se.length - 1] !== l)) {
      var S = "deltaY" in f ? it(f) : ye(f), p = n.current.filter(function(x) {
        return x.name === f.type && (x.target === f.target || f.target === x.shadowParent) && er(x.delta, S);
      })[0];
      if (p && p.should) {
        f.cancelable && f.preventDefault();
        return;
      }
      if (!p) {
        var v = (s.current.shards || []).map(st).filter(Boolean).filter(function(x) {
          return x.contains(f.target);
        }), E = v.length > 0 ? o(f, v[0]) : !s.current.noIsolation;
        E && f.cancelable && f.preventDefault();
      }
    }
  }, []), d = c.useCallback(function(i, f, S, p) {
    var v = { name: i, delta: f, target: S, should: p, shadowParent: or(S) };
    n.current.push(v), setTimeout(function() {
      n.current = n.current.filter(function(E) {
        return E !== v;
      });
    }, 1);
  }, []), g = c.useCallback(function(i) {
    t.current = ye(i), r.current = void 0;
  }, []), h = c.useCallback(function(i) {
    d(i.type, it(i), i.target, o(i, e.lockRef.current));
  }, []), y = c.useCallback(function(i) {
    d(i.type, ye(i), i.target, o(i, e.lockRef.current));
  }, []);
  c.useEffect(function() {
    return se.push(l), e.setCallbacks({
      onScrollCapture: h,
      onWheelCapture: h,
      onTouchMoveCapture: y
    }), document.addEventListener("wheel", u, ie), document.addEventListener("touchmove", u, ie), document.addEventListener("touchstart", g, ie), function() {
      se = se.filter(function(i) {
        return i !== l;
      }), document.removeEventListener("wheel", u, ie), document.removeEventListener("touchmove", u, ie), document.removeEventListener("touchstart", g, ie);
    };
  }, []);
  var w = e.removeScrollBar, C = e.inert;
  return c.createElement(
    c.Fragment,
    null,
    C ? c.createElement(l, { styles: tr(a) }) : null,
    w ? c.createElement(Yn, { gapMode: e.gapMode }) : null
  );
}
function or(e) {
  for (var n = null; e !== null; )
    e instanceof ShadowRoot && (n = e.host, e = e.host), e = e.parentNode;
  return n;
}
const ar = _n(ht, rr);
var bt = c.forwardRef(function(e, n) {
  return c.createElement(Ee, H({}, e, { ref: n, sideCar: ar }));
});
bt.classNames = Ee.classNames;
var cr = [" ", "Enter", "ArrowUp", "ArrowDown"], ir = [" ", "Enter"], pe = "Select", [xe, Te, sr] = Jt(pe), [ue, oo] = nn(pe, [
  sr,
  ut
]), Ie = ut(), [lr, Z] = ue(pe), [ur, dr] = ue(pe), Ct = (e) => {
  const {
    __scopeSelect: n,
    children: t,
    open: r,
    defaultOpen: a,
    onOpenChange: l,
    value: s,
    defaultValue: o,
    onValueChange: u,
    dir: d,
    name: g,
    autoComplete: h,
    disabled: y,
    required: w,
    form: C
  } = e, i = Ie(n), [f, S] = c.useState(null), [p, v] = c.useState(null), [E, x] = c.useState(!1), L = en(d), [R = !1, A] = qe({
    prop: r,
    defaultProp: a,
    onChange: l
  }), [D, Q] = qe({
    prop: s,
    defaultProp: o,
    onChange: u
  }), W = c.useRef(null), U = f ? C || !!f.closest("form") : !0, [Y, K] = c.useState(/* @__PURE__ */ new Set()), j = Array.from(Y).map((k) => k.props.value).join(";");
  return /* @__PURE__ */ m(ln, { ...i, children: /* @__PURE__ */ te(
    lr,
    {
      required: w,
      scope: n,
      trigger: f,
      onTriggerChange: S,
      valueNode: p,
      onValueNodeChange: v,
      valueNodeHasChildren: E,
      onValueNodeHasChildrenChange: x,
      contentId: $e(),
      value: D,
      onValueChange: Q,
      open: R,
      onOpenChange: A,
      dir: L,
      triggerPointerDownPosRef: W,
      disabled: y,
      children: [
        /* @__PURE__ */ m(xe.Provider, { scope: n, children: /* @__PURE__ */ m(
          ur,
          {
            scope: e.__scopeSelect,
            onNativeOptionAdd: c.useCallback((k) => {
              K((V) => new Set(V).add(k));
            }, []),
            onNativeOptionRemove: c.useCallback((k) => {
              K((V) => {
                const G = new Set(V);
                return G.delete(k), G;
              });
            }, []),
            children: t
          }
        ) }),
        U ? /* @__PURE__ */ te(
          zt,
          {
            "aria-hidden": !0,
            required: w,
            tabIndex: -1,
            name: g,
            autoComplete: h,
            value: D,
            onChange: (k) => Q(k.target.value),
            disabled: y,
            form: C,
            children: [
              D === void 0 ? /* @__PURE__ */ m("option", { value: "" }) : null,
              Array.from(Y)
            ]
          },
          j
        ) : null
      ]
    }
  ) });
};
Ct.displayName = pe;
var Et = "SelectTrigger", xt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, disabled: r = !1, ...a } = e, l = Ie(t), s = Z(Et, t), o = s.disabled || r, u = F(n, s.onTriggerChange), d = Te(t), g = c.useRef("touch"), [h, y, w] = Xt((i) => {
      const f = d().filter((v) => !v.disabled), S = f.find((v) => v.value === s.value), p = $t(f, i, S);
      p !== void 0 && s.onValueChange(p.value);
    }), C = (i) => {
      o || (s.onOpenChange(!0), w()), i && (s.triggerPointerDownPosRef.current = {
        x: Math.round(i.pageX),
        y: Math.round(i.pageY)
      });
    };
    return /* @__PURE__ */ m(rn, { asChild: !0, ...l, children: /* @__PURE__ */ m(
      O.button,
      {
        type: "button",
        role: "combobox",
        "aria-controls": s.contentId,
        "aria-expanded": s.open,
        "aria-required": s.required,
        "aria-autocomplete": "none",
        dir: s.dir,
        "data-state": s.open ? "open" : "closed",
        disabled: o,
        "data-disabled": o ? "" : void 0,
        "data-placeholder": Yt(s.value) ? "" : void 0,
        ...a,
        ref: u,
        onClick: M(a.onClick, (i) => {
          i.currentTarget.focus(), g.current !== "mouse" && C(i);
        }),
        onPointerDown: M(a.onPointerDown, (i) => {
          g.current = i.pointerType;
          const f = i.target;
          f.hasPointerCapture(i.pointerId) && f.releasePointerCapture(i.pointerId), i.button === 0 && i.ctrlKey === !1 && i.pointerType === "mouse" && (C(i), i.preventDefault());
        }),
        onKeyDown: M(a.onKeyDown, (i) => {
          const f = h.current !== "";
          !(i.ctrlKey || i.altKey || i.metaKey) && i.key.length === 1 && y(i.key), !(f && i.key === " ") && cr.includes(i.key) && (C(), i.preventDefault());
        })
      }
    ) });
  }
);
xt.displayName = Et;
var Tt = "SelectValue", It = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, className: r, style: a, children: l, placeholder: s = "", ...o } = e, u = Z(Tt, t), { onValueNodeHasChildrenChange: d } = u, g = l !== void 0, h = F(n, u.onValueNodeChange);
    return $(() => {
      d(g);
    }, [d, g]), /* @__PURE__ */ m(
      O.span,
      {
        ...o,
        ref: h,
        style: { pointerEvents: "none" },
        children: Yt(u.value) ? /* @__PURE__ */ m(Xe, { children: s }) : l
      }
    );
  }
);
It.displayName = Tt;
var fr = "SelectIcon", Pt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, children: r, ...a } = e;
    return /* @__PURE__ */ m(O.span, { "aria-hidden": !0, ...a, ref: n, children: r || "▼" });
  }
);
Pt.displayName = fr;
var pr = "SelectPortal", Nt = (e) => /* @__PURE__ */ m(un, { asChild: !0, ...e });
Nt.displayName = pr;
var re = "SelectContent", Rt = c.forwardRef(
  (e, n) => {
    const t = Z(re, e.__scopeSelect), [r, a] = c.useState();
    if ($(() => {
      a(new DocumentFragment());
    }, []), !t.open) {
      const l = r;
      return l ? lt.createPortal(
        /* @__PURE__ */ m(Mt, { scope: e.__scopeSelect, children: /* @__PURE__ */ m(xe.Slot, { scope: e.__scopeSelect, children: /* @__PURE__ */ m("div", { children: e.children }) }) }),
        l
      ) : null;
    }
    return /* @__PURE__ */ m(At, { ...e, ref: n });
  }
);
Rt.displayName = re;
var B = 10, [Mt, q] = ue(re), vr = "SelectContentImpl", At = c.forwardRef(
  (e, n) => {
    const {
      __scopeSelect: t,
      position: r = "item-aligned",
      onCloseAutoFocus: a,
      onEscapeKeyDown: l,
      onPointerDownOutside: s,
      //
      // PopperContent props
      side: o,
      sideOffset: u,
      align: d,
      alignOffset: g,
      arrowPadding: h,
      collisionBoundary: y,
      collisionPadding: w,
      sticky: C,
      hideWhenDetached: i,
      avoidCollisions: f,
      //
      ...S
    } = e, p = Z(re, t), [v, E] = c.useState(null), [x, L] = c.useState(null), R = F(n, (b) => E(b)), [A, D] = c.useState(null), [Q, W] = c.useState(
      null
    ), U = Te(t), [Y, K] = c.useState(!1), j = c.useRef(!1);
    c.useEffect(() => {
      if (v) return En(v);
    }, [v]), fn();
    const k = c.useCallback(
      (b) => {
        const [N, ..._] = U().map((I) => I.ref.current), [P] = _.slice(-1), T = document.activeElement;
        for (const I of b)
          if (I === T || (I == null || I.scrollIntoView({ block: "nearest" }), I === N && x && (x.scrollTop = 0), I === P && x && (x.scrollTop = x.scrollHeight), I == null || I.focus(), document.activeElement !== T)) return;
      },
      [U, x]
    ), V = c.useCallback(
      () => k([A, v]),
      [k, A, v]
    );
    c.useEffect(() => {
      Y && V();
    }, [Y, V]);
    const { onOpenChange: G, triggerPointerDownPosRef: z } = p;
    c.useEffect(() => {
      if (v) {
        let b = { x: 0, y: 0 };
        const N = (P) => {
          var T, I;
          b = {
            x: Math.abs(Math.round(P.pageX) - (((T = z.current) == null ? void 0 : T.x) ?? 0)),
            y: Math.abs(Math.round(P.pageY) - (((I = z.current) == null ? void 0 : I.y) ?? 0))
          };
        }, _ = (P) => {
          b.x <= 10 && b.y <= 10 ? P.preventDefault() : v.contains(P.target) || G(!1), document.removeEventListener("pointermove", N), z.current = null;
        };
        return z.current !== null && (document.addEventListener("pointermove", N), document.addEventListener("pointerup", _, { capture: !0, once: !0 })), () => {
          document.removeEventListener("pointermove", N), document.removeEventListener("pointerup", _, { capture: !0 });
        };
      }
    }, [v, G, z]), c.useEffect(() => {
      const b = () => G(!1);
      return window.addEventListener("blur", b), window.addEventListener("resize", b), () => {
        window.removeEventListener("blur", b), window.removeEventListener("resize", b);
      };
    }, [G]);
    const [Pe, ve] = Xt((b) => {
      const N = U().filter((T) => !T.disabled), _ = N.find((T) => T.ref.current === document.activeElement), P = $t(N, b, _);
      P && setTimeout(() => P.ref.current.focus());
    }), Ne = c.useCallback(
      (b, N, _) => {
        const P = !j.current && !_;
        (p.value !== void 0 && p.value === N || P) && (D(b), P && (j.current = !0));
      },
      [p.value]
    ), Re = c.useCallback(() => v == null ? void 0 : v.focus(), [v]), ae = c.useCallback(
      (b, N, _) => {
        const P = !j.current && !_;
        (p.value !== void 0 && p.value === N || P) && W(b);
      },
      [p.value]
    ), me = r === "popper" ? Ke : kt, de = me === Ke ? {
      side: o,
      sideOffset: u,
      align: d,
      alignOffset: g,
      arrowPadding: h,
      collisionBoundary: y,
      collisionPadding: w,
      sticky: C,
      hideWhenDetached: i,
      avoidCollisions: f
    } : {};
    return /* @__PURE__ */ m(
      Mt,
      {
        scope: t,
        content: v,
        viewport: x,
        onViewportChange: L,
        itemRefCallback: Ne,
        selectedItem: A,
        onItemLeave: Re,
        itemTextRefCallback: ae,
        focusSelectedItem: V,
        selectedItemText: Q,
        position: r,
        isPositioned: Y,
        searchRef: Pe,
        children: /* @__PURE__ */ m(bt, { as: tn, allowPinchZoom: !0, children: /* @__PURE__ */ m(
          dt,
          {
            asChild: !0,
            trapped: p.open,
            onMountAutoFocus: (b) => {
              b.preventDefault();
            },
            onUnmountAutoFocus: M(a, (b) => {
              var N;
              (N = p.trigger) == null || N.focus({ preventScroll: !0 }), b.preventDefault();
            }),
            children: /* @__PURE__ */ m(
              on,
              {
                asChild: !0,
                disableOutsidePointerEvents: !0,
                onEscapeKeyDown: l,
                onPointerDownOutside: s,
                onFocusOutside: (b) => b.preventDefault(),
                onDismiss: () => p.onOpenChange(!1),
                children: /* @__PURE__ */ m(
                  me,
                  {
                    role: "listbox",
                    id: p.contentId,
                    "data-state": p.open ? "open" : "closed",
                    dir: p.dir,
                    onContextMenu: (b) => b.preventDefault(),
                    ...S,
                    ...de,
                    onPlaced: () => K(!0),
                    ref: R,
                    style: {
                      // flex layout so we can place the scroll buttons properly
                      display: "flex",
                      flexDirection: "column",
                      // reset the outline by default as the content MAY get focused
                      outline: "none",
                      ...S.style
                    },
                    onKeyDown: M(S.onKeyDown, (b) => {
                      const N = b.ctrlKey || b.altKey || b.metaKey;
                      if (b.key === "Tab" && b.preventDefault(), !N && b.key.length === 1 && ve(b.key), ["ArrowUp", "ArrowDown", "Home", "End"].includes(b.key)) {
                        let P = U().filter((T) => !T.disabled).map((T) => T.ref.current);
                        if (["ArrowUp", "End"].includes(b.key) && (P = P.slice().reverse()), ["ArrowUp", "ArrowDown"].includes(b.key)) {
                          const T = b.target, I = P.indexOf(T);
                          P = P.slice(I + 1);
                        }
                        setTimeout(() => k(P)), b.preventDefault();
                      }
                    })
                  }
                )
              }
            )
          }
        ) })
      }
    );
  }
);
At.displayName = vr;
var mr = "SelectItemAlignedPosition", kt = c.forwardRef((e, n) => {
  const { __scopeSelect: t, onPlaced: r, ...a } = e, l = Z(re, t), s = q(re, t), [o, u] = c.useState(null), [d, g] = c.useState(null), h = F(n, (R) => g(R)), y = Te(t), w = c.useRef(!1), C = c.useRef(!0), { viewport: i, selectedItem: f, selectedItemText: S, focusSelectedItem: p } = s, v = c.useCallback(() => {
    if (l.trigger && l.valueNode && o && d && i && f && S) {
      const R = l.trigger.getBoundingClientRect(), A = d.getBoundingClientRect(), D = l.valueNode.getBoundingClientRect(), Q = S.getBoundingClientRect();
      if (l.dir !== "rtl") {
        const T = Q.left - A.left, I = D.left - T, J = R.left - I, ee = R.width + J, Me = Math.max(ee, A.width), Ae = window.innerWidth - B, ke = Qe(I, [
          B,
          // Prevents the content from going off the starting edge of the
          // viewport. It may still go off the ending edge, but this can be
          // controlled by the user since they may want to manage overflow in a
          // specific way.
          // https://github.com/radix-ui/primitives/issues/2049
          Math.max(B, Ae - Me)
        ]);
        o.style.minWidth = ee + "px", o.style.left = ke + "px";
      } else {
        const T = A.right - Q.right, I = window.innerWidth - D.right - T, J = window.innerWidth - R.right - I, ee = R.width + J, Me = Math.max(ee, A.width), Ae = window.innerWidth - B, ke = Qe(I, [
          B,
          Math.max(B, Ae - Me)
        ]);
        o.style.minWidth = ee + "px", o.style.right = ke + "px";
      }
      const W = y(), U = window.innerHeight - B * 2, Y = i.scrollHeight, K = window.getComputedStyle(d), j = parseInt(K.borderTopWidth, 10), k = parseInt(K.paddingTop, 10), V = parseInt(K.borderBottomWidth, 10), G = parseInt(K.paddingBottom, 10), z = j + k + Y + G + V, Pe = Math.min(f.offsetHeight * 5, z), ve = window.getComputedStyle(i), Ne = parseInt(ve.paddingTop, 10), Re = parseInt(ve.paddingBottom, 10), ae = R.top + R.height / 2 - B, me = U - ae, de = f.offsetHeight / 2, b = f.offsetTop + de, N = j + k + b, _ = z - N;
      if (N <= ae) {
        const T = W.length > 0 && f === W[W.length - 1].ref.current;
        o.style.bottom = "0px";
        const I = d.clientHeight - i.offsetTop - i.offsetHeight, J = Math.max(
          me,
          de + // viewport might have padding bottom, include it to avoid a scrollable viewport
          (T ? Re : 0) + I + V
        ), ee = N + J;
        o.style.height = ee + "px";
      } else {
        const T = W.length > 0 && f === W[0].ref.current;
        o.style.top = "0px";
        const J = Math.max(
          ae,
          j + i.offsetTop + // viewport might have padding top, include it to avoid a scrollable viewport
          (T ? Ne : 0) + de
        ) + _;
        o.style.height = J + "px", i.scrollTop = N - ae + i.offsetTop;
      }
      o.style.margin = `${B}px 0`, o.style.minHeight = Pe + "px", o.style.maxHeight = U + "px", r == null || r(), requestAnimationFrame(() => w.current = !0);
    }
  }, [
    y,
    l.trigger,
    l.valueNode,
    o,
    d,
    i,
    f,
    S,
    l.dir,
    r
  ]);
  $(() => v(), [v]);
  const [E, x] = c.useState();
  $(() => {
    d && x(window.getComputedStyle(d).zIndex);
  }, [d]);
  const L = c.useCallback(
    (R) => {
      R && C.current === !0 && (v(), p == null || p(), C.current = !1);
    },
    [v, p]
  );
  return /* @__PURE__ */ m(
    gr,
    {
      scope: t,
      contentWrapper: o,
      shouldExpandOnScrollRef: w,
      onScrollButtonChange: L,
      children: /* @__PURE__ */ m(
        "div",
        {
          ref: u,
          style: {
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            zIndex: E
          },
          children: /* @__PURE__ */ m(
            O.div,
            {
              ...a,
              ref: h,
              style: {
                // When we get the height of the content, it includes borders. If we were to set
                // the height without having `boxSizing: 'border-box'` it would be too big.
                boxSizing: "border-box",
                // We need to ensure the content doesn't get taller than the wrapper
                maxHeight: "100%",
                ...a.style
              }
            }
          )
        }
      )
    }
  );
});
kt.displayName = mr;
var hr = "SelectPopperPosition", Ke = c.forwardRef((e, n) => {
  const {
    __scopeSelect: t,
    align: r = "start",
    collisionPadding: a = B,
    ...l
  } = e, s = Ie(t);
  return /* @__PURE__ */ m(
    an,
    {
      ...s,
      ...l,
      ref: n,
      align: r,
      collisionPadding: a,
      style: {
        // Ensure border-box for floating-ui calculations
        boxSizing: "border-box",
        ...l.style,
        "--radix-select-content-transform-origin": "var(--radix-popper-transform-origin)",
        "--radix-select-content-available-width": "var(--radix-popper-available-width)",
        "--radix-select-content-available-height": "var(--radix-popper-available-height)",
        "--radix-select-trigger-width": "var(--radix-popper-anchor-width)",
        "--radix-select-trigger-height": "var(--radix-popper-anchor-height)"
      }
    }
  );
});
Ke.displayName = hr;
var [gr, Ze] = ue(re, {}), je = "SelectViewport", _t = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, nonce: r, ...a } = e, l = q(je, t), s = Ze(je, t), o = F(n, l.onViewportChange), u = c.useRef(0);
    return /* @__PURE__ */ te(Xe, { children: [
      /* @__PURE__ */ m(
        "style",
        {
          dangerouslySetInnerHTML: {
            __html: "[data-radix-select-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-select-viewport]::-webkit-scrollbar{display:none}"
          },
          nonce: r
        }
      ),
      /* @__PURE__ */ m(xe.Slot, { scope: t, children: /* @__PURE__ */ m(
        O.div,
        {
          "data-radix-select-viewport": "",
          role: "presentation",
          ...a,
          ref: o,
          style: {
            // we use position: 'relative' here on the `viewport` so that when we call
            // `selectedItem.offsetTop` in calculations, the offset is relative to the viewport
            // (independent of the scrollUpButton).
            position: "relative",
            flex: 1,
            // Viewport should only be scrollable in the vertical direction.
            // This won't work in vertical writing modes, so we'll need to
            // revisit this if/when that is supported
            // https://developer.chrome.com/blog/vertical-form-controls
            overflow: "hidden auto",
            ...a.style
          },
          onScroll: M(a.onScroll, (d) => {
            const g = d.currentTarget, { contentWrapper: h, shouldExpandOnScrollRef: y } = s;
            if (y != null && y.current && h) {
              const w = Math.abs(u.current - g.scrollTop);
              if (w > 0) {
                const C = window.innerHeight - B * 2, i = parseFloat(h.style.minHeight), f = parseFloat(h.style.height), S = Math.max(i, f);
                if (S < C) {
                  const p = S + w, v = Math.min(C, p), E = p - v;
                  h.style.height = v + "px", h.style.bottom === "0px" && (g.scrollTop = E > 0 ? E : 0, h.style.justifyContent = "flex-end");
                }
              }
            }
            u.current = g.scrollTop;
          })
        }
      ) })
    ] });
  }
);
_t.displayName = je;
var Ot = "SelectGroup", [Sr, yr] = ue(Ot), Lt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e, a = $e();
    return /* @__PURE__ */ m(Sr, { scope: t, id: a, children: /* @__PURE__ */ m(O.div, { role: "group", "aria-labelledby": a, ...r, ref: n }) });
  }
);
Lt.displayName = Ot;
var Dt = "SelectLabel", Bt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e, a = yr(Dt, t);
    return /* @__PURE__ */ m(O.div, { id: a.id, ...r, ref: n });
  }
);
Bt.displayName = Dt;
var Ce = "SelectItem", [wr, Ft] = ue(Ce), Vt = c.forwardRef(
  (e, n) => {
    const {
      __scopeSelect: t,
      value: r,
      disabled: a = !1,
      textValue: l,
      ...s
    } = e, o = Z(Ce, t), u = q(Ce, t), d = o.value === r, [g, h] = c.useState(l ?? ""), [y, w] = c.useState(!1), C = F(
      n,
      (p) => {
        var v;
        return (v = u.itemRefCallback) == null ? void 0 : v.call(u, p, r, a);
      }
    ), i = $e(), f = c.useRef("touch"), S = () => {
      a || (o.onValueChange(r), o.onOpenChange(!1));
    };
    if (r === "")
      throw new Error(
        "A <Select.Item /> must have a value prop that is not an empty string. This is because the Select value can be set to an empty string to clear the selection and show the placeholder."
      );
    return /* @__PURE__ */ m(
      wr,
      {
        scope: t,
        value: r,
        disabled: a,
        textId: i,
        isSelected: d,
        onItemTextChange: c.useCallback((p) => {
          h((v) => v || ((p == null ? void 0 : p.textContent) ?? "").trim());
        }, []),
        children: /* @__PURE__ */ m(
          xe.ItemSlot,
          {
            scope: t,
            value: r,
            disabled: a,
            textValue: g,
            children: /* @__PURE__ */ m(
              O.div,
              {
                role: "option",
                "aria-labelledby": i,
                "data-highlighted": y ? "" : void 0,
                "aria-selected": d && y,
                "data-state": d ? "checked" : "unchecked",
                "aria-disabled": a || void 0,
                "data-disabled": a ? "" : void 0,
                tabIndex: a ? void 0 : -1,
                ...s,
                ref: C,
                onFocus: M(s.onFocus, () => w(!0)),
                onBlur: M(s.onBlur, () => w(!1)),
                onClick: M(s.onClick, () => {
                  f.current !== "mouse" && S();
                }),
                onPointerUp: M(s.onPointerUp, () => {
                  f.current === "mouse" && S();
                }),
                onPointerDown: M(s.onPointerDown, (p) => {
                  f.current = p.pointerType;
                }),
                onPointerMove: M(s.onPointerMove, (p) => {
                  var v;
                  f.current = p.pointerType, a ? (v = u.onItemLeave) == null || v.call(u) : f.current === "mouse" && p.currentTarget.focus({ preventScroll: !0 });
                }),
                onPointerLeave: M(s.onPointerLeave, (p) => {
                  var v;
                  p.currentTarget === document.activeElement && ((v = u.onItemLeave) == null || v.call(u));
                }),
                onKeyDown: M(s.onKeyDown, (p) => {
                  var E;
                  ((E = u.searchRef) == null ? void 0 : E.current) !== "" && p.key === " " || (ir.includes(p.key) && S(), p.key === " " && p.preventDefault());
                })
              }
            )
          }
        )
      }
    );
  }
);
Vt.displayName = Ce;
var fe = "SelectItemText", Ht = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, className: r, style: a, ...l } = e, s = Z(fe, t), o = q(fe, t), u = Ft(fe, t), d = dr(fe, t), [g, h] = c.useState(null), y = F(
      n,
      (S) => h(S),
      u.onItemTextChange,
      (S) => {
        var p;
        return (p = o.itemTextRefCallback) == null ? void 0 : p.call(o, S, u.value, u.disabled);
      }
    ), w = g == null ? void 0 : g.textContent, C = c.useMemo(
      () => /* @__PURE__ */ m("option", { value: u.value, disabled: u.disabled, children: w }, u.value),
      [u.disabled, u.value, w]
    ), { onNativeOptionAdd: i, onNativeOptionRemove: f } = d;
    return $(() => (i(C), () => f(C)), [i, f, C]), /* @__PURE__ */ te(Xe, { children: [
      /* @__PURE__ */ m(O.span, { id: u.textId, ...l, ref: y }),
      u.isSelected && s.valueNode && !s.valueNodeHasChildren ? lt.createPortal(l.children, s.valueNode) : null
    ] });
  }
);
Ht.displayName = fe;
var Wt = "SelectItemIndicator", br = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e;
    return Ft(Wt, t).isSelected ? /* @__PURE__ */ m(O.span, { "aria-hidden": !0, ...r, ref: n }) : null;
  }
);
br.displayName = Wt;
var Ge = "SelectScrollUpButton", Ut = c.forwardRef((e, n) => {
  const t = q(Ge, e.__scopeSelect), r = Ze(Ge, e.__scopeSelect), [a, l] = c.useState(!1), s = F(n, r.onScrollButtonChange);
  return $(() => {
    if (t.viewport && t.isPositioned) {
      let o = function() {
        const d = u.scrollTop > 0;
        l(d);
      };
      const u = t.viewport;
      return o(), u.addEventListener("scroll", o), () => u.removeEventListener("scroll", o);
    }
  }, [t.viewport, t.isPositioned]), a ? /* @__PURE__ */ m(
    jt,
    {
      ...e,
      ref: s,
      onAutoScroll: () => {
        const { viewport: o, selectedItem: u } = t;
        o && u && (o.scrollTop = o.scrollTop - u.offsetHeight);
      }
    }
  ) : null;
});
Ut.displayName = Ge;
var Ye = "SelectScrollDownButton", Kt = c.forwardRef((e, n) => {
  const t = q(Ye, e.__scopeSelect), r = Ze(Ye, e.__scopeSelect), [a, l] = c.useState(!1), s = F(n, r.onScrollButtonChange);
  return $(() => {
    if (t.viewport && t.isPositioned) {
      let o = function() {
        const d = u.scrollHeight - u.clientHeight, g = Math.ceil(u.scrollTop) < d;
        l(g);
      };
      const u = t.viewport;
      return o(), u.addEventListener("scroll", o), () => u.removeEventListener("scroll", o);
    }
  }, [t.viewport, t.isPositioned]), a ? /* @__PURE__ */ m(
    jt,
    {
      ...e,
      ref: s,
      onAutoScroll: () => {
        const { viewport: o, selectedItem: u } = t;
        o && u && (o.scrollTop = o.scrollTop + u.offsetHeight);
      }
    }
  ) : null;
});
Kt.displayName = Ye;
var jt = c.forwardRef((e, n) => {
  const { __scopeSelect: t, onAutoScroll: r, ...a } = e, l = q("SelectScrollButton", t), s = c.useRef(null), o = Te(t), u = c.useCallback(() => {
    s.current !== null && (window.clearInterval(s.current), s.current = null);
  }, []);
  return c.useEffect(() => () => u(), [u]), $(() => {
    var g;
    const d = o().find((h) => h.ref.current === document.activeElement);
    (g = d == null ? void 0 : d.ref.current) == null || g.scrollIntoView({ block: "nearest" });
  }, [o]), /* @__PURE__ */ m(
    O.div,
    {
      "aria-hidden": !0,
      ...a,
      ref: n,
      style: { flexShrink: 0, ...a.style },
      onPointerDown: M(a.onPointerDown, () => {
        s.current === null && (s.current = window.setInterval(r, 50));
      }),
      onPointerMove: M(a.onPointerMove, () => {
        var d;
        (d = l.onItemLeave) == null || d.call(l), s.current === null && (s.current = window.setInterval(r, 50));
      }),
      onPointerLeave: M(a.onPointerLeave, () => {
        u();
      })
    }
  );
}), Cr = "SelectSeparator", Gt = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e;
    return /* @__PURE__ */ m(O.div, { "aria-hidden": !0, ...r, ref: n });
  }
);
Gt.displayName = Cr;
var ze = "SelectArrow", Er = c.forwardRef(
  (e, n) => {
    const { __scopeSelect: t, ...r } = e, a = Ie(t), l = Z(ze, t), s = q(ze, t);
    return l.open && s.position === "popper" ? /* @__PURE__ */ m(cn, { ...a, ...r, ref: n }) : null;
  }
);
Er.displayName = ze;
function Yt(e) {
  return e === "" || e === void 0;
}
var zt = c.forwardRef(
  (e, n) => {
    const { value: t, ...r } = e, a = c.useRef(null), l = F(n, a), s = dn(t);
    return c.useEffect(() => {
      const o = a.current, u = window.HTMLSelectElement.prototype, g = Object.getOwnPropertyDescriptor(
        u,
        "value"
      ).set;
      if (s !== t && g) {
        const h = new Event("change", { bubbles: !0 });
        g.call(o, t), o.dispatchEvent(h);
      }
    }, [s, t]), /* @__PURE__ */ m(sn, { asChild: !0, children: /* @__PURE__ */ m("select", { ...r, ref: l, defaultValue: t }) });
  }
);
zt.displayName = "BubbleSelect";
function Xt(e) {
  const n = He(e), t = c.useRef(""), r = c.useRef(0), a = c.useCallback(
    (s) => {
      const o = t.current + s;
      n(o), function u(d) {
        t.current = d, window.clearTimeout(r.current), d !== "" && (r.current = window.setTimeout(() => u(""), 1e3));
      }(o);
    },
    [n]
  ), l = c.useCallback(() => {
    t.current = "", window.clearTimeout(r.current);
  }, []);
  return c.useEffect(() => () => window.clearTimeout(r.current), []), [t, a, l];
}
function $t(e, n, t) {
  const a = n.length > 1 && Array.from(n).every((d) => d === n[0]) ? n[0] : n, l = t ? e.indexOf(t) : -1;
  let s = xr(e, Math.max(l, 0));
  a.length === 1 && (s = s.filter((d) => d !== t));
  const u = s.find(
    (d) => d.textValue.toLowerCase().startsWith(a.toLowerCase())
  );
  return u !== t ? u : void 0;
}
function xr(e, n) {
  return e.map((t, r) => e[(n + r) % e.length]);
}
var Tr = Ct, Ir = xt, Pr = It, Nr = Pt, Rr = Nt, Mr = Rt, Ar = _t, kr = Lt, _r = Bt, Or = Vt, Lr = Ht, Dr = Ut, Br = Kt, Fr = Gt;
const Vr = Tr, Hr = Pr, Zt = oe(
  ({ className: e, hasError: n, children: t, ...r }, a) => /* @__PURE__ */ te(
    Ir,
    {
      ref: a,
      className: ne(
        // Base
        "group/trigger flex h-s-6 w-full items-center justify-between truncate rounded-sm pl-sp-4 pr-sp-3 text-body1 text-[--color-input-content] antialiased outline-none transition",
        // Border
        "border border-[--color-input-stroke] hover:border-[--color-input-stroke-hover]",
        // Focus
        "focus:border-[--color-input-stroke-active]",
        // Active
        "data-[state=open]:border-[--color-input-stroke-active]",
        // Disabled
        "data-[disabled]:border-[--color-input-stroke-disabled] data-[disabled]:text-[--color-input-content-disabled]",
        // Error
        {
          "border-[--color-input-stroke-danger] hover:border-[--color-input-stroke-danger] focus:border-[--color-input-stroke-danger] data-[state=open]:border-[--color-input-stroke-danger]": n
        },
        e
      ),
      ...r,
      children: [
        /* @__PURE__ */ m("span", { className: "truncate", children: t }),
        /* @__PURE__ */ m(
          Nr,
          {
            asChild: !0,
            className: "shrink-0 transition-transform group-data-[state=open]/trigger:rotate-180",
            children: /* @__PURE__ */ m(We, { name: "chevron-down" })
          }
        )
      ]
    }
  )
);
Zt.displayName = "SelectTrigger";
const qt = oe(
  ({
    className: e,
    position: n = "popper",
    children: t,
    sideOffset: r = 4,
    collisionPadding: a = 10,
    ...l
  }, s) => /* @__PURE__ */ m(Rr, { children: /* @__PURE__ */ te(
    Mr,
    {
      ref: s,
      className: ne(
        // Base
        "text-t1 max-h-[var(--radix-select-content-available-height)] max-w-[var(--radix-select-trigger-width)] rounded-lg bg-bg-secondary p-sp-2 antialiased shadow-xl",
        // Animation
        "will-change-[transform,opacity]",
        "data-[state=closed]:animate-hide",
        "data-[side=bottom]:animate-slide-down-and-fade data-[side=top]:animate-slide-up-and-fade",
        e
      ),
      sideOffset: r,
      position: n,
      collisionPadding: a,
      ...l,
      children: [
        /* @__PURE__ */ m(Dr, { className: "flex items-center justify-center py-sp-1", children: /* @__PURE__ */ m(We, { name: "chevron-up", size: "sm" }) }),
        /* @__PURE__ */ m(
          Ar,
          {
            className: ne("flex flex-col gap-sp-1", {
              "h-[var(--radix-select-trigger-height)] w-full min-w-[calc(var(--radix-select-trigger-width)-var(--fui-sp-2)*2)]": n === "popper"
            }),
            children: t
          }
        ),
        /* @__PURE__ */ m(Br, { className: "flex items-center justify-center py-sp-1", children: /* @__PURE__ */ m(We, { name: "chevron-down", size: "sm" }) })
      ]
    }
  ) })
);
qt.displayName = "SelectContent";
const Wr = oe(
  ({ className: e, children: n, ...t }, r) => /* @__PURE__ */ m(
    Or,
    {
      ref: r,
      className: ne(
        // Base
        "cursor-pointer select-none gap-sp-3 break-words rounded-md px-sp-4 py-sp-3 outline-none transition-colors",
        // Hover
        "hover:bg-action-primary-1 data-[highlighted]:bg-action-primary-1",
        // Active
        "data-[state=checked]:bg-action-primary-1 data-[state=checked]:text-action-primary-0-2",
        // Disabled
        "data-[disabled]:cursor-default data-[disabled]:bg-transparent data-[disabled]:text-action-disable-0",
        e
      ),
      ...t,
      children: /* @__PURE__ */ m(Lr, { children: n })
    }
  )
);
Wr.displayName = "SelectItem";
const Ur = oe(
  ({ className: e, ...n }, t) => /* @__PURE__ */ m(
    kr,
    {
      ref: t,
      className: ne("flex flex-col gap-sp-1", e),
      ...n
    }
  )
);
Ur.displayName = "SelectGroup";
const Kr = oe(
  ({ className: e, ...n }, t) => /* @__PURE__ */ m(
    _r,
    {
      ref: t,
      className: ne("px-sp-4 py-sp-3 text-h5", e),
      ...n
    }
  )
);
Kr.displayName = "SelectGroupLabel";
const jr = oe(
  ({ className: e, ...n }, t) => /* @__PURE__ */ m(
    Fr,
    {
      ref: t,
      className: ne("mx-sp-4 my-sp-2 h-[1px] bg-el-stroke-primary", e),
      ...n
    }
  )
);
jr.displayName = "SelectSeparator";
const Gr = oe(
  ({
    hasError: e,
    children: n,
    placeholder: t,
    onCloseAutoFocus: r,
    onEscapeKeyDown: a,
    onPointerDownOutside: l,
    id: s,
    contentClasses: o,
    triggerClasses: u,
    ...d
  }, g) => /* @__PURE__ */ te(Vr, { ...d, children: [
    /* @__PURE__ */ m(
      Zt,
      {
        hasError: e,
        id: s,
        ref: g,
        className: u,
        children: /* @__PURE__ */ m(Hr, { placeholder: t })
      }
    ),
    /* @__PURE__ */ m(
      qt,
      {
        className: o,
        onCloseAutoFocus: r,
        onEscapeKeyDown: a,
        onPointerDownOutside: l,
        children: n
      }
    )
  ] })
);
Gr.displayName = "Select";
export {
  Gr as Select,
  Ur as SelectGroup,
  Kr as SelectGroupLabel,
  Wr as SelectItem,
  jr as SelectSeparator
};
