import React from "react";
import Balancer from "react-wrap-balancer";
import { Builder } from "@builder.io/react";

import { deprecatedColors } from "@/styles/deprecated-colors";

import { Label, Text1, Text2, Text3, Text4, Text5, Text6 } from "./Text.styled";
import type { ComponentList, TextProps } from "./Text.types";
import { TextTypes } from "./Text.types";

const Texts: ComponentList = {
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  Text6,
  Label,
  // These are the old names and should be removed after the migration is done
  BodyXS: Label,
  BodyS: Label,
  BodyM: Label,
  BodyL: Label,
};

export const Text = ({
  color,
  text,
  type,
  balanceText,
  attributes: builderAttributes,
}: TextProps) => {
  const Component = Texts[type];
  const { key, ...restBuilderAttributes } = builderAttributes || {};

  return (
    <Component color={color} key={key} {...restBuilderAttributes}>
      {balanceText ? <Balancer>{text}</Balancer> : text}
    </Component>
  );
};

Builder.registerComponent(Text, {
  name: "Body Text",
  hideFromInsertMenu: true,
  noWrap: true,
  inputs: [
    {
      name: "balanceText",
      type: "boolean",
      defaultValue: false,
      helperText:
        "Balances the number of characters on each line, enhancing layout quality and legibility",
    },
    {
      name: "text",
      type: "string",
      defaultValue: "Text placeholder",
      helperText: "Displayed text",
    },
    {
      name: "type",
      friendlyName: "Variant",
      type: "string",
      defaultValue: "Text4",
      enum: [...Object.keys(TextTypes)],
      helperText: "Variant of the text",
    },
    {
      name: "color",
      type: "string",
      defaultValue: "b100",
      enum: [...Object.keys(deprecatedColors)],
      helperText: "Color of the text",
    },
  ],
});
