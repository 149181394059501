import React from "react";
import { useFormContext } from "react-hook-form";
import { Builder } from "@builder.io/sdk";

import { Icon } from "@flare/ui";

import { FUNNEL_NEXT_BUTTON } from "@/builder/components-sections";

import type { ButtonProps } from "./base/Button";
import { Button } from "./base/Button";

export type NextButtonProps = Pick<ButtonProps, "children" | "fullWidth"> & {
  withIcon?: boolean;
};

export const NextButton = ({
  children,
  withIcon,
  fullWidth,
}: NextButtonProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button type="submit" fullWidth={fullWidth} disabled={isSubmitting}>
      {children}
      {withIcon && <Icon name="arrow-right" />}
    </Button>
  );
};

Builder.registerComponent(NextButton, {
  name: FUNNEL_NEXT_BUTTON,
  inputs: [
    {
      name: "children",
      friendlyName: "Button text",
      type: "string",
      defaultValue: "Click me",
      helperText: "Text displayed on the button",
    },
    {
      name: "withIcon",
      type: "boolean",
      defaultValue: false,
      helperText: "Add an arrow right icon to the button",
    },
    {
      name: "fullWidth",
      type: "boolean",
      defaultValue: false,
      helperText: "Set the button to be full width",
    },
  ],
});
