import * as y from "react";
import { useLayoutEffect as Se, useEffect as Le } from "react";
import { c as wt } from "./index-47DXPxf5.js";
import { P as Z, d as De } from "./index-C8BDXGwJ.js";
import { u as mt } from "./index-DoOsnuB6.js";
import { u as ht, c as Te, a as xt } from "./index-CwaZVody.js";
import { jsx as $ } from "react/jsx-runtime";
import * as Me from "react-dom";
import Ne from "react-dom";
import { u as ke } from "./index-CI9iLup9.js";
function Fe(t, e = globalThis == null ? void 0 : globalThis.document) {
  const n = ht(t);
  y.useEffect(() => {
    const o = (r) => {
      r.key === "Escape" && n(r);
    };
    return e.addEventListener("keydown", o, { capture: !0 }), () => e.removeEventListener("keydown", o, { capture: !0 });
  }, [n, e]);
}
var We = "DismissableLayer", vt = "dismissableLayer.update", $e = "dismissableLayer.pointerDownOutside", Be = "dismissableLayer.focusOutside", Ht, Zt = y.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), He = y.forwardRef(
  (t, e) => {
    const {
      disableOutsidePointerEvents: n = !1,
      onEscapeKeyDown: o,
      onPointerDownOutside: r,
      onFocusOutside: i,
      onInteractOutside: s,
      onDismiss: c,
      ...a
    } = t, l = y.useContext(Zt), [f, d] = y.useState(null), p = (f == null ? void 0 : f.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, u] = y.useState({}), m = mt(e, (A) => d(A)), h = Array.from(l.layers), [w] = [...l.layersWithOutsidePointerEventsDisabled].slice(-1), g = h.indexOf(w), x = f ? h.indexOf(f) : -1, E = l.layersWithOutsidePointerEventsDisabled.size > 0, v = x >= g, b = Ve((A) => {
      const C = A.target, T = [...l.branches].some((L) => L.contains(C));
      !v || T || (r == null || r(A), s == null || s(A), A.defaultPrevented || c == null || c());
    }, p), P = ze((A) => {
      const C = A.target;
      [...l.branches].some((L) => L.contains(C)) || (i == null || i(A), s == null || s(A), A.defaultPrevented || c == null || c());
    }, p);
    return Fe((A) => {
      x === l.layers.size - 1 && (o == null || o(A), !A.defaultPrevented && c && (A.preventDefault(), c()));
    }, p), y.useEffect(() => {
      if (f)
        return n && (l.layersWithOutsidePointerEventsDisabled.size === 0 && (Ht = p.body.style.pointerEvents, p.body.style.pointerEvents = "none"), l.layersWithOutsidePointerEventsDisabled.add(f)), l.layers.add(f), _t(), () => {
          n && l.layersWithOutsidePointerEventsDisabled.size === 1 && (p.body.style.pointerEvents = Ht);
        };
    }, [f, p, n, l]), y.useEffect(() => () => {
      f && (l.layers.delete(f), l.layersWithOutsidePointerEventsDisabled.delete(f), _t());
    }, [f, l]), y.useEffect(() => {
      const A = () => u({});
      return document.addEventListener(vt, A), () => document.removeEventListener(vt, A);
    }, []), /* @__PURE__ */ $(
      Z.div,
      {
        ...a,
        ref: m,
        style: {
          pointerEvents: E ? v ? "auto" : "none" : void 0,
          ...t.style
        },
        onFocusCapture: wt(t.onFocusCapture, P.onFocusCapture),
        onBlurCapture: wt(t.onBlurCapture, P.onBlurCapture),
        onPointerDownCapture: wt(
          t.onPointerDownCapture,
          b.onPointerDownCapture
        )
      }
    );
  }
);
He.displayName = We;
var _e = "DismissableLayerBranch", Ie = y.forwardRef((t, e) => {
  const n = y.useContext(Zt), o = y.useRef(null), r = mt(e, o);
  return y.useEffect(() => {
    const i = o.current;
    if (i)
      return n.branches.add(i), () => {
        n.branches.delete(i);
      };
  }, [n.branches]), /* @__PURE__ */ $(Z.div, { ...t, ref: r });
});
Ie.displayName = _e;
function Ve(t, e = globalThis == null ? void 0 : globalThis.document) {
  const n = ht(t), o = y.useRef(!1), r = y.useRef(() => {
  });
  return y.useEffect(() => {
    const i = (c) => {
      if (c.target && !o.current) {
        let a = function() {
          Gt(
            $e,
            n,
            l,
            { discrete: !0 }
          );
        };
        const l = { originalEvent: c };
        c.pointerType === "touch" ? (e.removeEventListener("click", r.current), r.current = a, e.addEventListener("click", r.current, { once: !0 })) : a();
      } else
        e.removeEventListener("click", r.current);
      o.current = !1;
    }, s = window.setTimeout(() => {
      e.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(s), e.removeEventListener("pointerdown", i), e.removeEventListener("click", r.current);
    };
  }, [e, n]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function ze(t, e = globalThis == null ? void 0 : globalThis.document) {
  const n = ht(t), o = y.useRef(!1);
  return y.useEffect(() => {
    const r = (i) => {
      i.target && !o.current && Gt(Be, n, { originalEvent: i }, {
        discrete: !1
      });
    };
    return e.addEventListener("focusin", r), () => e.removeEventListener("focusin", r);
  }, [e, n]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function _t() {
  const t = new CustomEvent(vt);
  document.dispatchEvent(t);
}
function Gt(t, e, n, { discrete: o }) {
  const r = n.originalEvent.target, i = new CustomEvent(t, { bubbles: !1, cancelable: !0, detail: n });
  e && r.addEventListener(t, e, { once: !0 }), o ? De(r, i) : r.dispatchEvent(i);
}
const Ye = ["top", "right", "bottom", "left"], _ = Math.min, W = Math.max, ft = Math.round, lt = Math.floor, U = (t) => ({
  x: t,
  y: t
}), Xe = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, je = {
  start: "end",
  end: "start"
};
function bt(t, e, n) {
  return W(t, _(e, n));
}
function Y(t, e) {
  return typeof t == "function" ? t(e) : t;
}
function X(t) {
  return t.split("-")[0];
}
function Q(t) {
  return t.split("-")[1];
}
function Et(t) {
  return t === "x" ? "y" : "x";
}
function Pt(t) {
  return t === "y" ? "height" : "width";
}
function tt(t) {
  return ["top", "bottom"].includes(X(t)) ? "y" : "x";
}
function Rt(t) {
  return Et(tt(t));
}
function Ue(t, e, n) {
  n === void 0 && (n = !1);
  const o = Q(t), r = Rt(t), i = Pt(r);
  let s = r === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return e.reference[i] > e.floating[i] && (s = ut(s)), [s, ut(s)];
}
function qe(t) {
  const e = ut(t);
  return [At(t), e, At(e)];
}
function At(t) {
  return t.replace(/start|end/g, (e) => je[e]);
}
function Ke(t, e, n) {
  const o = ["left", "right"], r = ["right", "left"], i = ["top", "bottom"], s = ["bottom", "top"];
  switch (t) {
    case "top":
    case "bottom":
      return n ? e ? r : o : e ? o : r;
    case "left":
    case "right":
      return e ? i : s;
    default:
      return [];
  }
}
function Ze(t, e, n, o) {
  const r = Q(t);
  let i = Ke(X(t), n === "start", o);
  return r && (i = i.map((s) => s + "-" + r), e && (i = i.concat(i.map(At)))), i;
}
function ut(t) {
  return t.replace(/left|right|bottom|top/g, (e) => Xe[e]);
}
function Ge(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function Jt(t) {
  return typeof t != "number" ? Ge(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function dt(t) {
  const {
    x: e,
    y: n,
    width: o,
    height: r
  } = t;
  return {
    width: o,
    height: r,
    top: n,
    left: e,
    right: e + o,
    bottom: n + r,
    x: e,
    y: n
  };
}
function It(t, e, n) {
  let {
    reference: o,
    floating: r
  } = t;
  const i = tt(e), s = Rt(e), c = Pt(s), a = X(e), l = i === "y", f = o.x + o.width / 2 - r.width / 2, d = o.y + o.height / 2 - r.height / 2, p = o[c] / 2 - r[c] / 2;
  let u;
  switch (a) {
    case "top":
      u = {
        x: f,
        y: o.y - r.height
      };
      break;
    case "bottom":
      u = {
        x: f,
        y: o.y + o.height
      };
      break;
    case "right":
      u = {
        x: o.x + o.width,
        y: d
      };
      break;
    case "left":
      u = {
        x: o.x - r.width,
        y: d
      };
      break;
    default:
      u = {
        x: o.x,
        y: o.y
      };
  }
  switch (Q(e)) {
    case "start":
      u[s] -= p * (n && l ? -1 : 1);
      break;
    case "end":
      u[s] += p * (n && l ? -1 : 1);
      break;
  }
  return u;
}
const Je = async (t, e, n) => {
  const {
    placement: o = "bottom",
    strategy: r = "absolute",
    middleware: i = [],
    platform: s
  } = n, c = i.filter(Boolean), a = await (s.isRTL == null ? void 0 : s.isRTL(e));
  let l = await s.getElementRects({
    reference: t,
    floating: e,
    strategy: r
  }), {
    x: f,
    y: d
  } = It(l, o, a), p = o, u = {}, m = 0;
  for (let h = 0; h < c.length; h++) {
    const {
      name: w,
      fn: g
    } = c[h], {
      x,
      y: E,
      data: v,
      reset: b
    } = await g({
      x: f,
      y: d,
      initialPlacement: o,
      placement: p,
      strategy: r,
      middlewareData: u,
      rects: l,
      platform: s,
      elements: {
        reference: t,
        floating: e
      }
    });
    f = x ?? f, d = E ?? d, u = {
      ...u,
      [w]: {
        ...u[w],
        ...v
      }
    }, b && m <= 50 && (m++, typeof b == "object" && (b.placement && (p = b.placement), b.rects && (l = b.rects === !0 ? await s.getElementRects({
      reference: t,
      floating: e,
      strategy: r
    }) : b.rects), {
      x: f,
      y: d
    } = It(l, p, a)), h = -1);
  }
  return {
    x: f,
    y: d,
    placement: p,
    strategy: r,
    middlewareData: u
  };
};
async function ot(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: o,
    y: r,
    platform: i,
    rects: s,
    elements: c,
    strategy: a
  } = t, {
    boundary: l = "clippingAncestors",
    rootBoundary: f = "viewport",
    elementContext: d = "floating",
    altBoundary: p = !1,
    padding: u = 0
  } = Y(e, t), m = Jt(u), w = c[p ? d === "floating" ? "reference" : "floating" : d], g = dt(await i.getClippingRect({
    element: (n = await (i.isElement == null ? void 0 : i.isElement(w))) == null || n ? w : w.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(c.floating)),
    boundary: l,
    rootBoundary: f,
    strategy: a
  })), x = d === "floating" ? {
    x: o,
    y: r,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, E = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(c.floating)), v = await (i.isElement == null ? void 0 : i.isElement(E)) ? await (i.getScale == null ? void 0 : i.getScale(E)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, b = dt(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: c,
    rect: x,
    offsetParent: E,
    strategy: a
  }) : x);
  return {
    top: (g.top - b.top + m.top) / v.y,
    bottom: (b.bottom - g.bottom + m.bottom) / v.y,
    left: (g.left - b.left + m.left) / v.x,
    right: (b.right - g.right + m.right) / v.x
  };
}
const Qe = (t) => ({
  name: "arrow",
  options: t,
  async fn(e) {
    const {
      x: n,
      y: o,
      placement: r,
      rects: i,
      platform: s,
      elements: c,
      middlewareData: a
    } = e, {
      element: l,
      padding: f = 0
    } = Y(t, e) || {};
    if (l == null)
      return {};
    const d = Jt(f), p = {
      x: n,
      y: o
    }, u = Rt(r), m = Pt(u), h = await s.getDimensions(l), w = u === "y", g = w ? "top" : "left", x = w ? "bottom" : "right", E = w ? "clientHeight" : "clientWidth", v = i.reference[m] + i.reference[u] - p[u] - i.floating[m], b = p[u] - i.reference[u], P = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(l));
    let A = P ? P[E] : 0;
    (!A || !await (s.isElement == null ? void 0 : s.isElement(P))) && (A = c.floating[E] || i.floating[m]);
    const C = v / 2 - b / 2, T = A / 2 - h[m] / 2 - 1, L = _(d[g], T), M = _(d[x], T), S = L, k = A - h[m] - M, D = A / 2 - h[m] / 2 + C, O = bt(S, D, k), N = !a.arrow && Q(r) != null && D !== O && i.reference[m] / 2 - (D < S ? L : M) - h[m] / 2 < 0, R = N ? D < S ? D - S : D - k : 0;
    return {
      [u]: p[u] + R,
      data: {
        [u]: O,
        centerOffset: D - O - R,
        ...N && {
          alignmentOffset: R
        }
      },
      reset: N
    };
  }
}), tn = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n, o;
      const {
        placement: r,
        middlewareData: i,
        rects: s,
        initialPlacement: c,
        platform: a,
        elements: l
      } = e, {
        mainAxis: f = !0,
        crossAxis: d = !0,
        fallbackPlacements: p,
        fallbackStrategy: u = "bestFit",
        fallbackAxisSideDirection: m = "none",
        flipAlignment: h = !0,
        ...w
      } = Y(t, e);
      if ((n = i.arrow) != null && n.alignmentOffset)
        return {};
      const g = X(r), x = X(c) === c, E = await (a.isRTL == null ? void 0 : a.isRTL(l.floating)), v = p || (x || !h ? [ut(c)] : qe(c));
      !p && m !== "none" && v.push(...Ze(c, h, m, E));
      const b = [c, ...v], P = await ot(e, w), A = [];
      let C = ((o = i.flip) == null ? void 0 : o.overflows) || [];
      if (f && A.push(P[g]), d) {
        const S = Ue(r, s, E);
        A.push(P[S[0]], P[S[1]]);
      }
      if (C = [...C, {
        placement: r,
        overflows: A
      }], !A.every((S) => S <= 0)) {
        var T, L;
        const S = (((T = i.flip) == null ? void 0 : T.index) || 0) + 1, k = b[S];
        if (k)
          return {
            data: {
              index: S,
              overflows: C
            },
            reset: {
              placement: k
            }
          };
        let D = (L = C.filter((O) => O.overflows[0] <= 0).sort((O, N) => O.overflows[1] - N.overflows[1])[0]) == null ? void 0 : L.placement;
        if (!D)
          switch (u) {
            case "bestFit": {
              var M;
              const O = (M = C.map((N) => [N.placement, N.overflows.filter((R) => R > 0).reduce((R, F) => R + F, 0)]).sort((N, R) => N[1] - R[1])[0]) == null ? void 0 : M[0];
              O && (D = O);
              break;
            }
            case "initialPlacement":
              D = c;
              break;
          }
        if (r !== D)
          return {
            reset: {
              placement: D
            }
          };
      }
      return {};
    }
  };
};
function Vt(t, e) {
  return {
    top: t.top - e.height,
    right: t.right - e.width,
    bottom: t.bottom - e.height,
    left: t.left - e.width
  };
}
function zt(t) {
  return Ye.some((e) => t[e] >= 0);
}
const en = function(t) {
  return t === void 0 && (t = {}), {
    name: "hide",
    options: t,
    async fn(e) {
      const {
        rects: n
      } = e, {
        strategy: o = "referenceHidden",
        ...r
      } = Y(t, e);
      switch (o) {
        case "referenceHidden": {
          const i = await ot(e, {
            ...r,
            elementContext: "reference"
          }), s = Vt(i, n.reference);
          return {
            data: {
              referenceHiddenOffsets: s,
              referenceHidden: zt(s)
            }
          };
        }
        case "escaped": {
          const i = await ot(e, {
            ...r,
            altBoundary: !0
          }), s = Vt(i, n.floating);
          return {
            data: {
              escapedOffsets: s,
              escaped: zt(s)
            }
          };
        }
        default:
          return {};
      }
    }
  };
};
async function nn(t, e) {
  const {
    placement: n,
    platform: o,
    elements: r
  } = t, i = await (o.isRTL == null ? void 0 : o.isRTL(r.floating)), s = X(n), c = Q(n), a = tt(n) === "y", l = ["left", "top"].includes(s) ? -1 : 1, f = i && a ? -1 : 1, d = Y(e, t);
  let {
    mainAxis: p,
    crossAxis: u,
    alignmentAxis: m
  } = typeof d == "number" ? {
    mainAxis: d,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...d
  };
  return c && typeof m == "number" && (u = c === "end" ? m * -1 : m), a ? {
    x: u * f,
    y: p * l
  } : {
    x: p * l,
    y: u * f
  };
}
const on = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      var n, o;
      const {
        x: r,
        y: i,
        placement: s,
        middlewareData: c
      } = e, a = await nn(e, t);
      return s === ((n = c.offset) == null ? void 0 : n.placement) && (o = c.arrow) != null && o.alignmentOffset ? {} : {
        x: r + a.x,
        y: i + a.y,
        data: {
          ...a,
          placement: s
        }
      };
    }
  };
}, rn = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o,
        placement: r
      } = e, {
        mainAxis: i = !0,
        crossAxis: s = !1,
        limiter: c = {
          fn: (w) => {
            let {
              x: g,
              y: x
            } = w;
            return {
              x: g,
              y: x
            };
          }
        },
        ...a
      } = Y(t, e), l = {
        x: n,
        y: o
      }, f = await ot(e, a), d = tt(X(r)), p = Et(d);
      let u = l[p], m = l[d];
      if (i) {
        const w = p === "y" ? "top" : "left", g = p === "y" ? "bottom" : "right", x = u + f[w], E = u - f[g];
        u = bt(x, u, E);
      }
      if (s) {
        const w = d === "y" ? "top" : "left", g = d === "y" ? "bottom" : "right", x = m + f[w], E = m - f[g];
        m = bt(x, m, E);
      }
      const h = c.fn({
        ...e,
        [p]: u,
        [d]: m
      });
      return {
        ...h,
        data: {
          x: h.x - n,
          y: h.y - o
        }
      };
    }
  };
}, sn = function(t) {
  return t === void 0 && (t = {}), {
    options: t,
    fn(e) {
      const {
        x: n,
        y: o,
        placement: r,
        rects: i,
        middlewareData: s
      } = e, {
        offset: c = 0,
        mainAxis: a = !0,
        crossAxis: l = !0
      } = Y(t, e), f = {
        x: n,
        y: o
      }, d = tt(r), p = Et(d);
      let u = f[p], m = f[d];
      const h = Y(c, e), w = typeof h == "number" ? {
        mainAxis: h,
        crossAxis: 0
      } : {
        mainAxis: 0,
        crossAxis: 0,
        ...h
      };
      if (a) {
        const E = p === "y" ? "height" : "width", v = i.reference[p] - i.floating[E] + w.mainAxis, b = i.reference[p] + i.reference[E] - w.mainAxis;
        u < v ? u = v : u > b && (u = b);
      }
      if (l) {
        var g, x;
        const E = p === "y" ? "width" : "height", v = ["top", "left"].includes(X(r)), b = i.reference[d] - i.floating[E] + (v && ((g = s.offset) == null ? void 0 : g[d]) || 0) + (v ? 0 : w.crossAxis), P = i.reference[d] + i.reference[E] + (v ? 0 : ((x = s.offset) == null ? void 0 : x[d]) || 0) - (v ? w.crossAxis : 0);
        m < b ? m = b : m > P && (m = P);
      }
      return {
        [p]: u,
        [d]: m
      };
    }
  };
}, cn = function(t) {
  return t === void 0 && (t = {}), {
    name: "size",
    options: t,
    async fn(e) {
      const {
        placement: n,
        rects: o,
        platform: r,
        elements: i
      } = e, {
        apply: s = () => {
        },
        ...c
      } = Y(t, e), a = await ot(e, c), l = X(n), f = Q(n), d = tt(n) === "y", {
        width: p,
        height: u
      } = o.floating;
      let m, h;
      l === "top" || l === "bottom" ? (m = l, h = f === (await (r.isRTL == null ? void 0 : r.isRTL(i.floating)) ? "start" : "end") ? "left" : "right") : (h = l, m = f === "end" ? "top" : "bottom");
      const w = u - a.top - a.bottom, g = p - a.left - a.right, x = _(u - a[m], w), E = _(p - a[h], g), v = !e.middlewareData.shift;
      let b = x, P = E;
      if (d ? P = f || v ? _(E, g) : g : b = f || v ? _(x, w) : w, v && !f) {
        const C = W(a.left, 0), T = W(a.right, 0), L = W(a.top, 0), M = W(a.bottom, 0);
        d ? P = p - 2 * (C !== 0 || T !== 0 ? C + T : W(a.left, a.right)) : b = u - 2 * (L !== 0 || M !== 0 ? L + M : W(a.top, a.bottom));
      }
      await s({
        ...e,
        availableWidth: P,
        availableHeight: b
      });
      const A = await r.getDimensions(i.floating);
      return p !== A.width || u !== A.height ? {
        reset: {
          rects: !0
        }
      } : {};
    }
  };
};
function et(t) {
  return Qt(t) ? (t.nodeName || "").toLowerCase() : "#document";
}
function B(t) {
  var e;
  return (t == null || (e = t.ownerDocument) == null ? void 0 : e.defaultView) || window;
}
function j(t) {
  var e;
  return (e = (Qt(t) ? t.ownerDocument : t.document) || window.document) == null ? void 0 : e.documentElement;
}
function Qt(t) {
  return t instanceof Node || t instanceof B(t).Node;
}
function I(t) {
  return t instanceof Element || t instanceof B(t).Element;
}
function V(t) {
  return t instanceof HTMLElement || t instanceof B(t).HTMLElement;
}
function Yt(t) {
  return typeof ShadowRoot > "u" ? !1 : t instanceof ShadowRoot || t instanceof B(t).ShadowRoot;
}
function it(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: o,
    display: r
  } = H(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + o + n) && !["inline", "contents"].includes(r);
}
function ln(t) {
  return ["table", "td", "th"].includes(et(t));
}
function Ct(t) {
  const e = Ot(), n = H(t);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !e && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !e && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((o) => (n.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some((o) => (n.contain || "").includes(o));
}
function an(t) {
  let e = q(t);
  for (; V(e) && !J(e); ) {
    if (Ct(e))
      return e;
    e = q(e);
  }
  return null;
}
function Ot() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function J(t) {
  return ["html", "body", "#document"].includes(et(t));
}
function H(t) {
  return B(t).getComputedStyle(t);
}
function gt(t) {
  return I(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function q(t) {
  if (et(t) === "html")
    return t;
  const e = (
    // Step into the shadow DOM of the parent of a slotted node.
    t.assignedSlot || // DOM Element detected.
    t.parentNode || // ShadowRoot detected.
    Yt(t) && t.host || // Fallback.
    j(t)
  );
  return Yt(e) ? e.host : e;
}
function te(t) {
  const e = q(t);
  return J(e) ? t.ownerDocument ? t.ownerDocument.body : t.body : V(e) && it(e) ? e : te(e);
}
function rt(t, e, n) {
  var o;
  e === void 0 && (e = []), n === void 0 && (n = !0);
  const r = te(t), i = r === ((o = t.ownerDocument) == null ? void 0 : o.body), s = B(r);
  return i ? e.concat(s, s.visualViewport || [], it(r) ? r : [], s.frameElement && n ? rt(s.frameElement) : []) : e.concat(r, rt(r, [], n));
}
function ee(t) {
  const e = H(t);
  let n = parseFloat(e.width) || 0, o = parseFloat(e.height) || 0;
  const r = V(t), i = r ? t.offsetWidth : n, s = r ? t.offsetHeight : o, c = ft(n) !== i || ft(o) !== s;
  return c && (n = i, o = s), {
    width: n,
    height: o,
    $: c
  };
}
function St(t) {
  return I(t) ? t : t.contextElement;
}
function G(t) {
  const e = St(t);
  if (!V(e))
    return U(1);
  const n = e.getBoundingClientRect(), {
    width: o,
    height: r,
    $: i
  } = ee(e);
  let s = (i ? ft(n.width) : n.width) / o, c = (i ? ft(n.height) : n.height) / r;
  return (!s || !Number.isFinite(s)) && (s = 1), (!c || !Number.isFinite(c)) && (c = 1), {
    x: s,
    y: c
  };
}
const fn = /* @__PURE__ */ U(0);
function ne(t) {
  const e = B(t);
  return !Ot() || !e.visualViewport ? fn : {
    x: e.visualViewport.offsetLeft,
    y: e.visualViewport.offsetTop
  };
}
function un(t, e, n) {
  return e === void 0 && (e = !1), !n || e && n !== B(t) ? !1 : e;
}
function K(t, e, n, o) {
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const r = t.getBoundingClientRect(), i = St(t);
  let s = U(1);
  e && (o ? I(o) && (s = G(o)) : s = G(t));
  const c = un(i, n, o) ? ne(i) : U(0);
  let a = (r.left + c.x) / s.x, l = (r.top + c.y) / s.y, f = r.width / s.x, d = r.height / s.y;
  if (i) {
    const p = B(i), u = o && I(o) ? B(o) : o;
    let m = p, h = m.frameElement;
    for (; h && o && u !== m; ) {
      const w = G(h), g = h.getBoundingClientRect(), x = H(h), E = g.left + (h.clientLeft + parseFloat(x.paddingLeft)) * w.x, v = g.top + (h.clientTop + parseFloat(x.paddingTop)) * w.y;
      a *= w.x, l *= w.y, f *= w.x, d *= w.y, a += E, l += v, m = B(h), h = m.frameElement;
    }
  }
  return dt({
    width: f,
    height: d,
    x: a,
    y: l
  });
}
const dn = [":popover-open", ":modal"];
function Lt(t) {
  return dn.some((e) => {
    try {
      return t.matches(e);
    } catch {
      return !1;
    }
  });
}
function pn(t) {
  let {
    elements: e,
    rect: n,
    offsetParent: o,
    strategy: r
  } = t;
  const i = r === "fixed", s = j(o), c = e ? Lt(e.floating) : !1;
  if (o === s || c && i)
    return n;
  let a = {
    scrollLeft: 0,
    scrollTop: 0
  }, l = U(1);
  const f = U(0), d = V(o);
  if ((d || !d && !i) && ((et(o) !== "body" || it(s)) && (a = gt(o)), V(o))) {
    const p = K(o);
    l = G(o), f.x = p.x + o.clientLeft, f.y = p.y + o.clientTop;
  }
  return {
    width: n.width * l.x,
    height: n.height * l.y,
    x: n.x * l.x - a.scrollLeft * l.x + f.x,
    y: n.y * l.y - a.scrollTop * l.y + f.y
  };
}
function mn(t) {
  return Array.from(t.getClientRects());
}
function oe(t) {
  return K(j(t)).left + gt(t).scrollLeft;
}
function hn(t) {
  const e = j(t), n = gt(t), o = t.ownerDocument.body, r = W(e.scrollWidth, e.clientWidth, o.scrollWidth, o.clientWidth), i = W(e.scrollHeight, e.clientHeight, o.scrollHeight, o.clientHeight);
  let s = -n.scrollLeft + oe(t);
  const c = -n.scrollTop;
  return H(o).direction === "rtl" && (s += W(e.clientWidth, o.clientWidth) - r), {
    width: r,
    height: i,
    x: s,
    y: c
  };
}
function gn(t, e) {
  const n = B(t), o = j(t), r = n.visualViewport;
  let i = o.clientWidth, s = o.clientHeight, c = 0, a = 0;
  if (r) {
    i = r.width, s = r.height;
    const l = Ot();
    (!l || l && e === "fixed") && (c = r.offsetLeft, a = r.offsetTop);
  }
  return {
    width: i,
    height: s,
    x: c,
    y: a
  };
}
function wn(t, e) {
  const n = K(t, !0, e === "fixed"), o = n.top + t.clientTop, r = n.left + t.clientLeft, i = V(t) ? G(t) : U(1), s = t.clientWidth * i.x, c = t.clientHeight * i.y, a = r * i.x, l = o * i.y;
  return {
    width: s,
    height: c,
    x: a,
    y: l
  };
}
function Xt(t, e, n) {
  let o;
  if (e === "viewport")
    o = gn(t, n);
  else if (e === "document")
    o = hn(j(t));
  else if (I(e))
    o = wn(e, n);
  else {
    const r = ne(t);
    o = {
      ...e,
      x: e.x - r.x,
      y: e.y - r.y
    };
  }
  return dt(o);
}
function re(t, e) {
  const n = q(t);
  return n === e || !I(n) || J(n) ? !1 : H(n).position === "fixed" || re(n, e);
}
function yn(t, e) {
  const n = e.get(t);
  if (n)
    return n;
  let o = rt(t, [], !1).filter((c) => I(c) && et(c) !== "body"), r = null;
  const i = H(t).position === "fixed";
  let s = i ? q(t) : t;
  for (; I(s) && !J(s); ) {
    const c = H(s), a = Ct(s);
    !a && c.position === "fixed" && (r = null), (i ? !a && !r : !a && c.position === "static" && !!r && ["absolute", "fixed"].includes(r.position) || it(s) && !a && re(t, s)) ? o = o.filter((f) => f !== s) : r = c, s = q(s);
  }
  return e.set(t, o), o;
}
function xn(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: o,
    strategy: r
  } = t;
  const s = [...n === "clippingAncestors" ? Lt(e) ? [] : yn(e, this._c) : [].concat(n), o], c = s[0], a = s.reduce((l, f) => {
    const d = Xt(e, f, r);
    return l.top = W(d.top, l.top), l.right = _(d.right, l.right), l.bottom = _(d.bottom, l.bottom), l.left = W(d.left, l.left), l;
  }, Xt(e, c, r));
  return {
    width: a.right - a.left,
    height: a.bottom - a.top,
    x: a.left,
    y: a.top
  };
}
function vn(t) {
  const {
    width: e,
    height: n
  } = ee(t);
  return {
    width: e,
    height: n
  };
}
function bn(t, e, n) {
  const o = V(e), r = j(e), i = n === "fixed", s = K(t, !0, i, e);
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const a = U(0);
  if (o || !o && !i)
    if ((et(e) !== "body" || it(r)) && (c = gt(e)), o) {
      const d = K(e, !0, i, e);
      a.x = d.x + e.clientLeft, a.y = d.y + e.clientTop;
    } else r && (a.x = oe(r));
  const l = s.left + c.scrollLeft - a.x, f = s.top + c.scrollTop - a.y;
  return {
    x: l,
    y: f,
    width: s.width,
    height: s.height
  };
}
function yt(t) {
  return H(t).position === "static";
}
function jt(t, e) {
  return !V(t) || H(t).position === "fixed" ? null : e ? e(t) : t.offsetParent;
}
function ie(t, e) {
  const n = B(t);
  if (Lt(t))
    return n;
  if (!V(t)) {
    let r = q(t);
    for (; r && !J(r); ) {
      if (I(r) && !yt(r))
        return r;
      r = q(r);
    }
    return n;
  }
  let o = jt(t, e);
  for (; o && ln(o) && yt(o); )
    o = jt(o, e);
  return o && J(o) && yt(o) && !Ct(o) ? n : o || an(t) || n;
}
const An = async function(t) {
  const e = this.getOffsetParent || ie, n = this.getDimensions, o = await n(t.floating);
  return {
    reference: bn(t.reference, await e(t.floating), t.strategy),
    floating: {
      x: 0,
      y: 0,
      width: o.width,
      height: o.height
    }
  };
};
function En(t) {
  return H(t).direction === "rtl";
}
const Pn = {
  convertOffsetParentRelativeRectToViewportRelativeRect: pn,
  getDocumentElement: j,
  getClippingRect: xn,
  getOffsetParent: ie,
  getElementRects: An,
  getClientRects: mn,
  getDimensions: vn,
  getScale: G,
  isElement: I,
  isRTL: En
};
function Rn(t, e) {
  let n = null, o;
  const r = j(t);
  function i() {
    var c;
    clearTimeout(o), (c = n) == null || c.disconnect(), n = null;
  }
  function s(c, a) {
    c === void 0 && (c = !1), a === void 0 && (a = 1), i();
    const {
      left: l,
      top: f,
      width: d,
      height: p
    } = t.getBoundingClientRect();
    if (c || e(), !d || !p)
      return;
    const u = lt(f), m = lt(r.clientWidth - (l + d)), h = lt(r.clientHeight - (f + p)), w = lt(l), x = {
      rootMargin: -u + "px " + -m + "px " + -h + "px " + -w + "px",
      threshold: W(0, _(1, a)) || 1
    };
    let E = !0;
    function v(b) {
      const P = b[0].intersectionRatio;
      if (P !== a) {
        if (!E)
          return s();
        P ? s(!1, P) : o = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      E = !1;
    }
    try {
      n = new IntersectionObserver(v, {
        ...x,
        // Handle <iframe>s
        root: r.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(v, x);
    }
    n.observe(t);
  }
  return s(!0), i;
}
function Cn(t, e, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: r = !0,
    ancestorResize: i = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: c = typeof IntersectionObserver == "function",
    animationFrame: a = !1
  } = o, l = St(t), f = r || i ? [...l ? rt(l) : [], ...rt(e)] : [];
  f.forEach((g) => {
    r && g.addEventListener("scroll", n, {
      passive: !0
    }), i && g.addEventListener("resize", n);
  });
  const d = l && c ? Rn(l, n) : null;
  let p = -1, u = null;
  s && (u = new ResizeObserver((g) => {
    let [x] = g;
    x && x.target === l && u && (u.unobserve(e), cancelAnimationFrame(p), p = requestAnimationFrame(() => {
      var E;
      (E = u) == null || E.observe(e);
    })), n();
  }), l && !a && u.observe(l), u.observe(e));
  let m, h = a ? K(t) : null;
  a && w();
  function w() {
    const g = K(t);
    h && (g.x !== h.x || g.y !== h.y || g.width !== h.width || g.height !== h.height) && n(), h = g, m = requestAnimationFrame(w);
  }
  return n(), () => {
    var g;
    f.forEach((x) => {
      r && x.removeEventListener("scroll", n), i && x.removeEventListener("resize", n);
    }), d == null || d(), (g = u) == null || g.disconnect(), u = null, a && cancelAnimationFrame(m);
  };
}
const On = on, Sn = rn, Ln = tn, Dn = cn, Tn = en, Ut = Qe, Mn = sn, Nn = (t, e, n) => {
  const o = /* @__PURE__ */ new Map(), r = {
    platform: Pn,
    ...n
  }, i = {
    ...r.platform,
    _c: o
  };
  return Je(t, e, {
    ...r,
    platform: i
  });
};
var at = typeof document < "u" ? Se : Le;
function pt(t, e) {
  if (t === e)
    return !0;
  if (typeof t != typeof e)
    return !1;
  if (typeof t == "function" && t.toString() === e.toString())
    return !0;
  let n, o, r;
  if (t && e && typeof t == "object") {
    if (Array.isArray(t)) {
      if (n = t.length, n !== e.length) return !1;
      for (o = n; o-- !== 0; )
        if (!pt(t[o], e[o]))
          return !1;
      return !0;
    }
    if (r = Object.keys(t), n = r.length, n !== Object.keys(e).length)
      return !1;
    for (o = n; o-- !== 0; )
      if (!{}.hasOwnProperty.call(e, r[o]))
        return !1;
    for (o = n; o-- !== 0; ) {
      const i = r[o];
      if (!(i === "_owner" && t.$$typeof) && !pt(t[i], e[i]))
        return !1;
    }
    return !0;
  }
  return t !== t && e !== e;
}
function se(t) {
  return typeof window > "u" ? 1 : (t.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function qt(t, e) {
  const n = se(t);
  return Math.round(e * n) / n;
}
function Kt(t) {
  const e = y.useRef(t);
  return at(() => {
    e.current = t;
  }), e;
}
function kn(t) {
  t === void 0 && (t = {});
  const {
    placement: e = "bottom",
    strategy: n = "absolute",
    middleware: o = [],
    platform: r,
    elements: {
      reference: i,
      floating: s
    } = {},
    transform: c = !0,
    whileElementsMounted: a,
    open: l
  } = t, [f, d] = y.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: e,
    middlewareData: {},
    isPositioned: !1
  }), [p, u] = y.useState(o);
  pt(p, o) || u(o);
  const [m, h] = y.useState(null), [w, g] = y.useState(null), x = y.useCallback((R) => {
    R !== P.current && (P.current = R, h(R));
  }, []), E = y.useCallback((R) => {
    R !== A.current && (A.current = R, g(R));
  }, []), v = i || m, b = s || w, P = y.useRef(null), A = y.useRef(null), C = y.useRef(f), T = a != null, L = Kt(a), M = Kt(r), S = y.useCallback(() => {
    if (!P.current || !A.current)
      return;
    const R = {
      placement: e,
      strategy: n,
      middleware: p
    };
    M.current && (R.platform = M.current), Nn(P.current, A.current, R).then((F) => {
      const z = {
        ...F,
        isPositioned: !0
      };
      k.current && !pt(C.current, z) && (C.current = z, Me.flushSync(() => {
        d(z);
      }));
    });
  }, [p, e, n, M]);
  at(() => {
    l === !1 && C.current.isPositioned && (C.current.isPositioned = !1, d((R) => ({
      ...R,
      isPositioned: !1
    })));
  }, [l]);
  const k = y.useRef(!1);
  at(() => (k.current = !0, () => {
    k.current = !1;
  }), []), at(() => {
    if (v && (P.current = v), b && (A.current = b), v && b) {
      if (L.current)
        return L.current(v, b, S);
      S();
    }
  }, [v, b, S, L, T]);
  const D = y.useMemo(() => ({
    reference: P,
    floating: A,
    setReference: x,
    setFloating: E
  }), [x, E]), O = y.useMemo(() => ({
    reference: v,
    floating: b
  }), [v, b]), N = y.useMemo(() => {
    const R = {
      position: n,
      left: 0,
      top: 0
    };
    if (!O.floating)
      return R;
    const F = qt(O.floating, f.x), z = qt(O.floating, f.y);
    return c ? {
      ...R,
      transform: "translate(" + F + "px, " + z + "px)",
      ...se(O.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: F,
      top: z
    };
  }, [n, c, O.floating, f.x, f.y]);
  return y.useMemo(() => ({
    ...f,
    update: S,
    refs: D,
    elements: O,
    floatingStyles: N
  }), [f, S, D, O, N]);
}
const Fn = (t) => {
  function e(n) {
    return {}.hasOwnProperty.call(n, "current");
  }
  return {
    name: "arrow",
    options: t,
    fn(n) {
      const {
        element: o,
        padding: r
      } = typeof t == "function" ? t(n) : t;
      return o && e(o) ? o.current != null ? Ut({
        element: o.current,
        padding: r
      }).fn(n) : {} : o ? Ut({
        element: o,
        padding: r
      }).fn(n) : {};
    }
  };
}, Wn = (t, e) => ({
  ...On(t),
  options: [t, e]
}), $n = (t, e) => ({
  ...Sn(t),
  options: [t, e]
}), Bn = (t, e) => ({
  ...Mn(t),
  options: [t, e]
}), Hn = (t, e) => ({
  ...Ln(t),
  options: [t, e]
}), _n = (t, e) => ({
  ...Dn(t),
  options: [t, e]
}), In = (t, e) => ({
  ...Tn(t),
  options: [t, e]
}), Vn = (t, e) => ({
  ...Fn(t),
  options: [t, e]
});
var zn = "Arrow", ce = y.forwardRef((t, e) => {
  const { children: n, width: o = 10, height: r = 5, ...i } = t;
  return /* @__PURE__ */ $(
    Z.svg,
    {
      ...i,
      ref: e,
      width: o,
      height: r,
      viewBox: "0 0 30 10",
      preserveAspectRatio: "none",
      children: t.asChild ? n : /* @__PURE__ */ $("polygon", { points: "0,0 30,0 15,10" })
    }
  );
});
ce.displayName = zn;
var Yn = ce, Dt = "Popper", [le, lo] = Te(Dt), [Xn, ae] = le(Dt), fe = (t) => {
  const { __scopePopper: e, children: n } = t, [o, r] = y.useState(null);
  return /* @__PURE__ */ $(Xn, { scope: e, anchor: o, onAnchorChange: r, children: n });
};
fe.displayName = Dt;
var ue = "PopperAnchor", de = y.forwardRef(
  (t, e) => {
    const { __scopePopper: n, virtualRef: o, ...r } = t, i = ae(ue, n), s = y.useRef(null), c = mt(e, s);
    return y.useEffect(() => {
      i.onAnchorChange((o == null ? void 0 : o.current) || s.current);
    }), o ? null : /* @__PURE__ */ $(Z.div, { ...r, ref: c });
  }
);
de.displayName = ue;
var Tt = "PopperContent", [jn, Un] = le(Tt), pe = y.forwardRef(
  (t, e) => {
    var Mt, Nt, kt, Ft, Wt, $t;
    const {
      __scopePopper: n,
      side: o = "bottom",
      sideOffset: r = 0,
      align: i = "center",
      alignOffset: s = 0,
      arrowPadding: c = 0,
      avoidCollisions: a = !0,
      collisionBoundary: l = [],
      collisionPadding: f = 0,
      sticky: d = "partial",
      hideWhenDetached: p = !1,
      updatePositionStrategy: u = "optimized",
      onPlaced: m,
      ...h
    } = t, w = ae(Tt, n), [g, x] = y.useState(null), E = mt(e, (nt) => x(nt)), [v, b] = y.useState(null), P = ke(v), A = (P == null ? void 0 : P.width) ?? 0, C = (P == null ? void 0 : P.height) ?? 0, T = o + (i !== "center" ? "-" + i : ""), L = typeof f == "number" ? f : { top: 0, right: 0, bottom: 0, left: 0, ...f }, M = Array.isArray(l) ? l : [l], S = M.length > 0, k = {
      padding: L,
      boundary: M.filter(Kn),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: S
    }, { refs: D, floatingStyles: O, placement: N, isPositioned: R, middlewareData: F } = kn({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: "fixed",
      placement: T,
      whileElementsMounted: (...nt) => Cn(...nt, {
        animationFrame: u === "always"
      }),
      elements: {
        reference: w.anchor
      },
      middleware: [
        Wn({ mainAxis: r + C, alignmentAxis: s }),
        a && $n({
          mainAxis: !0,
          crossAxis: !1,
          limiter: d === "partial" ? Bn() : void 0,
          ...k
        }),
        a && Hn({ ...k }),
        _n({
          ...k,
          apply: ({ elements: nt, rects: Bt, availableWidth: Pe, availableHeight: Re }) => {
            const { width: Ce, height: Oe } = Bt.reference, ct = nt.floating.style;
            ct.setProperty("--radix-popper-available-width", `${Pe}px`), ct.setProperty("--radix-popper-available-height", `${Re}px`), ct.setProperty("--radix-popper-anchor-width", `${Ce}px`), ct.setProperty("--radix-popper-anchor-height", `${Oe}px`);
          }
        }),
        v && Vn({ element: v, padding: c }),
        Zn({ arrowWidth: A, arrowHeight: C }),
        p && In({ strategy: "referenceHidden", ...k })
      ]
    }), [z, ye] = ge(N), st = ht(m);
    xt(() => {
      R && (st == null || st());
    }, [R, st]);
    const xe = (Mt = F.arrow) == null ? void 0 : Mt.x, ve = (Nt = F.arrow) == null ? void 0 : Nt.y, be = ((kt = F.arrow) == null ? void 0 : kt.centerOffset) !== 0, [Ae, Ee] = y.useState();
    return xt(() => {
      g && Ee(window.getComputedStyle(g).zIndex);
    }, [g]), /* @__PURE__ */ $(
      "div",
      {
        ref: D.setFloating,
        "data-radix-popper-content-wrapper": "",
        style: {
          ...O,
          transform: R ? O.transform : "translate(0, -200%)",
          // keep off the page when measuring
          minWidth: "max-content",
          zIndex: Ae,
          "--radix-popper-transform-origin": [
            (Ft = F.transformOrigin) == null ? void 0 : Ft.x,
            (Wt = F.transformOrigin) == null ? void 0 : Wt.y
          ].join(" "),
          // hide the content if using the hide middleware and should be hidden
          // set visibility to hidden and disable pointer events so the UI behaves
          // as if the PopperContent isn't there at all
          ...(($t = F.hide) == null ? void 0 : $t.referenceHidden) && {
            visibility: "hidden",
            pointerEvents: "none"
          }
        },
        dir: t.dir,
        children: /* @__PURE__ */ $(
          jn,
          {
            scope: n,
            placedSide: z,
            onArrowChange: b,
            arrowX: xe,
            arrowY: ve,
            shouldHideArrow: be,
            children: /* @__PURE__ */ $(
              Z.div,
              {
                "data-side": z,
                "data-align": ye,
                ...h,
                ref: E,
                style: {
                  ...h.style,
                  // if the PopperContent hasn't been placed yet (not all measurements done)
                  // we prevent animations so that users's animation don't kick in too early referring wrong sides
                  animation: R ? void 0 : "none"
                }
              }
            )
          }
        )
      }
    );
  }
);
pe.displayName = Tt;
var me = "PopperArrow", qn = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, he = y.forwardRef(function(e, n) {
  const { __scopePopper: o, ...r } = e, i = Un(me, o), s = qn[i.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ $(
      "span",
      {
        ref: i.onArrowChange,
        style: {
          position: "absolute",
          left: i.arrowX,
          top: i.arrowY,
          [s]: 0,
          transformOrigin: {
            top: "",
            right: "0 0",
            bottom: "center 0",
            left: "100% 0"
          }[i.placedSide],
          transform: {
            top: "translateY(100%)",
            right: "translateY(50%) rotate(90deg) translateX(-50%)",
            bottom: "rotate(180deg)",
            left: "translateY(50%) rotate(-90deg) translateX(50%)"
          }[i.placedSide],
          visibility: i.shouldHideArrow ? "hidden" : void 0
        },
        children: /* @__PURE__ */ $(
          Yn,
          {
            ...r,
            ref: n,
            style: {
              ...r.style,
              // ensures the element can be measured correctly (mostly for if SVG)
              display: "block"
            }
          }
        )
      }
    )
  );
});
he.displayName = me;
function Kn(t) {
  return t !== null;
}
var Zn = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(e) {
    var w, g, x;
    const { placement: n, rects: o, middlewareData: r } = e, s = ((w = r.arrow) == null ? void 0 : w.centerOffset) !== 0, c = s ? 0 : t.arrowWidth, a = s ? 0 : t.arrowHeight, [l, f] = ge(n), d = { start: "0%", center: "50%", end: "100%" }[f], p = (((g = r.arrow) == null ? void 0 : g.x) ?? 0) + c / 2, u = (((x = r.arrow) == null ? void 0 : x.y) ?? 0) + a / 2;
    let m = "", h = "";
    return l === "bottom" ? (m = s ? d : `${p}px`, h = `${-a}px`) : l === "top" ? (m = s ? d : `${p}px`, h = `${o.floating.height + a}px`) : l === "right" ? (m = `${-a}px`, h = s ? d : `${u}px`) : l === "left" && (m = `${o.floating.width + a}px`, h = s ? d : `${u}px`), { data: { x: m, y: h } };
  }
});
function ge(t) {
  const [e, n = "center"] = t.split("-");
  return [e, n];
}
var ao = fe, fo = de, uo = pe, po = he, Gn = "Portal", Jn = y.forwardRef((t, e) => {
  var c;
  const { container: n, ...o } = t, [r, i] = y.useState(!1);
  xt(() => i(!0), []);
  const s = n || r && ((c = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : c.body);
  return s ? Ne.createPortal(/* @__PURE__ */ $(Z.div, { ...o, ref: e }), s) : null;
});
Jn.displayName = Gn;
var Qn = "VisuallyHidden", we = y.forwardRef(
  (t, e) => /* @__PURE__ */ $(
    Z.span,
    {
      ...t,
      ref: e,
      style: {
        // See: https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
        position: "absolute",
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        ...t.style
      }
    }
  )
);
we.displayName = Qn;
var mo = we;
export {
  fo as A,
  uo as C,
  He as D,
  Jn as P,
  mo as R,
  we as V,
  po as a,
  ao as b,
  lo as c
};
