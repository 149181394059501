// keep common family flow parts in this file

import type { FunnelData } from "../config.types";

export const isFamilyDirectToLssFlow = ({
  answers,
  experiments,
}: Omit<FunnelData, "meta">): boolean => {
  const { leadIntent, subPractice } = answers || {};
  const { abTestHighIntent, abTestLowIntent, abTestDivorceFlowEnabled } =
    experiments || {};

  return (
    (abTestHighIntent && leadIntent === "high") ||
    (abTestLowIntent && leadIntent === "low") ||
    (abTestDivorceFlowEnabled && subPractice === "divorce")
  );
};
