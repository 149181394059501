import React from "react";
import { Builder, builder, withChildren } from "@builder.io/react";
import { IconButton as CoreIconButton } from "@marbletech/core";

import { analytics } from "@flare/analytics";
import { Icon } from "@flare/ui";

import { Link } from "@/components/Link";
import type { BiEventsNamesType } from "@/services/analytics/analytics.types";
import { BiEventsNames } from "@/services/analytics/event-names";

import { navigationComponentsNames } from "../consts";

import { IconButtonBuilderProps } from "./IconButton.builder.props";
import type { IconButtonProps } from "./IconButton.types";

export function IconButton({
  children,
  event,
  href,
  pathname,
  onClick,
  ...rest
}: IconButtonProps) {
  const handleClick = (e: React.SyntheticEvent) => {
    if (event) {
      analytics.track(BiEventsNames[event?.name as BiEventsNamesType], {
        ...event?.eventData,
        click_url: href || pathname,
      });
      builder.track(event.name);
    }
    onClick?.(e);
  };

  const button = (
    <CoreIconButton onClick={handleClick} {...rest}>
      {children ?? <Icon name="chevron-left" />}
    </CoreIconButton>
  );
  return href || pathname ? (
    <Link href={href} pathname={pathname}>
      {button}
    </Link>
  ) : (
    button
  );
}

const IconButtonWithChildren = withChildren(IconButton);

Builder.registerComponent(IconButtonWithChildren, {
  name: navigationComponentsNames.ICON_BUTTON,
  inputs: IconButtonBuilderProps,
});
