import * as r from "react";
import { jsx as l } from "react/jsx-runtime";
function $(e, t = []) {
  let s = [];
  function i(f, n) {
    const o = r.createContext(n), c = s.length;
    s = [...s, n];
    const p = (x) => {
      var v;
      const { scope: u, children: d, ...m } = x, C = ((v = u == null ? void 0 : u[e]) == null ? void 0 : v[c]) || o, h = r.useMemo(() => m, Object.values(m));
      return /* @__PURE__ */ l(C.Provider, { value: h, children: d });
    };
    p.displayName = f + "Provider";
    function S(x, u) {
      var C;
      const d = ((C = u == null ? void 0 : u[e]) == null ? void 0 : C[c]) || o, m = r.useContext(d);
      if (m) return m;
      if (n !== void 0) return n;
      throw new Error(`\`${x}\` must be used within \`${f}\``);
    }
    return [p, S];
  }
  const a = () => {
    const f = s.map((n) => r.createContext(n));
    return function(o) {
      const c = (o == null ? void 0 : o[e]) || f;
      return r.useMemo(
        () => ({ [`__scope${e}`]: { ...o, [e]: c } }),
        [o, c]
      );
    };
  };
  return a.scopeName = e, [i, _(a, ...t)];
}
function _(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const s = () => {
    const i = e.map((a) => ({
      useScope: a(),
      scopeName: a.scopeName
    }));
    return function(f) {
      const n = i.reduce((o, { useScope: c, scopeName: p }) => {
        const x = c(f)[`__scope${p}`];
        return { ...o, ...x };
      }, {});
      return r.useMemo(() => ({ [`__scope${t.scopeName}`]: n }), [n]);
    };
  };
  return s.scopeName = t.scopeName, s;
}
function E(e) {
  const t = r.useRef(e);
  return r.useEffect(() => {
    t.current = e;
  }), r.useMemo(() => (...s) => {
    var i;
    return (i = t.current) == null ? void 0 : i.call(t, ...s);
  }, []);
}
var M = globalThis != null && globalThis.document ? r.useLayoutEffect : () => {
};
export {
  M as a,
  $ as c,
  E as u
};
