import { jsx as c, jsxs as b } from "react/jsx-runtime";
import * as l from "react";
import { forwardRef as w } from "react";
import { c as N, u as C, a as v } from "../../index-CwaZVody.js";
import { P as f } from "../../index-C8BDXGwJ.js";
import { c as I } from "../../index-DFo-3WCJ.js";
import { c as R } from "../../index-Bm_zk_a7.js";
var u = "Avatar", [h, $] = N(u), [_, g] = h(u), p = l.forwardRef(
  (t, r) => {
    const { __scopeAvatar: n, ...a } = t, [o, e] = l.useState("idle");
    return /* @__PURE__ */ c(
      _,
      {
        scope: n,
        imageLoadingStatus: o,
        onImageLoadingStatusChange: e,
        children: /* @__PURE__ */ c(f.span, { ...a, ref: r })
      }
    );
  }
);
p.displayName = u;
var x = "AvatarImage", A = l.forwardRef(
  (t, r) => {
    const { __scopeAvatar: n, src: a, onLoadingStatusChange: o = () => {
    }, ...e } = t, i = g(x, n), s = z(a, e.referrerPolicy), d = C((m) => {
      o(m), i.onImageLoadingStatusChange(m);
    });
    return v(() => {
      s !== "idle" && d(s);
    }, [s, d]), s === "loaded" ? /* @__PURE__ */ c(f.img, { ...e, ref: r, src: a }) : null;
  }
);
A.displayName = x;
var S = "AvatarFallback", y = l.forwardRef(
  (t, r) => {
    const { __scopeAvatar: n, delayMs: a, ...o } = t, e = g(S, n), [i, s] = l.useState(a === void 0);
    return l.useEffect(() => {
      if (a !== void 0) {
        const d = window.setTimeout(() => s(!0), a);
        return () => window.clearTimeout(d);
      }
    }, [a]), i && e.imageLoadingStatus !== "loaded" ? /* @__PURE__ */ c(f.span, { ...o, ref: r }) : null;
  }
);
y.displayName = S;
function z(t, r) {
  const [n, a] = l.useState("idle");
  return v(() => {
    if (!t) {
      a("error");
      return;
    }
    let o = !0;
    const e = new window.Image(), i = (s) => () => {
      o && a(s);
    };
    return a("loading"), e.onload = i("loaded"), e.onerror = i("error"), e.src = t, r && (e.referrerPolicy = r), () => {
      o = !1;
    };
  }, [t, r]), n;
}
var k = p, E = A, M = y;
const P = I(
  "inline-flex items-center justify-center overflow-hidden rounded-full align-middle",
  {
    variants: {
      variant: {
        filled: "text-text-primary-inverted",
        stroke: "border border-el-stroke-primary text-text-primary"
      },
      color: {
        primary: "",
        revealed: ""
      },
      size: {
        sm: "size-s-4 text-[12px]",
        md: "size-s-5 text-[18px]",
        lg: "size-s-6 text-[16px]",
        xl: "size-s-8 text-[22px]",
        "2xl": "size-s-9 text-[28px]",
        "3xl": "size-s-10 text-[40px]"
      }
    },
    compoundVariants: [
      {
        variant: "filled",
        color: "primary",
        className: "bg-el-bg-avatar-primary"
      },
      {
        variant: "filled",
        color: "revealed",
        className: "bg-el-bg-avatar-revealed"
      }
    ]
  }
), j = w(
  ({
    className: t,
    src: r,
    alt: n,
    onLoadingStatusChange: a,
    fallback: o,
    delayMs: e,
    size: i = "lg",
    variant: s = "filled",
    color: d = "primary",
    ...m
  }, L) => /* @__PURE__ */ b(
    k,
    {
      ref: L,
      className: R(P({ size: i, variant: s, color: d, className: t })),
      ...m,
      children: [
        /* @__PURE__ */ c(
          E,
          {
            className: "size-full object-cover",
            src: r,
            alt: n,
            onLoadingStatusChange: a
          }
        ),
        /* @__PURE__ */ c(
          M,
          {
            className: "mt-[7%] flex select-none items-center justify-center font-serif font-medium leading-none",
            delayMs: e,
            children: o
          }
        )
      ]
    }
  )
);
j.displayName = "Avatar";
export {
  j as Avatar
};
