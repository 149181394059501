import { createGlobalStyle } from "styled-components";

import { deprecatedColors } from "@/styles/deprecated-colors";

export const desktopNavbarStyles = {
  display: "flex",
  justifyContent: "center",
  lineHeight: "inherit",
  alignItems: "center",
  height: "100%",
  backgroundColor: "transparent",
  border: "none",
};

export const GlobalStyles = createGlobalStyle`
&& {
  .ant-menu-submenu-vertical {
    width: 362px;
  }
  
  .ant-menu-vertical {
    width: 362px;
    box-shadow: '0px 4px 16px 0px rgba(75, 63, 45, 0.20)';
    font-feature-settings: 'liga' off, 'ss02';
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border: solid ${deprecatedColors.b20};
    border-bottom-width: 0px;
  }
  
  .ant-menu-submenu .ant-menu-submenu-vertical {
    border-bottom: solid 1px ${deprecatedColors.b20};
    border-radius: 4px;
  }

  .ant-menu-submenu >.ant-menu .ant-menu-item:last-child, .ant-menu-submenu .ant-menu-submenu-vertical:last-child {
    border-bottom: none;
  }

  .ant-menu-submenu-popup .ant-menu-vertical .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-menu-overflow-item{
    padding-inline: 20px;
  }

  .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after, 
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after, 
  .ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after,
  .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu::after, 
  .ant-menu-light.ant-menu-horizontal >.ant-menu-item::after {
    border: none;
  }
  
  .ant-menu-title-content {
      color: currentColor;
  }

  .ant-menu-submenu-arrow {
      color: ${deprecatedColors.primary} !important;
  }
  
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
    color: ${deprecatedColors.b100};
  }
  
  .ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-116eih2).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, :where(.css-116eih2).ant-menu-light.ant-menu-root.ant-menu-vertical, :where(.css-116eih2).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical{
    background-color:  ${deprecatedColors.w80};
    font-feature-settings: 'liga' off, 'ss02';
  }
  .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
    background-color: ${deprecatedColors.w80};
  }


}
`;
