import React from "react";
import { Builder, BuilderBlocks, withChildren } from "@builder.io/react";
import * as Dialog from "@radix-ui/react-dialog";

import { analytics } from "@flare/analytics";
import { Icon } from "@flare/ui";

import { BiEventsNames } from "@/services/analytics/event-names";

import { interactiveElementsComponentsNames } from "../consts";

import {
  GlobalStyle,
  StyledDialogBuilderBlocks,
  StyledDialogContent,
  StyledDialogOverlay,
} from "./VideoDialog.styled";
import type { VideoDialogProps } from "./VideoDialog.types";

export function VideoDialog({
  builderBlock,
  trigger,
  dialogContent,
  preventModalFromOpening,
  event,
}: VideoDialogProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const hasTriggerElement = Array.isArray(trigger) && trigger[0];

  function handleClick() {
    analytics.track(BiEventsNames.WebPopupClick, {
      location: event?.eventData?.location,
      element_name: "Video",
      click_type: "interact",
    });

    // When editing the component on Builder we need a way to prevent the dialog from opening
    // when the trigger element isn't defined yet
    if (Builder.isEditing && hasTriggerElement && !preventModalFromOpening) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  }

  function handleClose() {
    analytics.track(BiEventsNames.WebPopupClick, {
      location: "popup",
      element_name: "Video",
      click_type: "close",
    });

    setIsOpen(false);
  }

  return (
    <>
      <GlobalStyle />
      <Dialog.Root onOpenChange={setIsOpen} open={isOpen}>
        <Dialog.Trigger onClick={handleClick}>
          <div>
            <BuilderBlocks
              parentElementId={builderBlock.id}
              dataPath="component.options.trigger"
              blocks={trigger}
            />
          </div>
        </Dialog.Trigger>
        <Dialog.Portal>
          <StyledDialogOverlay />
          <StyledDialogContent>
            <Dialog.Close onClick={handleClose}>
              <Icon
                name="xmark"
                className="absolute right-3 top-3 z-[3] cursor-pointer fill-white-80"
              />
            </Dialog.Close>
            <StyledDialogBuilderBlocks
              parentElementId={builderBlock.id}
              dataPath="component.options.dialogContent"
              blocks={dialogContent}
            />
          </StyledDialogContent>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}

const VideoDialogWithChildren = withChildren(VideoDialog);

Builder.registerComponent(VideoDialogWithChildren, {
  name: interactiveElementsComponentsNames.VIDEO_DIALOGUE,
  inputs: [
    {
      name: "trigger",
      type: "uiBlocks",
      defaultValue: [],
    },
    {
      name: "dialogContent",
      type: "uiBlocks",
      defaultValue: [],
    },
    {
      name: "preventModalFromOpening",
      type: "boolean",
      defaultValue: false,
      helperText: "Disable modal opening on click (for editing only)",
    },
    {
      name: "event",
      type: "object",
      subFields: [
        {
          friendlyName: "Data",
          name: "eventData",
          type: "object",
          subFields: [
            {
              friendlyName: "Location in the page, e.g. hero, fold_1, etc.",
              name: "location",
              type: "string",
            },
          ],
        },
      ],
    },
  ],
});
