import { jsxs as H, jsx as n } from "react/jsx-runtime";
import * as r from "react";
import { u as I, c as M } from "../../index-47DXPxf5.js";
import { u as j } from "../../index-DoOsnuB6.js";
import { c as z } from "../../index-CwaZVody.js";
import { u as B } from "../../index-BZPx6jYI.js";
import { u as q } from "../../index-CI9iLup9.js";
import { P as S } from "../../index-C8BDXGwJ.js";
import { c as g } from "../../index-Bm_zk_a7.js";
import { focusRing as A } from "../../utils/focus-ring.js";
var w = "Switch", [O, ee] = z(w), [D, F] = O(w), C = r.forwardRef(
  (e, o) => {
    const {
      __scopeSwitch: t,
      name: c,
      checked: s,
      defaultChecked: u,
      required: i,
      disabled: a,
      value: d = "on",
      onCheckedChange: b,
      form: m,
      ...p
    } = e, [l, E] = r.useState(null), N = j(o, (f) => E(f)), v = r.useRef(!1), k = l ? m || !!l.closest("form") : !0, [h = !1, T] = I({
      prop: s,
      defaultProp: u,
      onChange: b
    });
    return /* @__PURE__ */ H(D, { scope: t, checked: h, disabled: a, children: [
      /* @__PURE__ */ n(
        S.button,
        {
          type: "button",
          role: "switch",
          "aria-checked": h,
          "aria-required": i,
          "data-state": y(h),
          "data-disabled": a ? "" : void 0,
          disabled: a,
          value: d,
          ...p,
          ref: N,
          onClick: M(e.onClick, (f) => {
            T((_) => !_), k && (v.current = f.isPropagationStopped(), v.current || f.stopPropagation());
          })
        }
      ),
      k && /* @__PURE__ */ n(
        L,
        {
          control: l,
          bubbles: !v.current,
          name: c,
          value: d,
          checked: h,
          required: i,
          disabled: a,
          form: m,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
C.displayName = w;
var P = "SwitchThumb", x = r.forwardRef(
  (e, o) => {
    const { __scopeSwitch: t, ...c } = e, s = F(P, t);
    return /* @__PURE__ */ n(
      S.span,
      {
        "data-state": y(s.checked),
        "data-disabled": s.disabled ? "" : void 0,
        ...c,
        ref: o
      }
    );
  }
);
x.displayName = P;
var L = (e) => {
  const { control: o, checked: t, bubbles: c = !0, ...s } = e, u = r.useRef(null), i = B(t), a = q(o);
  return r.useEffect(() => {
    const d = u.current, b = window.HTMLInputElement.prototype, p = Object.getOwnPropertyDescriptor(b, "checked").set;
    if (i !== t && p) {
      const l = new Event("click", { bubbles: c });
      p.call(d, t), d.dispatchEvent(l);
    }
  }, [i, t, c]), /* @__PURE__ */ n(
    "input",
    {
      type: "checkbox",
      "aria-hidden": !0,
      defaultChecked: t,
      ...s,
      tabIndex: -1,
      ref: u,
      style: {
        ...e.style,
        ...a,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function y(e) {
  return e ? "checked" : "unchecked";
}
var R = C, U = x;
const W = r.forwardRef(({ className: e, ...o }, t) => /* @__PURE__ */ n(
  R,
  {
    className: g(
      A,
      // Base
      "inline-flex h-s-3 w-[calc(var(--fui-baseline)+var(--fui-subline)*3)] shrink-0 cursor-pointer items-center rounded-full border-[calc(var(--fui-subline)/2)] border-transparent bg-[--color-switch-bg-off]",
      // Checked
      "data-[state=checked]:bg-[--color-switch-bg-on] data-[state=checked]:hover:bg-[--color-switch-bg-on-hover] disabled:data-[state=checked]:bg-[--color-switch-bg-on-disabled]",
      // Hover
      "hover:bg-[--color-switch-bg-off-hover]",
      // Disabled
      "disabled:pointer-events-none disabled:bg-[--color-switch-bg-off-disabled]",
      e
    ),
    ...o,
    ref: t,
    children: /* @__PURE__ */ n(
      U,
      {
        className: g(
          "pointer-events-none block size-[calc(var(--fui-subline)*4)] rounded-full bg-[--color-switch-content] transition-transform data-[state=checked]:translate-x-sp-4 data-[state=unchecked]:translate-x-[0]"
        )
      }
    )
  }
));
W.displayName = R.displayName;
export {
  W as Switch
};
