import { BuilderBlocks } from "@builder.io/react";
import * as Dialog from "@radix-ui/react-dialog";
import styled, { createGlobalStyle, keyframes } from "styled-components";

import { deprecatedColors } from "@/styles/deprecated-colors";

const overlayShowAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const contentShowAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  `;

export const StyledTriggerPlaceholder = styled.div`
  padding: 12px 24px;
  background-color: #eee;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModalPlaceholder = styled.div`
  padding: 25px;
`;

export const StyledDialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0;
  animation: ${overlayShowAnimation} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const StyledDialogContent = styled(Dialog.Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  color: ${deprecatedColors.w80};
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  width: 100%;
  min-width: 200px;
  height: 100%;
  animation: ${contentShowAnimation} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1000;

  &:focus {
    outline: none;
  }
`;

export const StyledDialogBuilderBlocks = styled(BuilderBlocks)`
  width: 100%;

  & div {
    max-height: 100vh;
  }
`;

export const GlobalStyle = createGlobalStyle`
/* reset */
button,
fieldset,
input {
  all: unset;
}
`;
