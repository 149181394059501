import { jsx as a } from "react/jsx-runtime";
import { forwardRef as d } from "react";
import { c as l } from "../../index-Bm_zk_a7.js";
const i = d(
  ({ autoSize: e, className: o, hasError: r, ...t }, n) => /* @__PURE__ */ a(
    "textarea",
    {
      className: l(
        // Base
        "max-h-[calc(var(--fui-s-9)*4)] min-h-s-9 w-full resize-y appearance-none rounded-sm border px-sp-4 py-sp-2 text-body1 text-[--color-input-content] outline-none transition placeholder:text-[--color-input-placeholder]",
        // Border colors
        "border-[--color-input-stroke] hover:border-[--color-input-stroke-hover] focus:border-[--color-input-stroke-active]",
        // Disabled
        "disabled:border-[--color-input-stroke-disabled] disabled:text-[--color-input-content-disabled] placeholder:disabled:text-[--color-input-placeholder-disabled]",
        // Error
        {
          "border-[--color-input-stroke-danger] hover:border-[--color-input-stroke-danger] focus:border-[--color-input-stroke-danger]": r
        },
        // Auto size
        {
          "[field-sizing:content]": e
        },
        o
      ),
      ref: n,
      ...t
    }
  )
);
i.displayName = "Textarea";
export {
  i as Textarea
};
