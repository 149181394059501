import { jsx as p } from "react/jsx-runtime";
import * as r from "react";
function u(e, n) {
  typeof e == "function" ? e(n) : e != null && (e.current = n);
}
function a(...e) {
  return (n) => e.forEach((o) => u(o, n));
}
const f = r.forwardRef((e, n) => {
  const { children: o, ...t } = e;
  if (m(o)) {
    const l = o;
    return /* @__PURE__ */ p(i, { ...t, ref: n, children: r.isValidElement(
      l.props.child
    ) ? r.cloneElement(
      l.props.child,
      void 0,
      l.props.children(l.props.child.props.children)
    ) : null });
  }
  return /* @__PURE__ */ p(i, { ...t, ref: n, children: o });
});
f.displayName = "Slot";
const i = r.forwardRef(
  (e, n) => {
    const { children: o, ...t } = e;
    return r.isValidElement(o) ? r.cloneElement(o, {
      ...h(t, o.props),
      ref: n ? a(n, o.ref) : o.ref
    }) : r.Children.count(o) > 1 ? r.Children.only(null) : null;
  }
);
i.displayName = "SlotClone";
const d = ({ child: e, children: n }) => n(e);
function m(e) {
  return r.isValidElement(e) && e.type === d;
}
function h(e, n) {
  const o = { ...n };
  for (const t in n) {
    const l = e[t], s = n[t];
    /^on[A-Z]/.test(t) ? l && s ? o[t] = (...c) => {
      s(...c), l(...c);
    } : l && (o[t] = l) : t === "style" ? o[t] = { ...l, ...s } : t === "className" && (o[t] = [l, s].filter(Boolean).join(" "));
  }
  return { ...e, ...o };
}
const S = f;
export {
  S as Root,
  f as Slot,
  d as Slottable
};
