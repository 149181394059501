import { analytics } from "@flare/analytics";
import type { User } from "@flare/next-auth";

import { meetingType } from "@/components/Funnel/funnel.consts";
import type { FunnelMeta, FunnelModels } from "@/modules/v2/funnel/config";
import type { SessionContext } from "@/types/next-auth";

import { BiEventsNames } from "./event-names";

export function reportWebCalendarSuccess(
  user: User,
  context: SessionContext,
  funnelMeta: FunnelMeta,
) {
  const selectedMeetingType =
    context.funnelData?.eventStartTime === "call_me_now"
      ? meetingType.cmn
      : meetingType.intake;

  analytics.track(BiEventsNames.WebCalendarSuccess, {
    slot: context.funnelData?.eventStartTime,
    email_hash: funnelMeta?.hashedEmail,
    phone_hash: funnelMeta?.hashedPhone,
    email_hash_unsalted: funnelMeta?.unsaltedSanitizedEmail,
    phone_hash_unsalted: funnelMeta?.unsaltedSanitizedPhone,
    meeting_type: selectedMeetingType,
    sf_funnel_action_id: user.integrationInfo?.sfLastWFAId,
  });
}
export function reportWebLeadCreate(
  user: User,
  context: SessionContext,
  funnelMeta: FunnelMeta,
) {
  const modelsMap = funnelMeta?.models || ({} as FunnelModels);
  const models = Object.values(modelsMap);

  analytics.track(BiEventsNames.WebLeadCreate, {
    practice: context.funnelData?.practice,
    email_hash: funnelMeta?.hashedEmail,
    phone_hash: funnelMeta?.hashedPhone,
    email_hash_unsalted: funnelMeta?.unsaltedSanitizedEmail,
    phone_hash_unsalted: funnelMeta?.unsaltedSanitizedPhone,
    sf_funnel_action_id: user.integrationInfo?.sfLastWFAId,
    models,
  });
}

export async function getAnonymousId() {
  return await analytics.getAnonymousId();
}
