import { jsx as e } from "react/jsx-runtime";
import { S as m } from "../../index-DoOsnuB6.js";
import { c as d } from "../../index-DFo-3WCJ.js";
import { c as h } from "../../index-Bm_zk_a7.js";
const y = d("font-sans antialiased", {
  variants: {
    alignment: {
      start: "text-start",
      center: "text-center",
      end: "text-end"
    },
    color: {
      primary: "text-text-primary",
      secondary: "text-text-secondary",
      "primary-inverted": "text-text-primary-inverted",
      danger: "text-text-danger",
      success: "text-text-success"
    },
    maxLines: {
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
      5: "line-clamp-5",
      6: "line-clamp-6"
    },
    textWrap: {
      balance: "text-balance",
      pretty: "text-pretty"
    },
    underline: {
      true: "underline"
    },
    variant: {
      body1: "text-body1",
      "body1-highlight": "text-body1-highlight",
      body2: "text-body2",
      "body2-highlight": "text-body2-highlight",
      caption: "text-caption",
      "caption-highlight": "text-caption-highlight"
    }
  }
}), v = ({
  alignment: a = "start",
  as: i = "p",
  asChild: r,
  children: t,
  className: n,
  color: o = "primary",
  maxLines: c,
  textWrap: l,
  underline: x,
  variant: s = "body1",
  ...p
}) => /* @__PURE__ */ e(
  m,
  {
    className: h(
      y({
        alignment: a,
        color: o,
        maxLines: c,
        textWrap: l,
        underline: x,
        variant: s,
        className: n
      })
    ),
    ...p,
    children: r ? t : /* @__PURE__ */ e(i, { children: t })
  }
);
export {
  v as Text
};
