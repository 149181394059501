import { jsx as e } from "react/jsx-runtime";
import { S as l } from "../../index-DoOsnuB6.js";
import { c as p } from "../../index-DFo-3WCJ.js";
import { c as h } from "../../index-Bm_zk_a7.js";
const d = p("font-serif antialiased", {
  variants: {
    alignment: {
      start: "text-start",
      center: "text-center",
      end: "text-end"
    },
    color: {
      primary: "text-text-primary",
      secondary: "text-text-secondary",
      "primary-inverted": "text-text-primary-inverted",
      danger: "text-text-danger",
      success: "text-text-success"
    },
    maxLines: {
      1: "line-clamp-1",
      2: "line-clamp-2",
      3: "line-clamp-3",
      4: "line-clamp-4",
      5: "line-clamp-5",
      6: "line-clamp-6"
    },
    textWrap: {
      balance: "text-balance",
      pretty: "text-pretty"
    },
    variant: {
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
      h4: "font-sans text-h4",
      h5: "font-sans text-h5",
      h6: "font-sans text-h6"
    }
  }
}), u = ({
  alignment: a = "start",
  as: r,
  children: n,
  className: s,
  color: i = "primary",
  textWrap: c,
  maxLines: x,
  variant: t = "h1",
  ...m
}) => {
  const o = r ?? t ?? "h1";
  return /* @__PURE__ */ e(
    l,
    {
      className: h(
        d({
          alignment: a,
          color: i,
          maxLines: x,
          textWrap: c,
          variant: t,
          className: s
        })
      ),
      ...m,
      children: /* @__PURE__ */ e(o, { children: n })
    }
  );
};
export {
  u as Heading
};
