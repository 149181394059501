import React from "react";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";

import { Icon } from "@flare/ui";

import { deprecatedColors } from "@/styles/deprecated-colors";

import {
  CollapseContentWrapper,
  FaqContainer,
  TitleLineLayout,
} from "./Faq.styled";
import type { FaqProps, TitleProps } from "./Faq.types";

const Title = ({
  children,
  isOpen,
  iconColor,
}: React.PropsWithChildren<TitleProps>) => (
  <CollapsiblePrimitive.Trigger asChild>
    <TitleLineLayout>
      {children}
      <div className="ml-8 flex shrink-0 items-center justify-center">
        {isOpen ? (
          <Icon name="minus" size="lg" color={deprecatedColors[iconColor!]} />
        ) : (
          <Icon name="plus" size="lg" color={deprecatedColors[iconColor!]} />
        )}
      </div>
    </TitleLineLayout>
  </CollapsiblePrimitive.Trigger>
);

const Content = ({ children }: React.PropsWithChildren) => (
  <CollapseContentWrapper>{children}</CollapseContentWrapper>
);

export const Faq = ({
  children,
  question,
  onItemClicked = () => null,
  iconColor = "primary",
  borderColor = "primary",
  isOpen,
}: React.PropsWithChildren<FaqProps>) => {
  const toggle = () => {
    onItemClicked({
      isOpen: !isOpen,
    });
  };

  return (
    <FaqContainer
      open={isOpen}
      onOpenChange={toggle}
      $borderColor={borderColor}
    >
      <Title isOpen={isOpen} iconColor={iconColor}>
        {question}
      </Title>
      <Content>{children}</Content>
    </FaqContainer>
  );
};
