import { differenceInSeconds } from "date-fns";
import { isNumber } from "lodash";

import type { FunnelAnswers, Steps } from "../config";
import type { Intent, LeadScoreParams } from "../types";
import { getLeadScoreParams } from "../utils/getLeadScoreParams";

import { saveModelResultToMeta } from "./saveModelResultToMeta";
import { sendWebLeadScoreResultEvent } from "./sendWebLeadScoreResultEvent";

type ScoreResult = {
  intent: Intent;
  leadScoreParams: LeadScoreParams;
  score: number;
};

export const MODEL_VERSION = "v3.000";

const LOW_INTENT_MAX_SCORE = 4.16;
const MEDIUM_INTENT_MAX_SCORE = 7.87;

const MORE_INFO_MIN_LENGTH = 60;
const SESSION_DURATION_MIN_SECONDS = 468;

const MIN_SCORE = -0.15411419032102977;
const MAX_SCORE = 0.2172624740059983;

type TrackArgs = {
  funnelAnswers: FunnelAnswers;
  scoreResult: ScoreResult;
  step: Steps;
};

function track({ funnelAnswers, scoreResult, step }: TrackArgs) {
  const { practice, subPractice } = funnelAnswers;
  const { score, intent, leadScoreParams } = scoreResult;

  sendWebLeadScoreResultEvent({
    currentStepKey: step,
    intent,
    leadScoreParams,
    modelName: "Andrew",
    practice,
    score,
    subPractice,
    version: MODEL_VERSION,
  });
}

function resolveLeadIntent(leadScore: number): Intent {
  if (leadScore <= LOW_INTENT_MAX_SCORE) return "low";
  if (leadScore <= MEDIUM_INTENT_MAX_SCORE) return "medium";
  return "high";
}

interface Options {
  answers: FunnelAnswers;
  step: Steps;
}

export function calculateLeadScore({ answers, step }: Options): ScoreResult {
  const leadScoreParams = getLeadScoreParams(answers);

  const deviceBrowser = `${leadScoreParams.deviceType} - ${leadScoreParams.browser?.toLowerCase()}`;

  let sum = 0;

  // Case Urgency
  switch (leadScoreParams.caseUrgency?.toLowerCase().trim()) {
    case "yes, right away":
      sum += 0.06819273502;
      break;

    case "yes, but it’s not urgent":
      sum += 0.06819273502;
      break;

    case "not yet, i’m just looking for advice":
      sum += -0.07513394186;
      break;
  }

  // Device Brand
  switch (leadScoreParams.deviceVendor?.toLowerCase().trim()) {
    case undefined:
      sum += 0.03051767972;
      break;
    case "motorola":
      sum += -0.02240864651;
      break;
  }

  // Device Browser
  switch (deviceBrowser.toLowerCase().trim()) {
    case "mobile - chrome webview":
      sum += -0.0201857855;
      break;
    case "mobile - uiwebview":
      sum += -0.03973627055;
      break;
    case "mobile - chrome":
      sum += 0.01654666272;
      break;
  }

  // Service Type
  switch (leadScoreParams.serviceType?.toLowerCase().trim()) {
    case "i want to respond to a divorce petition":
      sum += 0.05148656446;
      break;
    case "i want a divorce involving child custody":
      sum += 0.02510555146;
      break;
    case "i'm looking to initiate a divorce":
      sum += -0.03638581645;
      break;
    case "i want shared custody with my partner":
      sum += 0.02637183381;
      break;
  }

  // More Info length
  if (
    leadScoreParams.moreInfo &&
    leadScoreParams.moreInfo.trim().length >= MORE_INFO_MIN_LENGTH
  ) {
    sum += 0.02898637682;
  }

  // User came by campaign
  if (leadScoreParams.hasCampaign) {
    sum += -0.03539361426;
  }

  // Returning visitor
  if (
    isNumber(leadScoreParams?.visitsCount) &&
    leadScoreParams?.visitsCount > 0
  ) {
    sum += 0.04642430528;
  }

  // User stayed on the site for a while
  if (leadScoreParams?.sessionStartTime) {
    const sessionDuration = differenceInSeconds(
      new Date(),
      new Date(leadScoreParams.sessionStartTime),
    );
    if (sessionDuration >= SESSION_DURATION_MIN_SECONDS) {
      sum += 0.05051883209;
    }
  }

  const normalizedScore = 1 + ((sum - MIN_SCORE) / (MAX_SCORE - MIN_SCORE)) * 9;

  const intent = resolveLeadIntent(normalizedScore);

  // Update FunnelAnswers with leadScore and intent

  answers.leadScore = normalizedScore;
  answers.leadIntent = intent;

  const res = {
    score: normalizedScore,
    intent,
    leadScoreParams,
  };

  saveModelResultToMeta({
    name: "Andrew",
    score: res.score,
    version: MODEL_VERSION,
    step,
  });

  // Send BI event
  track({ funnelAnswers: answers, scoreResult: res, step });

  return res;
}
