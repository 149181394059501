export const deprecatedColors = {
  b10: "#8C8E9A",
  b100: "#192348",
  b20: "#E5E5E5",
  b40: "#9E9FA8",
  b5: "#DFE0E5",
  b6: "#F4F5F5",
  b7: "#B0BAC4",
  b80: "#454C68",
  b85: "#223077",
  b90: "#192666",
  g120: "#006A52",
  g20: "#DFEAE3",
  g50: "#A8C1B2",
  g70: "#80AB91",
  n100: "#F7F1E8",
  n30: "#F5F1EC",
  n50: "#FBF7F0",
  n90: "#ECE7E0",
  o100: "#FE7654",
  o20: "#F9DFD0",
  o50: "#FCC6AD",
  o70: "#FFAA85",
  p100: "#7B93DF",
  p50: "#EFE8FD",
  p60: "#B8C1E2",
  primary: "#2C816E",
  w0: "#FFFFFF",
  w60: "#F6F5F4",
  w80: "#FDFBF8",
  y100: "#FEE097",
  y20: "#F8EECE",
  y60: "#FBE6B8",
} as const;
export type DeprecatedColors = typeof deprecatedColors;
export type DeprecatedColorKeys = keyof DeprecatedColors;
