import { gql } from "@apollo/client";

import { client } from "@/lib/apollo-client";

const PREDICT_BIDDING_MODEL = gql`
  query PredictBiddingModel($features: BiddingModelFeatures!) {
    predictedBiddingScore: predictBiddingScore(
      payload: { features: $features }
    ) {
      conversionScore
      dealValuePrediction
      product
    }
  }
`;

interface BiddingModelFeatures {
  anonymousId?: string;
  browser?: string;
  browserVersion?: string;
  caseUrgency?: string;
  contextPagePath?: string;
  countyFips?: number;
  dayPartBusiness?: string;
  dayPartFine?: string;
  daysFromCutoff?: number;
  deviceBrand?: string;
  deviceClass?: string;
  deviceName?: string;
  emailDomain?: string;
  hostname?: string;
  hourOfDay?: number;
  ipCity?: string;
  ipState?: string;
  isWeekend?: boolean;
  matterRelated?: string;
  moreInfoLength?: number;
  msFromFirstPresentedSlotToSelectedSlot?: number;
  msFromSessionStartToCalendarSuccessEvent?: number;
  os?: string;
  osClass?: string;
  pageType?: string;
  serviceType?: string;
  sessionDow?: number;
  stateByLeadSelection?: string;
  step?: string;
  subPractice?: string;
  subPracticeFromMatter?: string;
  userAgent?: string;
  utmSource?: string;
  zipcode?: string;
}

export interface BiddingModelResponse {
  conversionScore: number;
  dealValuePrediction: number;
  product: number;
}

export async function predictBiddingModelScore(
  features: BiddingModelFeatures = {},
): Promise<BiddingModelResponse> {
  const response = await client.query({
    query: PREDICT_BIDDING_MODEL,
    variables: {
      features,
    },
  });

  const data = response.data.predictedBiddingScore as BiddingModelResponse;

  return data;
}
