import { jsx as e } from "react/jsx-runtime";
import { forwardRef as c } from "react";
import { S as s } from "../../index-DoOsnuB6.js";
import { c as i } from "../../index-DFo-3WCJ.js";
import { c as p } from "../../index-Bm_zk_a7.js";
const l = i(
  "inline-flex h-s-4 items-center justify-center gap-sp-1 whitespace-nowrap rounded-full px-sp-2 text-caption-highlight",
  {
    variants: {
      variant: {
        danger: "bg-[--color-tag-danger-bg] text-[--color-tag-danger-content]",
        warning: "bg-[--color-tag-warning-bg] text-[--color-tag-warning-content]",
        success: "bg-[--color-tag-success-bg] text-[--color-tag-success-content]",
        status: "bg-[--color-tag-status-bg] text-[--color-tag-status-content]",
        pending: "bg-[--color-tag-pending-bg] text-[--color-tag-pending-content]",
        promotion1: "bg-[--color-tag-promotion_1-bg] text-[--color-tag-promotion_1-content]",
        promotion2: "bg-[--color-tag-promotion_2-bg] text-[--color-tag-promotion_2-content]"
      }
    }
  }
), m = c(
  ({ children: t, className: o, variant: n = "status", asChild: r, ...a }, g) => /* @__PURE__ */ e(
    r ? s : "span",
    {
      className: p(l({ variant: n, className: o })),
      ...a,
      ref: g,
      children: t
    }
  )
);
m.displayName = "Tag";
export {
  m as Tag
};
