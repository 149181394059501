import { jsx as o } from "react/jsx-runtime";
import * as f from "react";
import { forwardRef as l } from "react";
import { P as s } from "../../index-C8BDXGwJ.js";
import { c as d } from "../../index-Bm_zk_a7.js";
var n = "Label", i = f.forwardRef((t, e) => /* @__PURE__ */ o(
  s.label,
  {
    ...t,
    ref: e,
    onMouseDown: (a) => {
      var r;
      a.target.closest("button, input, select, textarea") || ((r = t.onMouseDown) == null || r.call(t, a), !a.defaultPrevented && a.detail > 1 && a.preventDefault());
    }
  }
));
i.displayName = n;
var m = i;
const c = l(({ className: t, ...e }, a) => /* @__PURE__ */ o(
  m,
  {
    ref: a,
    className: d("text-body2", t),
    ...e
  }
));
c.displayName = m.displayName;
export {
  c as Label
};
