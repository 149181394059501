import { useEffect } from "react";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";

import { useWindowSize } from "@/hooks/use-window-size";

import { useAppContext } from "../store";
import { getValidCurrentUrlForReferrer } from "../store/campaign-data-context/campaign-data-utils";

import type { PageMetaData, ServicesContainerProps } from "./analytics.types";
import { BiEventsNames } from "./event-names";

export const AppInitService = ({ pageProps }: ServicesContainerProps) => {
  const router = useRouter();
  const {
    campaignData: { extractCampaignData, setCampaignData, campaignData },
    geoLocation: { geoLocation },
  } = useAppContext();
  const { width, height } = useWindowSize();

  useEffect(() => {
    const campaignData = extractCampaignData();
    const referrer = getValidCurrentUrlForReferrer();
    setCampaignData?.({ ...campaignData, referrer });

    analytics.setPersistentData({
      utm_source: campaignData.source,
      utm_medium: campaignData.medium,
      utm_campaign: campaignData.campaignId,
      utm_sub_campaign: campaignData.adgroupId,
      utm_keyword: campaignData.keyword,
      utm_content: campaignData.content,
      url_referrer: referrer,
      search_network: campaignData.searchNetwork,
      affiliate_id: campaignData.affiliateId,
      gclid: campaignData.gclid,
      fbclid: campaignData.fbclid,
      ttclid: campaignData.ttclid,
      match_type: campaignData.matchType,
      match_location: campaignData.matchLocation,
      session_id: crypto.randomUUID?.(),
      screen_width: width,
      screen_height: height,
      brand: "Marble",
      mcidtype: campaignData.mcidtype,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analytics.track(BiEventsNames.WebOpenSiteCross);

    // Fire PageView event on first render
    analytics.track(BiEventsNames.WebPageView, {
      pathname: router.asPath,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleStartRouteChange() {
      const referrer = getValidCurrentUrlForReferrer();
      setCampaignData?.({ ...campaignData, referrer });

      analytics.setPersistentData({
        url_referrer: referrer,
      });
    }

    function triggerWebPageViewEvent() {
      analytics.track(BiEventsNames.WebPageView, {
        pathname: router.asPath,
      });
    }

    router.events.on("routeChangeComplete", triggerWebPageViewEvent);
    router.events.on("routeChangeStart", handleStartRouteChange);

    return () => {
      router.events.off("routeChangeComplete", triggerWebPageViewEvent);
      router.events.off("routeChangeStart", handleStartRouteChange);
    };
  }, [router, campaignData, setCampaignData]);

  useEffect(() => {
    const metaData = pageProps?.metaData as PageMetaData;

    if (!metaData || !metaData.pageType || !metaData.pageName) {
      return;
    }

    analytics.setPersistentData({
      page_type: metaData.pageType,
      page_name: metaData.pageName,
    });
  }, [pageProps.metaData]);

  useEffect(() => {
    if (!geoLocation?.city) {
      return;
    }

    analytics.setPersistentData({
      user_city: geoLocation.city,
      ip_city: geoLocation.ipCity,
      ip_state: geoLocation.ipState,
      ip_country_code: geoLocation.ipCountryCode,
      ip_country_name: geoLocation.ipCountryName,
    });
  }, [
    geoLocation.city,
    geoLocation.ipCity,
    geoLocation.ipCountryCode,
    geoLocation.ipCountryName,
    geoLocation.ipState,
  ]);

  return null;
};
