import React from "react";
import { Builder, withChildren } from "@builder.io/react";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { analytics } from "@flare/analytics";

import { useIsDesktop } from "@/hooks/use-is-desktop";
import { BiEventsNames } from "@/services/analytics/event-names";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import { interactiveElementsComponentsNames } from "../consts";
import { PlaceHolder } from "../PlaceHolder";
import { SliderEffects } from "../SingleSlider/SingleSlider.builder.props";

import { SliderBuilderProps } from "./Slider.builder.props";
import { GlobalStyle, PaginationContainer } from "./Slider.styled";
import type { SliderProps } from "./Slider.types";

export const Slider = (props: SliderProps) => {
  const {
    children,
    autoplay,
    delay,
    speed,
    prevClassName,
    nextClassName,
    showPagination,
    mobileSpaceBetween,
    desktopSpaceBetween,
    event,
    includeMargins,
  } = props;

  const isDesktop = useIsDesktop();

  const spaceBetween = isDesktop ? desktopSpaceBetween : mobileSpaceBetween;

  const hasChildren = Array.isArray(children) && children.length > 0;

  const handleSwiperEvent = (eventType: string) => {
    analytics.track(BiEventsNames.WebPageClick, {
      location: event?.eventData?.location,
      element_name: "Slider",
      click_type: eventType,
    });
  };

  const propsExcludingBuilderProps = [...Object.values(props)].slice(2);

  return (
    <>
      <GlobalStyle margin={includeMargins ? spaceBetween : 0} />
      <Swiper
        onSliderFirstMove={() => handleSwiperEvent("swipe")}
        freeMode
        navigation={{
          prevEl: `.${prevClassName}`,
          nextEl: `.${nextClassName}`,
        }}
        pagination={
          showPagination &&
          !isDesktop && {
            clickable: true,
            el: ".swiper-pagination-custom",
          }
        }
        key={`${propsExcludingBuilderProps}`}
        slidesPerView={hasChildren ? "auto" : 0}
        effect={SliderEffects.slide}
        speed={speed}
        spaceBetween={spaceBetween}
        autoplay={
          autoplay && {
            delay,
            disableOnInteraction: false,
          }
        }
        modules={[Autoplay, Pagination, Navigation, FreeMode]}
      >
        {hasChildren ? (
          React.Children.map(children, (child, index) => {
            return <SwiperSlide key={`${index}`}>{child}</SwiperSlide>;
          })
        ) : (
          <PlaceHolder>Insert Slides Inside</PlaceHolder>
        )}
        <PaginationContainer className="swiper-pagination-custom" />
      </Swiper>
    </>
  );
};

const SliderWithChildren = withChildren(Slider);

Builder.registerComponent(SliderWithChildren, {
  name: interactiveElementsComponentsNames.SLIDER,
  inputs: SliderBuilderProps,
});
