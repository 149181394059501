import type { IconButton } from "@marbletech/core";

import { deprecatedColors } from "@/styles/deprecated-colors";

import type { EventProps } from "../types";

export interface IconButtonProps
  extends Omit<Parameters<typeof IconButton>["0"], "onClick"> {
  event: EventProps;
  href?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  pathname?: string;
}

type variants = NonNullable<IconButtonProps["variant"]>;

type sizes = NonNullable<IconButtonProps["size"]>;

type colors = NonNullable<IconButtonProps["color"]>;

type shape = NonNullable<IconButtonProps["shape"]>;

export const variants: Array<variants> = [
  "filled",
  "ghost",
  "outlined",
  "solid",
  "transparent",
];

export const sizes: Array<sizes> = ["xxs", "xs", "sm", "md"];

export const colors: Array<colors> = [
  ...(Object.keys(deprecatedColors) as Array<colors>),
  "transparent",
];

export const shape: Array<shape> = ["circle", "square"];
