import { jsxs as V, jsx as d } from "react/jsx-runtime";
import * as s from "react";
import { c as k, u as j } from "../../index-47DXPxf5.js";
import { u as P } from "../../index-DoOsnuB6.js";
import { c as w } from "../../index-CwaZVody.js";
import { P as g } from "../../index-C8BDXGwJ.js";
import { c as I, R as z, I as T } from "../../index-CSQxi4eg.js";
import { u as U } from "../../index-DBpAfTyY.js";
import { u as B } from "../../index-CI9iLup9.js";
import { u as H } from "../../index-BZPx6jYI.js";
import { P as $ } from "../../index-BvsBDqN-.js";
import { c as G } from "../../index-Bm_zk_a7.js";
import { focusRing as W } from "../../utils/focus-ring.js";
var y = "Radio", [X, N] = w(y), [Y, J] = X(y), E = s.forwardRef(
  (o, t) => {
    const {
      __scopeRadio: e,
      name: c,
      checked: r = !1,
      required: a,
      disabled: i,
      value: p = "on",
      onCheck: u,
      form: f,
      ...b
    } = o, [l, m] = s.useState(null), n = P(t, (h) => m(h)), v = s.useRef(!1), R = l ? f || !!l.closest("form") : !0;
    return /* @__PURE__ */ V(Y, { scope: e, checked: r, disabled: i, children: [
      /* @__PURE__ */ d(
        g.button,
        {
          type: "button",
          role: "radio",
          "aria-checked": r,
          "data-state": A(r),
          "data-disabled": i ? "" : void 0,
          disabled: i,
          value: p,
          ...b,
          ref: n,
          onClick: k(o.onClick, (h) => {
            r || u == null || u(), R && (v.current = h.isPropagationStopped(), v.current || h.stopPropagation());
          })
        }
      ),
      R && /* @__PURE__ */ d(
        Q,
        {
          control: l,
          bubbles: !v.current,
          name: c,
          value: p,
          checked: r,
          required: a,
          disabled: i,
          form: f,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
E.displayName = y;
var S = "RadioIndicator", _ = s.forwardRef(
  (o, t) => {
    const { __scopeRadio: e, forceMount: c, ...r } = o, a = J(S, e);
    return /* @__PURE__ */ d($, { present: c || a.checked, children: /* @__PURE__ */ d(
      g.span,
      {
        "data-state": A(a.checked),
        "data-disabled": a.disabled ? "" : void 0,
        ...r,
        ref: t
      }
    ) });
  }
);
_.displayName = S;
var Q = (o) => {
  const { control: t, checked: e, bubbles: c = !0, ...r } = o, a = s.useRef(null), i = H(e), p = B(t);
  return s.useEffect(() => {
    const u = a.current, f = window.HTMLInputElement.prototype, l = Object.getOwnPropertyDescriptor(f, "checked").set;
    if (i !== e && l) {
      const m = new Event("click", { bubbles: c });
      l.call(u, e), u.dispatchEvent(m);
    }
  }, [i, e, c]), /* @__PURE__ */ d(
    "input",
    {
      type: "radio",
      "aria-hidden": !0,
      defaultChecked: e,
      ...r,
      tabIndex: -1,
      ref: a,
      style: {
        ...o.style,
        ...p,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function A(o) {
  return o ? "checked" : "unchecked";
}
var Z = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], C = "RadioGroup", [ee, ke] = w(C, [
  I,
  N
]), x = I(), D = N(), [oe, re] = ee(C), M = s.forwardRef(
  (o, t) => {
    const {
      __scopeRadioGroup: e,
      name: c,
      defaultValue: r,
      value: a,
      required: i = !1,
      disabled: p = !1,
      orientation: u,
      dir: f,
      loop: b = !0,
      onValueChange: l,
      ...m
    } = o, n = x(e), v = U(f), [R, h] = j({
      prop: a,
      defaultProp: r,
      onChange: l
    });
    return /* @__PURE__ */ d(
      oe,
      {
        scope: e,
        name: c,
        required: i,
        disabled: p,
        value: R,
        onValueChange: h,
        children: /* @__PURE__ */ d(
          z,
          {
            asChild: !0,
            ...n,
            orientation: u,
            dir: v,
            loop: b,
            children: /* @__PURE__ */ d(
              g.div,
              {
                role: "radiogroup",
                "aria-required": i,
                "aria-orientation": u,
                "data-disabled": p ? "" : void 0,
                dir: v,
                ...m,
                ref: t
              }
            )
          }
        )
      }
    );
  }
);
M.displayName = C;
var O = "RadioGroupItem", F = s.forwardRef(
  (o, t) => {
    const { __scopeRadioGroup: e, disabled: c, ...r } = o, a = re(O, e), i = a.disabled || c, p = x(e), u = D(e), f = s.useRef(null), b = P(t, f), l = a.value === r.value, m = s.useRef(!1);
    return s.useEffect(() => {
      const n = (R) => {
        Z.includes(R.key) && (m.current = !0);
      }, v = () => m.current = !1;
      return document.addEventListener("keydown", n), document.addEventListener("keyup", v), () => {
        document.removeEventListener("keydown", n), document.removeEventListener("keyup", v);
      };
    }, []), /* @__PURE__ */ d(
      T,
      {
        asChild: !0,
        ...p,
        focusable: !i,
        active: l,
        children: /* @__PURE__ */ d(
          E,
          {
            disabled: i,
            required: a.required,
            checked: l,
            ...u,
            ...r,
            name: a.name,
            ref: b,
            onCheck: () => a.onValueChange(r.value),
            onKeyDown: k((n) => {
              n.key === "Enter" && n.preventDefault();
            }),
            onFocus: k(r.onFocus, () => {
              var n;
              m.current && ((n = f.current) == null || n.click());
            })
          }
        )
      }
    );
  }
);
F.displayName = O;
var ae = "RadioGroupIndicator", q = s.forwardRef(
  (o, t) => {
    const { __scopeRadioGroup: e, ...c } = o, r = D(e);
    return /* @__PURE__ */ d(_, { ...r, ...c, ref: t });
  }
);
q.displayName = ae;
var L = M, K = F, te = q;
const de = s.forwardRef(({ className: o, hasError: t, ...e }, c) => /* @__PURE__ */ d(
  L,
  {
    className: G("group grid gap-sp-3", o),
    "data-state": t ? "error" : void 0,
    ...e,
    ref: c
  }
));
de.displayName = L.displayName;
const ce = s.forwardRef(({ className: o, ...t }, e) => /* @__PURE__ */ d(
  K,
  {
    ref: e,
    className: G(
      W,
      // Base
      "peer aspect-square size-s-2 rounded-full border border-[--color-radio-stroke-unchecked]",
      // Checked
      "data-[state=checked]:border-[--color-radio-bg-checked] data-[state=checked]:bg-[--color-radio-bg-checked] data-[state=checked]:hover:border-[--color-radio-bg-checked-hover] data-[state=checked]:hover:bg-[--color-radio-bg-checked-hover]",
      // Hover
      "hover:bg-[--color-radio-bg-unchecked-hover]",
      // Disabled
      "disabled:pointer-events-none disabled:border-[--color-radio-stroke-unchecked-disabled] disabled:bg-[--color-radio-bg-unchecked-disabled] data-[state=checked]:disabled:border-[--color-radio-bg-checked-disabled] data-[state=checked]:disabled:bg-[--color-radio-bg-checked-disabled]",
      // Error
      "group-data-[state=error]:border-action-stroke-input-danger-active",
      o
    ),
    ...t,
    children: /* @__PURE__ */ d(te, { className: "flex items-center justify-center", children: /* @__PURE__ */ d("div", { className: "size-s-1 rounded-full bg-[--color-radio-content-checked] group-data-[disabled]:bg-[--color-radio-content-checked-disabled]" }) })
  }
));
ce.displayName = K.displayName;
export {
  de as RadioGroup,
  ce as RadioGroupItem
};
