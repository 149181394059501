import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import { cn } from "@flare/ui";

import { useFeatureFlag } from "@/services/experiments";

export type RadioGroupProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Root
>;
export type RadioGroupItemProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
>;

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-sp-3", className)}
      {...props}
      ref={ref}
    />
  );
});

RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, ...props }, ref) => {
  const { isOn, isReady } = useFeatureFlag("FUNNEL_TEST_MARATHON_2");

  if (!isReady) {
    return null;
  }

  const originalClassName =
    "w-full rounded-lg  bg-grey-50 px-4 py-6 text-left text-blue-100 transition-colors duration-200 ease-in-out data-[state=checked]:bg-green-20 data-[state=checked]:text-blue-100 data-[state=checked]:ring-1 data-[state=checked]:ring-inset data-[state=checked]:ring-green-100 md:hover:bg-green-20";

  //TODO: Update this to use correct tokens and hardcoded values
  const testClassName =
    "h-s-9 w-full rounded-xl text-body1-highlight bg-[#17203d08] pl-sp-6 pr-sp-5 text-left transition-colors hover:bg-[#17203d14] data-[state=checked]:bg-action-primary-1 data-[state=checked]:text-action-primary-0-2";

  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(isOn ? testClassName : originalClassName, className)}
      {...props}
    />
  );
});

RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
