import type { FunnelMetaModel, FunnelModels } from "../config";
import { getFunnelMeta, setFunnelMeta } from "../funnel.utils";

export function getUniqueModels(models: FunnelModels): FunnelModels {
  const uniqueMap = new Map(
    models.map((model) => [`${model.name}-${model.step}`, model]),
  ).values();

  return Array.from(uniqueMap);
}

export function saveModelResultToMeta(result: FunnelMetaModel) {
  const meta = getFunnelMeta();

  const models = meta.models || ([] as FunnelModels);
  models.push(result);

  const uniqueModels = getUniqueModels(models);

  meta.models = uniqueModels;
  setFunnelMeta(meta);
}
