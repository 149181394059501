import { builder, BuilderComponent } from "@builder.io/react";
import { MarbleProductThemeProvider } from "@marbletech/core";
import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType,
} from "next";
import { useRouter } from "next/router";

import { analytics } from "@flare/analytics";

import { pagesModel } from "@/builder/model-names";
import { trackExperimentEvent } from "@/builder/utilities";
import { SEO } from "@/components/SEO";
import {
  BUILDER_PAGES_LIMIT,
  REVALIDATION_TIME_SECONDS,
} from "@/lib/constants";
import { BiEventsNames as biEventsNames } from "@/services/analytics/event-names";

import "@/components/Builder/index";

import { DialogFlowChatbot } from "./dialog-flow-chatbot";

type Path = {
  params: {
    page: string[];
  };
};

type Page = {
  data: {
    description: string;
    noIndex: boolean;
    openGraphImage: string;
    schemaMarkup: object;
    title: string;
    url: string;
  };
  name: string;
};

type BiEventsNames = typeof biEventsNames;

type SendBiEventProps = {
  eventName: keyof BiEventsNames;
  properties: { [key: string]: string };
};

export default function StaticPage({
  page,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { query } = useRouter();

  const seo = {
    noIndex: page?.data?.noIndex,
    title: page?.data?.title,
    description: page?.data?.description,
    schemaMarkup: page?.data?.schemaMarkup,
    openGraphImage: page?.data?.openGraphImage,
  };

  return (
    <MarbleProductThemeProvider>
      <SEO {...seo} />
      <DialogFlowChatbot />
      <BuilderComponent
        model={pagesModel.page}
        content={page}
        data={{ queryParams: query }}
        contentLoaded={(_, content) => {
          trackExperimentEvent(content);
        }}
        context={{
          sendBiEvent: ({ eventName, properties }: SendBiEventProps) => {
            if (biEventsNames[eventName]) {
              analytics.track(biEventsNames[eventName], { ...properties });
            }
          },
        }}
      />
    </MarbleProductThemeProvider>
  );
}

export const getStaticPaths = (async () => {
  const staticPages = await builder.getAll(pagesModel.page, {
    limit: BUILDER_PAGES_LIMIT,
    options: { noTargeting: true },
  });

  const paths: Path[] = staticPages.map((page) => ({
    params: {
      page: page?.data?.url?.split("/").filter((item: string) => item),
    },
  }));

  // filter out the "/thank-you-referral" page
  const filteredPaths = paths.filter(
    (path) => !["thank-you-referral"].includes(path?.params?.page?.[0]),
  );

  return {
    paths: filteredPaths,
    fallback: true,
  };
}) satisfies GetStaticPaths;

export const getStaticProps = (async ({ params }) => {
  const pageParams = Array.isArray(params?.page)
    ? params?.page?.join("/")
    : params?.page;

  const page: Page = await builder
    .get(pagesModel.page, { url: `/${pageParams ?? ""}` })
    .promise();

  return {
    props: {
      page: page || null,
      isBuilder: true,
    },
    notFound: !page,
    revalidate: REVALIDATION_TIME_SECONDS,
  };
}) satisfies GetStaticProps;
