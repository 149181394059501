import type { SubPractices } from "../config.types";

import {
  immigrationBookLssScreens,
  immigrationEligibleForLssScreens,
  intakeConfirmationScreens,
} from "./common-immigration";

export const immigrationOtherVisa: SubPractices = {
  firstScreen: "service-type",
  screens: {
    "service-type": {
      next: async ({ answers }) => {
        const { serviceType } = answers;

        if (serviceType === "else") {
          return "deportation-or-removal";
        }
        if (serviceType === "Tourist visa") {
          return "tourist-visa-status";
        }
        return "visa-eligibility";
      },
    },
    "visa-eligibility": {
      next: async ({ answers }) => {
        const { serviceType, visaEligibility } = answers;

        const studentInScope =
          serviceType === "Student visa" &&
          visaEligibility === "I’ve been accepted to school in the U.S.";

        const employmentInScope =
          serviceType === "Employment visa" &&
          visaEligibility === "I have a job offer from a U.S. employer";

        const investorInScope =
          serviceType === "Investor visa" &&
          [
            "I have an investment in the U.S.",
            "I’m considering a few investment options",
          ].includes(visaEligibility!);

        const isInScope =
          studentInScope || employmentInScope || investorInScope;

        return isInScope ? "deportation-or-removal" : "out-of-scope";
      },
    },
    "tourist-visa-status": {
      next: async () => "deportation-or-removal",
    },
    "case-urgency": {
      next: async ({ experiments }) => {
        const { abTestImmigrationEligibility } = experiments;
        return abTestImmigrationEligibility ? "more-info" : "funding-source";
      },
    },

    ...immigrationEligibleForLssScreens,

    "funding-source": {
      next: async () => "beneficiary-region",
    },
    "beneficiary-region": {
      next: async () => "more-info",
    },

    ...intakeConfirmationScreens,
    ...immigrationBookLssScreens,

    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
