import * as e from "react";
import { a as s } from "./index-CwaZVody.js";
var u = e.useId || (() => {
}), i = 0;
function n(r) {
  const [t, a] = e.useState(u());
  return s(() => {
    a((o) => o ?? String(i++));
  }, [r]), t ? `radix-${t}` : "";
}
export {
  n as u
};
