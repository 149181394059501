import type { DeprecatedColorKeys } from "@/styles/deprecated-colors";

import type { BuilderAttributes } from "../types";

export enum HeadingTypes {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5",
}

export type ComponentList = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in HeadingTypes]: React.ComponentType<any>;
};

export interface OverrideProps {
  color?: DeprecatedColorKeys;
  customColor?: string;
  textAlign?: string;
  whiteSpace?: string;
}

export interface HeadingProps {
  attributes?: BuilderAttributes;
  balanceText?: boolean;
  children?: React.ReactNode;
  color?: DeprecatedColorKeys;
  cssOverrides?: OverrideProps;
  customColor?: string;
  text?: string;
  type: keyof typeof HeadingTypes;
}
