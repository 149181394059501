import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

import { reportGraphQLError, reportNetworkError } from "./reporters";

const OLD_GRAPHQL_OPERATIONS = new Set([
  "CreateWebLead",
  "UpdateWebLead",
  "CreateRejectedCustomer",
  "GetIntakeMeetingSlots",
  "GetLeadIdByFunnelActionId",
  "RegisterStripeCustomer",
  "AddPayment",
]);

const isOldBackendOp = (operationName?: string): boolean =>
  Boolean(operationName && OLD_GRAPHQL_OPERATIONS.has(operationName));

const oldHttpLink = createHttpLink({
  uri: (operation) =>
    `${process.env.NEXT_PUBLIC_OLD_GRAPHQL_URL}?op=${
      operation.operationName
    }&oid=${crypto.randomUUID()}`,
});

const httpLink = createHttpLink({
  uri: (operation) =>
    `${process.env.NEXT_PUBLIC_CUSTOMERS_GRAPHQL_URL}?op=${
      operation.operationName
    }&oid=${crypto.randomUUID()}`,
});

class AuthError extends Error {}

const authLink = setContext(async (_, { headers }) => {
  let token: string | null = null;
  try {
    const response = await fetch(`/api/user/token`);
    if (!response.ok) {
      throw new AuthError("Failed to fetch token for graphql requests");
    }

    const json = await response.json();
    token = json.token;
  } catch (error) {
    console.error(error);
  }
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    reportGraphQLError({ operation, graphQLErrors, networkError });
  } else if (networkError) {
    reportNetworkError({ operation, networkError });
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 1000 * 60 * 5, // 5m
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (error) => !!error,
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    retryLink,
    ApolloLink.split(
      (op) => isOldBackendOp(op.operationName),
      oldHttpLink,
      httpLink,
    ),
  ]),
  cache: new InMemoryCache(),
});

export { ApolloProvider, client };
