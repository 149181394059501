import { jsx as a } from "react/jsx-runtime";
import { forwardRef as m } from "react";
import { S as u } from "../../index-DoOsnuB6.js";
import { c as g } from "../../index-DFo-3WCJ.js";
import { c as p } from "../../index-Bm_zk_a7.js";
import { buttonSharedVariants as f, buttonBase as x } from "../../utils/button-variants.js";
import { focusRing as v } from "../../utils/focus-ring.js";
import { LoadingAnimation as N } from "../button/button.js";
const y = g([v, x], {
  variants: {
    variant: {
      ...f
    },
    size: {
      sm: "size-s-5 rounded-sm ",
      md: "size-s-6 rounded-lg ",
      lg: "size-s-7 rounded-lg "
    },
    isLoading: {
      true: ""
    }
  },
  compoundVariants: [
    {
      isLoading: !0,
      variant: "primary",
      className: "disabled:bg-[--color-button-primary-bg] disabled:text-[--color-button-primary-content]"
    },
    {
      isLoading: !0,
      variant: "danger",
      className: "disabled:bg-[--color-button-danger-bg] disabled:text-[--color-button-danger-content]"
    },
    {
      isLoading: !0,
      variant: "outline",
      className: "disabled:border-[--color-button-outline-stroke] disabled:bg-[--color-button-outline-bg] disabled:text-[--color-button-outline-content]"
    },
    {
      isLoading: !0,
      variant: "extra",
      className: "disabled:bg-[--color-button-extra-bg] disabled:text-[--color-button-extra-content]"
    },
    {
      isLoading: !0,
      variant: "ghost",
      className: "disabled:bg-[--color-button-ghost-bg] disabled:text-[--color-button-ghost-content]"
    }
  ]
}), h = m(
  ({
    asChild: r = !1,
    children: n,
    className: e,
    size: o = "md",
    isLoading: t = !1,
    variant: s = "primary",
    disabled: i,
    ...d
  }, l) => {
    const b = r ? u : "button", c = i || t;
    return /* @__PURE__ */ a(
      b,
      {
        className: p(
          y({
            variant: s,
            size: o,
            className: e,
            isLoading: t
          })
        ),
        "data-size": o,
        ref: l,
        disabled: c,
        "aria-busy": t,
        ...d,
        children: t ? /* @__PURE__ */ a(N, { size: o }) : n
      }
    );
  }
);
h.displayName = "IconButton";
export {
  h as IconButton
};
