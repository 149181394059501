import { jsx as t, jsxs as i, Fragment as h } from "react/jsx-runtime";
import { forwardRef as y } from "react";
import { c as _ } from "../../index-DFo-3WCJ.js";
import { c as o } from "../../index-Bm_zk_a7.js";
import { buttonSharedVariants as z, buttonBase as I } from "../../utils/button-variants.js";
import { focusRing as S } from "../../utils/focus-ring.js";
import { Slottable as w, Slot as B } from "../slot/slot.js";
const E = _([S, I], {
  variants: {
    variant: {
      ...z
    },
    size: {
      sm: "h-s-5 rounded-sm px-[var(--fui-button-sm-padding-x2)] text-caption",
      md: "h-s-6 rounded-sm px-[var(--fui-button-md-padding-x2)] text-body2",
      lg: "h-s-7 rounded-lg px-[var(--fui-button-lg-padding-x2)] text-body1"
    },
    fullWidth: {
      true: "w-full"
    },
    isLoading: {
      true: ""
    },
    isStartIcon: {
      true: ""
    },
    isEndIcon: {
      true: ""
    }
  },
  compoundVariants: [
    {
      isLoading: !0,
      variant: "primary",
      className: "disabled:bg-[--color-button-primary-bg] disabled:text-[--color-button-primary-content]"
    },
    {
      isLoading: !0,
      variant: "danger",
      className: "disabled:bg-[--color-button-danger-bg] disabled:text-[--color-button-danger-content]"
    },
    {
      isLoading: !0,
      variant: "outline",
      className: "disabled:border-[--color-button-outline-stroke] disabled:bg-[--color-button-outline-bg] disabled:text-[--color-button-outline-content]"
    },
    {
      isLoading: !0,
      variant: "extra",
      className: "disabled:bg-[--color-button-extra-bg] disabled:text-[--color-button-extra-content]"
    },
    {
      isLoading: !0,
      variant: "ghost",
      className: "disabled:bg-[--color-button-ghost-bg] disabled:text-[--color-button-ghost-content]"
    },
    {
      isStartIcon: !0,
      size: "sm",
      className: "pl-[var(--fui-button-sm-padding-x1)] pr-[var(--fui-button-sm-padding-x2)] [&_span]:gap-[var(--fui-button-sm-padding-x3)]"
    },
    {
      isStartIcon: !0,
      size: "md",
      className: "pl-[var(--fui-button-md-padding-x1)] pr-[var(--fui-button-md-padding-x2)] [&_span]:gap-[var(--fui-button-md-padding-x3)]"
    },
    {
      isStartIcon: !0,
      size: "lg",
      className: "pl-[var(--fui-button-lg-padding-x1)] pr-[var(--fui-button-lg-padding-x2)] [&_span]:gap-[var(--fui-button-lg-padding-x3)]"
    },
    {
      isEndIcon: !0,
      size: "sm",
      className: "pl-[var(--fui-button-sm-padding-x2)] pr-[var(--fui-button-sm-padding-x1)] [&_span]:gap-[var(--fui-button-sm-padding-x3)]"
    },
    {
      isEndIcon: !0,
      size: "md",
      className: "pl-[var(--fui-button-md-padding-x2)] pr-[var(--fui-button-md-padding-x1)] [&_span]:gap-[var(--fui-button-md-padding-x3)]"
    },
    {
      isEndIcon: !0,
      size: "lg",
      className: "pl-[var(--fui-button-lg-padding-x2)] pr-[var(--fui-button-lg-padding-x1)] [&_span]:gap-[var(--fui-button-lg-padding-x3)]"
    }
  ]
});
function j({ size: a = "lg" }) {
  return /* @__PURE__ */ i(
    "div",
    {
      className: o(
        "col-start-1 row-start-1 flex items-center justify-center gap-sp-1",
        { "[&_span]:size-[calc(var(--fui-subline)*2)]": a === "lg" },
        { "[&_span]:size-[calc(var(--fui-subline)*1.5)]": a === "md" },
        { "[&_span]:size-[var(--fui-subline)]": a === "sm" }
      ),
      children: [
        /* @__PURE__ */ t("span", { className: "animate-dot-flashing rounded-full bg-current animation-delay-0" }),
        /* @__PURE__ */ t("span", { className: "animate-dot-flashing rounded-full bg-current animation-delay-200" }),
        /* @__PURE__ */ t("span", { className: "animate-dot-flashing rounded-full bg-current animation-delay-500" })
      ]
    }
  );
}
const V = y(
  ({
    asChild: a = !1,
    children: r,
    className: l,
    disabled: u,
    fullWidth: c = !1,
    isLoading: n = !1,
    size: s = "md",
    variant: m = "primary",
    startIcon: e,
    endIcon: d,
    ...p
  }, b) => {
    const g = a ? B : "button", f = !!e, x = !!d, v = u || n;
    return /* @__PURE__ */ t(
      g,
      {
        className: o(
          E({
            variant: m,
            size: s,
            fullWidth: c,
            className: l,
            isLoading: n,
            isStartIcon: f,
            isEndIcon: x
          })
        ),
        disabled: v,
        "aria-busy": n,
        "data-size": s,
        ref: b,
        ...p,
        children: n && !a ? /* @__PURE__ */ i(h, { children: [
          /* @__PURE__ */ t(
            "span",
            {
              className: o("col-start-1 row-start-1", {
                invisible: n
              }),
              children: r
            }
          ),
          n && /* @__PURE__ */ t(j, {})
        ] }) : /* @__PURE__ */ t(w, { child: r, children: (N) => /* @__PURE__ */ i("span", { className: "inline-grid grid-flow-col items-center justify-center", children: [
          e,
          N,
          d
        ] }) })
      }
    );
  }
);
V.displayName = "Button";
export {
  V as Button,
  j as LoadingAnimation
};
