import styled, { createGlobalStyle } from "styled-components";

import { deprecatedColors } from "@/styles/deprecated-colors";

import type { SliderContainerProps } from "./Slider.types";

export const GlobalStyle = createGlobalStyle<SliderContainerProps>`
  .swiper {
    width: 100%;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-slide:first-child{
    ${(props) => (props.margin ? `margin-left: ${props.margin}px` : "")};
  }
  .swiper-slide:last-child{
    ${(props) => (props.margin ? `margin-right: ${props.margin}px` : "")};
  }
`;

export const PaginationContainer = styled.div`
  margin-top: 23px;
  text-align: center;

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin-right: 1px !important;
  }
  .swiper-pagination-bullet-active {
    background-color: ${deprecatedColors.primary} !important;
    width: 16px;
    border-radius: 16px;
  }
`;
