import { analytics } from "@flare/analytics";

import { BiEventsNames } from "@/services/analytics/event-names";

import type { Practices, Steps } from "../config";
import type { Intent, LeadScoreParams } from "../types";

import type { BiddingModelResponse } from "./predictBiddingModelScore";

type EventData = {
  currentStepKey: Steps;
  intent: Intent;
  leadScoreParams: LeadScoreParams;
  modelName: "Roni" | "Andrew";
  modelScore?: BiddingModelResponse;
  practice?: keyof Practices;
  score: number;
  subPractice?: Practices[keyof Practices][number];
  version: string;
};

export function sendWebLeadScoreResultEvent(data: EventData) {
  analytics.track(BiEventsNames.WebLeadScoreResult, {
    practice: data.practice,
    sub_practice: data.subPractice,
    current_step_key: data.currentStepKey,
    lead_score: data.score,
    lead_intent: data.intent,
    leadScoreParams: data.leadScoreParams,
    scoreVersion: data.version,
    modelName: data.modelName,
    modelScore: data.modelScore,
  });
}
