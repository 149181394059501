import { FUNNEL_META_SESSION_STORAGE_KEY } from "@/services/storage";
import { getData, setData } from "@/services/storage/session-storage";

import type { FunnelMeta } from "./config";
import { steps } from "./config";

export function isBuilderStep(step: keyof typeof steps) {
  return typeof steps[step] === "string";
}

export function setFunnelMeta(data: FunnelMeta) {
  setData(FUNNEL_META_SESSION_STORAGE_KEY, data);
}

export function getFunnelMeta() {
  const funnelMeta =
    (getData(FUNNEL_META_SESSION_STORAGE_KEY) as FunnelMeta) || {};

  return funnelMeta;
}

export function saveToFunnelMeta(data: Partial<FunnelMeta>) {
  const funnelMeta = getFunnelMeta();
  setFunnelMeta({ ...funnelMeta, ...data });
}
