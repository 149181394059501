import React, { Children } from "react";
import { Builder, withChildren } from "@builder.io/react";
import Ticker from "framer-motion-ticker";

import { useIsDesktop } from "@/hooks/use-is-desktop";

import { PlaceHolder } from "../PlaceHolder";

const spaceBetweenOptions = ["8", "16", "24", "32"];
const durationOptions = ["10", "20", "30", "40", "50", "60", "70", "80"];

import { interactiveElementsComponentsNames } from "../consts";

import type { AutoplayCarouselProps } from "./AutoplayCarousel.types";

export const AutoplayCarousel = ({
  children,
  mobileGap,
  desktopGap,
  duration = 40,
}: AutoplayCarouselProps) => {
  const childrenArr = Children.toArray(children);
  const isDesktop = useIsDesktop();
  const gap = isDesktop ? desktopGap : mobileGap;

  return children ? (
    <Ticker duration={Number(duration)}>
      {React.Children.map(childrenArr, (card, index) => (
        <div
          key={index}
          style={{
            marginLeft: `${gap}px`,
          }}
        >
          {card}
        </div>
      ))}
    </Ticker>
  ) : (
    <PlaceHolder>Insert Elements Inside</PlaceHolder>
  );
};

const AutoplayCarouselWithChildren = withChildren(AutoplayCarousel);

Builder.registerComponent(AutoplayCarouselWithChildren, {
  name: interactiveElementsComponentsNames.AUTOPLAY_CAROUSEL,
  inputs: [
    {
      name: "desktopGap",
      friendlyName: "Gap between slides (desktop)",
      type: "string",
      defaultValue: 24,
      enum: spaceBetweenOptions,
    },
    {
      name: "mobileGap",
      friendlyName: "Gap between slides (mobile)",
      type: "string",
      defaultValue: 24,
      enum: spaceBetweenOptions,
    },
    {
      name: "duration",
      friendlyName: "Duration of one segment",
      type: "string",
      defaultValue: 40,
      enum: durationOptions,
      helperText: "default is 40. faster carousel < 40 < slower carousel",
    },
  ],
});
