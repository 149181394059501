import { Provider as BalancerProvider } from "react-wrap-balancer";
import { builder } from "@builder.io/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import type { AppProps } from "next/app";
import Head from "next/head";

import { Favicon } from "@/components/Favicon";
import { SEO } from "@/components/SEO";
import { ApolloProvider, client } from "@/lib/apollo-client";
import { queryClient } from "@/lib/react-query-client";
import { ServicesContainer } from "@/services";
import { AppWrapper } from "@/services/store";

import "../styles/fonts.css";
import "../styles/globals.css";

builder.init(process.env.NEXT_PUBLIC_BUILDER_IO_KEY as string);

import type { ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import type { NextPage } from "next";
import localFont from "next/font/local";

import { analytics } from "@flare/analytics";
import { I18nextProvider } from "@flare/i18n";

import { AuthProvider } from "@/modules/auth/auth-provider";
import { SplitProvider } from "@/modules/Split";
import { BiEventsNames } from "@/services/analytics/event-names";
import { useFeatureFlag } from "@/services/experiments";
import { featureFlagNames } from "@/services/experiments/constants";
import { i18n } from "@/services/locale/i18n";
import { ExperimentProvider } from "@/services/statsig/experiment-provider";
import { useUserSession } from "@/services/store/user-session/user-session";

import "@/builder/register";
import "@builder.io/widgets";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const serif = localFont({
  src: [
    {
      path: "../../public/fonts/BogueLight.woff2",
      weight: "300",
    },
    {
      path: "../../public/fonts/BogueRegular.woff2",
      weight: "400",
    },
  ],
  variable: "--font-family-serif",
});

const sans = localFont({
  src: [
    {
      path: "../../public/fonts/RoobertBold.woff2",
      weight: "600",
    },
    {
      path: "../../public/fonts/RoobertMedium.woff2",
      weight: "500",
    },
    {
      path: "../../public/fonts/RoobertRegular.woff2",
      weight: "400",
    },
  ],
  variable: "--font-family-sans",
});

analytics.init({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_ID as string,
  disable: process.env.NODE_ENV === "development",
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useUserSession();
  useFeatureFlag(featureFlagNames.FUNNEL_TEST_PRE_CLIENT_PORTAL);
  /**
   * Fire page load cross event as soon as possible,
   * this is temporary until we have a better solution
   */
  useEffect(() => {
    analytics.track(BiEventsNames.WebPageLoadCross);
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <I18nextProvider i18n={i18n}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <Favicon />
      </Head>
      <SEO {...(pageProps?.story?.seo || {})} />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={client}>
            <AppWrapper>
              <BalancerProvider>
                {/* Currently disabled due to product requirements */}
                {/* <GoogleOneTapSignIn parentContainerId="google-one-tap" /> */}
                <div
                  className={`${serif.variable} ${sans.variable} font-sans`}
                  id="appContainer"
                >
                  <SplitProvider>
                    <ExperimentProvider statsigProps={pageProps.statsigProps}>
                      {getLayout(<Component {...pageProps} />)}
                    </ExperimentProvider>
                  </SplitProvider>
                </div>
                <ServicesContainer pageProps={pageProps} />
              </BalancerProvider>
            </AppWrapper>
          </ApolloProvider>
        </QueryClientProvider>
      </AuthProvider>
      <SpeedInsights />
    </I18nextProvider>
  );
}

export default MyApp;
