import { useState as r, useEffect as c } from "react";
function i(e, t) {
  const [o, u] = r(e);
  return c(() => {
    const n = setTimeout(() => u(e), t || 500);
    return () => {
      clearTimeout(n);
    };
  }, [e, t]), o;
}
export {
  i as useDebounce
};
