import { jsx as Z, jsxs as Re } from "react/jsx-runtime";
import * as t from "react";
import { forwardRef as Te } from "react";
import { c as te } from "../../index-Bm_zk_a7.js";
var je = Object.defineProperty, De = Object.defineProperties, Oe = Object.getOwnPropertyDescriptors, J = Object.getOwnPropertySymbols, ve = Object.prototype.hasOwnProperty, he = Object.prototype.propertyIsEnumerable, fe = (r, l, a) => l in r ? je(r, l, { enumerable: !0, configurable: !0, writable: !0, value: a }) : r[l] = a, We = (r, l) => {
  for (var a in l || (l = {})) ve.call(l, a) && fe(r, a, l[a]);
  if (J) for (var a of J(l)) he.call(l, a) && fe(r, a, l[a]);
  return r;
}, Ae = (r, l) => De(r, Oe(l)), Be = (r, l) => {
  var a = {};
  for (var i in r) ve.call(r, i) && l.indexOf(i) < 0 && (a[i] = r[i]);
  if (r != null && J) for (var i of J(r)) l.indexOf(i) < 0 && he.call(r, i) && (a[i] = r[i]);
  return a;
};
function Ie(r) {
  let l = setTimeout(r, 0), a = setTimeout(r, 10), i = setTimeout(r, 50);
  return [l, a, i];
}
function _e(r) {
  let l = t.useRef();
  return t.useEffect(() => {
    l.current = r;
  }), l.current;
}
var Fe = 18, ge = 40, Ne = `${ge}px`, He = ["[data-lastpass-icon-root]", "com-1password-button", "[data-dashlanecreated]", '[style$="2147483647 !important;"]'].join(",");
function Le({ containerRef: r, inputRef: l, pushPasswordManagerStrategy: a, isFocused: i }) {
  let [E, p] = t.useState(!1), [M, S] = t.useState(!1), [W, F] = t.useState(!1), N = t.useMemo(() => a === "none" ? !1 : (a === "increase-width" || a === "experimental-no-flickering") && E && M, [E, M, a]), A = t.useCallback(() => {
    let f = r.current, P = l.current;
    if (!f || !P || W || a === "none") return;
    let g = f, y = g.getBoundingClientRect().left + g.offsetWidth, B = g.getBoundingClientRect().top + g.offsetHeight / 2, u = y - Fe, H = B;
    document.querySelectorAll(He).length === 0 && document.elementFromPoint(u, H) === f || (p(!0), F(!0));
  }, [r, l, W, a]);
  return t.useEffect(() => {
    let f = r.current;
    if (!f || a === "none") return;
    function P() {
      let y = window.innerWidth - f.getBoundingClientRect().right;
      S(y >= ge);
    }
    P();
    let g = setInterval(P, 1e3);
    return () => {
      clearInterval(g);
    };
  }, [r, a]), t.useEffect(() => {
    let f = i || document.activeElement === l.current;
    if (a === "none" || !f) return;
    let P = setTimeout(A, 0), g = setTimeout(A, 2e3), y = setTimeout(A, 5e3), B = setTimeout(() => {
      F(!0);
    }, 6e3);
    return () => {
      clearTimeout(P), clearTimeout(g), clearTimeout(y), clearTimeout(B);
    };
  }, [l, i, a, A]), { hasPWMBadge: E, willPushPWMBadge: N, PWM_BADGE_SPACE_WIDTH: Ne };
}
var $e = t.createContext({}), be = t.forwardRef((r, l) => {
  var a = r, { value: i, onChange: E, maxLength: p, textAlign: M = "left", pattern: S, placeholder: W, inputMode: F = "numeric", onComplete: N, pushPasswordManagerStrategy: A = "increase-width", pasteTransformer: f, containerClassName: P, noScriptCSSFallback: g = Ge, render: y, children: B } = a, u = Be(a, ["value", "onChange", "maxLength", "textAlign", "pattern", "placeholder", "inputMode", "onComplete", "pushPasswordManagerStrategy", "pasteTransformer", "containerClassName", "noScriptCSSFallback", "render", "children"]), H, ne, re, le, ae;
  let [we, Ee] = t.useState(typeof u.defaultValue == "string" ? u.defaultValue : ""), s = i ?? we, R = _e(s), L = t.useCallback((e) => {
    E == null || E(e), Ee(e);
  }, [E]), w = t.useMemo(() => S ? typeof S == "string" ? new RegExp(S) : S : null, [S]), c = t.useRef(null), Q = t.useRef(null), U = t.useRef({ value: s, onChange: L, isIOS: typeof window < "u" && ((ne = (H = window == null ? void 0 : window.CSS) == null ? void 0 : H.supports) == null ? void 0 : ne.call(H, "-webkit-touch-callout", "none")) }), q = t.useRef({ prev: [(re = c.current) == null ? void 0 : re.selectionStart, (le = c.current) == null ? void 0 : le.selectionEnd, (ae = c.current) == null ? void 0 : ae.selectionDirection] });
  t.useImperativeHandle(l, () => c.current, []), t.useEffect(() => {
    let e = c.current, n = Q.current;
    if (!e || !n) return;
    U.current.value !== e.value && U.current.onChange(e.value), q.current.prev = [e.selectionStart, e.selectionEnd, e.selectionDirection];
    function d() {
      if (document.activeElement !== e) {
        G(null), z(null);
        return;
      }
      let o = e.selectionStart, m = e.selectionEnd, K = e.selectionDirection, b = e.maxLength, j = e.value, x = q.current.prev, C = -1, k = -1, D;
      if (j.length !== 0 && o !== null && m !== null) {
        let Ce = o === m, ke = o === j.length && j.length < b;
        if (Ce && !ke) {
          let O = o;
          if (O === 0) C = 0, k = 1, D = "forward";
          else if (O === b) C = O - 1, k = O, D = "backward";
          else if (b > 1 && j.length > 1) {
            let ee = 0;
            if (x[0] !== null && x[1] !== null) {
              D = O < x[1] ? "backward" : "forward";
              let Me = x[0] === x[1] && x[0] < b;
              D === "backward" && !Me && (ee = -1);
            }
            C = ee + O, k = ee + O + 1;
          }
        }
        C !== -1 && k !== -1 && C !== k && c.current.setSelectionRange(C, k, D);
      }
      let de = C !== -1 ? C : o, me = k !== -1 ? k : m, Pe = D ?? K;
      G(de), z(me), q.current.prev = [de, me, Pe];
    }
    if (document.addEventListener("selectionchange", d, { capture: !0 }), d(), document.activeElement === e && X(!0), !document.getElementById("input-otp-style")) {
      let o = document.createElement("style");
      if (o.id = "input-otp-style", document.head.appendChild(o), o.sheet) {
        let m = "background: transparent !important; color: transparent !important; border-color: transparent !important; opacity: 0 !important; box-shadow: none !important; -webkit-box-shadow: none !important; -webkit-text-fill-color: transparent !important;";
        V(o.sheet, "[data-input-otp]::selection { background: transparent !important; color: transparent !important; }"), V(o.sheet, `[data-input-otp]:autofill { ${m} }`), V(o.sheet, `[data-input-otp]:-webkit-autofill { ${m} }`), V(o.sheet, "@supports (-webkit-touch-callout: none) { [data-input-otp] { letter-spacing: -.6em !important; font-weight: 100 !important; font-stretch: ultra-condensed; font-optical-sizing: none !important; left: -1px !important; right: 1px !important; } }"), V(o.sheet, "[data-input-otp] + * { pointer-events: all !important; }");
      }
    }
    let v = () => {
      n && n.style.setProperty("--root-height", `${e.clientHeight}px`);
    };
    v();
    let h = new ResizeObserver(v);
    return h.observe(e), () => {
      document.removeEventListener("selectionchange", d, { capture: !0 }), h.disconnect();
    };
  }, []);
  let [oe, ie] = t.useState(!1), [$, X] = t.useState(!1), [T, G] = t.useState(null), [I, z] = t.useState(null);
  t.useEffect(() => {
    Ie(() => {
      var e, n, d, v;
      (e = c.current) == null || e.dispatchEvent(new Event("input"));
      let h = (n = c.current) == null ? void 0 : n.selectionStart, o = (d = c.current) == null ? void 0 : d.selectionEnd, m = (v = c.current) == null ? void 0 : v.selectionDirection;
      h !== null && o !== null && (G(h), z(o), q.current.prev = [h, o, m]);
    });
  }, [s, $]), t.useEffect(() => {
    R !== void 0 && s !== R && R.length < p && s.length === p && (N == null || N(s));
  }, [p, N, R, s]);
  let _ = Le({ containerRef: Q, inputRef: c, pushPasswordManagerStrategy: A, isFocused: $ }), ue = t.useCallback((e) => {
    let n = e.currentTarget.value.slice(0, p);
    if (n.length > 0 && w && !w.test(n)) {
      e.preventDefault();
      return;
    }
    typeof R == "string" && n.length < R.length && document.dispatchEvent(new Event("selectionchange")), L(n);
  }, [p, L, R, w]), se = t.useCallback(() => {
    var e;
    if (c.current) {
      let n = Math.min(c.current.value.length, p - 1), d = c.current.value.length;
      (e = c.current) == null || e.setSelectionRange(n, d), G(n), z(d);
    }
    X(!0);
  }, [p]), ce = t.useCallback((e) => {
    var n, d;
    let v = c.current;
    if (!f && (!U.current.isIOS || !e.clipboardData || !v)) return;
    let h = e.clipboardData.getData("text/plain"), o = f ? f(h) : h;
    e.preventDefault();
    let m = (n = c.current) == null ? void 0 : n.selectionStart, K = (d = c.current) == null ? void 0 : d.selectionEnd, b = (m !== K ? s.slice(0, m) + o + s.slice(K) : s.slice(0, m) + o + s.slice(m)).slice(0, p);
    if (b.length > 0 && w && !w.test(b)) return;
    v.value = b, L(b);
    let j = Math.min(b.length, p - 1), x = b.length;
    v.setSelectionRange(j, x), G(j), z(x);
  }, [p, L, w, s]), Se = t.useMemo(() => ({ position: "relative", cursor: u.disabled ? "default" : "text", userSelect: "none", WebkitUserSelect: "none", pointerEvents: "none" }), [u.disabled]), pe = t.useMemo(() => ({ position: "absolute", inset: 0, width: _.willPushPWMBadge ? `calc(100% + ${_.PWM_BADGE_SPACE_WIDTH})` : "100%", clipPath: _.willPushPWMBadge ? `inset(0 ${_.PWM_BADGE_SPACE_WIDTH} 0 0)` : void 0, height: "100%", display: "flex", textAlign: M, opacity: "1", color: "transparent", pointerEvents: "all", background: "transparent", caretColor: "transparent", border: "0 solid transparent", outline: "0 solid transparent", boxShadow: "none", lineHeight: "1", letterSpacing: "-.5em", fontSize: "var(--root-height)", fontFamily: "monospace", fontVariantNumeric: "tabular-nums" }), [_.PWM_BADGE_SPACE_WIDTH, _.willPushPWMBadge, M]), ye = t.useMemo(() => t.createElement("input", Ae(We({ autoComplete: u.autoComplete || "one-time-code" }, u), { "data-input-otp": !0, "data-input-otp-placeholder-shown": s.length === 0 || void 0, "data-input-otp-mss": T, "data-input-otp-mse": I, inputMode: F, pattern: w == null ? void 0 : w.source, "aria-placeholder": W, style: pe, maxLength: p, value: s, ref: c, onPaste: (e) => {
    var n;
    ce(e), (n = u.onPaste) == null || n.call(u, e);
  }, onChange: ue, onMouseOver: (e) => {
    var n;
    ie(!0), (n = u.onMouseOver) == null || n.call(u, e);
  }, onMouseLeave: (e) => {
    var n;
    ie(!1), (n = u.onMouseLeave) == null || n.call(u, e);
  }, onFocus: (e) => {
    var n;
    se(), (n = u.onFocus) == null || n.call(u, e);
  }, onBlur: (e) => {
    var n;
    X(!1), (n = u.onBlur) == null || n.call(u, e);
  } })), [ue, se, ce, F, pe, p, I, T, u, w == null ? void 0 : w.source, s]), Y = t.useMemo(() => ({ slots: Array.from({ length: p }).map((e, n) => {
    var d;
    let v = $ && T !== null && I !== null && (T === I && n === T || n >= T && n < I), h = s[n] !== void 0 ? s[n] : null, o = s[0] !== void 0 ? null : (d = W == null ? void 0 : W[n]) != null ? d : null;
    return { char: h, placeholderChar: o, isActive: v, hasFakeCaret: v && h === null };
  }), isFocused: $, isHovering: !u.disabled && oe }), [$, oe, p, I, T, u.disabled, s]), xe = t.useMemo(() => y ? y(Y) : t.createElement($e.Provider, { value: Y }, B), [B, Y, y]);
  return t.createElement(t.Fragment, null, g !== null && t.createElement("noscript", null, t.createElement("style", null, g)), t.createElement("div", { ref: Q, "data-input-otp-container": !0, style: Se, className: P }, xe, t.createElement("div", { style: { position: "absolute", inset: 0, pointerEvents: "none" } }, ye)));
});
be.displayName = "Input";
function V(r, l) {
  try {
    r.insertRule(l);
  } catch {
    console.error("input-otp could not insert CSS rule:", l);
  }
}
var Ge = `
[data-input-otp] {
  --nojs-bg: white !important;
  --nojs-fg: black !important;

  background-color: var(--nojs-bg) !important;
  color: var(--nojs-fg) !important;
  caret-color: var(--nojs-fg) !important;
  letter-spacing: .25em !important;
  text-align: center !important;
  border: 1px solid var(--nojs-fg) !important;
  border-radius: 4px !important;
  width: 100% !important;
}
@media (prefers-color-scheme: dark) {
  [data-input-otp] {
    --nojs-bg: black !important;
    --nojs-fg: white !important;
  }
}`, ze = "^\\d+$";
const Ve = ({
  isActive: r,
  char: l,
  hasFakeCaret: a,
  hasError: i
}) => /* @__PURE__ */ Re(
  "div",
  {
    className: te(
      "relative flex size-s-6 shrink-0 items-center justify-center rounded-sm border border-[--color-input-stroke] text-body1 text-[--color-input-content] transition-all",
      {
        "border-[--color-input-stroke-active]": r,
        "border-[--color-input-stroke-danger]": i
      }
    ),
    children: [
      l,
      a && /* @__PURE__ */ Z("div", { className: "pointer-events-none absolute flex items-center justify-center", children: /* @__PURE__ */ Z("div", { className: "h-s-3 w-[2px] animate-caret-blink rounded-full bg-text-primary duration-1000" }) })
    ]
  }
), qe = Te(
  ({ className: r, containerClassName: l, hasError: a, ...i }, E) => /* @__PURE__ */ Z(
    be,
    {
      ref: E,
      pattern: ze,
      maxLength: 6,
      render: ({ slots: p }) => p.map((M, S) => /* @__PURE__ */ Z(Ve, { ...M, hasError: a }, S)),
      containerClassName: te(
        "flex items-center gap-sp-2 has-[:disabled]:opacity-70",
        l
      ),
      className: te(r),
      ...i
    }
  )
);
qe.displayName = "InputOTP";
export {
  qe as InputOTP
};
