import { immigrationCitizenship } from "./citizenship";
import { immigrationElse } from "./else";
import { immigrationFianceVisa } from "./fiance-visa";
import { immigrationGreenCard } from "./green-card";
import { immigrationOtherVisa } from "./other-visa";
import { immigrationReschedule } from "./reschedule";

export const immigrationFlow = {
  "green-card": immigrationGreenCard,
  citizenship: immigrationCitizenship,
  "fiance-visa": immigrationFianceVisa,
  "other-visa": immigrationOtherVisa,
  else: immigrationElse,
  reschedule: immigrationReschedule,
};
