import { useEffect, useState } from "react";

export const useIsMount = (): boolean => {
  const [isMount, setIsMount] = useState<boolean>(false);

  useEffect(() => {
    setIsMount(true);
  }, []);

  return isMount;
};
