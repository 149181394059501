import { calculateBiddingModel } from "../../visitor-checks/calculateBiddingModel";
import { calculateLeadScore } from "../../visitor-checks/calculateLeadScore";
import { stepsNames } from "../config.const";
import type { SubPractices } from "../config.types";

export const familyAlimony: SubPractices = {
  firstScreen: "service-type",
  screens: {
    "service-type": {
      next: async () => "zip-code",
    },
    "zip-code": {
      next: async ({ answers, experiments, meta }) => {
        const { abTestMarathon2 } = experiments;
        const { state } = answers;
        const { skip } = meta;

        if (skip) {
          return "state-selection";
        }

        if (state === "unsupported") {
          return "unsupported";
        }

        return abTestMarathon2 ? "case-urgency" : "location-confirmation";
      },
    },
    "state-selection": {
      next: async ({ answers, experiments }) => {
        const { abTestMarathon2 } = experiments;
        const { state } = answers;

        if (state === "unsupported") {
          return "unsupported";
        }

        return abTestMarathon2 ? "case-urgency" : "location-confirmation";
      },
    },
    unsupported: {
      next: async () => "unsupported-confirmed",
      sanitize: true,
    },
    "unsupported-confirmed": {
      next: async () => "unsupported-confirmed",
    },
    "location-confirmation": {
      next: async () => "case-urgency",
    },
    "case-urgency": {
      next: async () => "more-info",
    },
    "more-info": {
      next: async ({ answers }) => {
        calculateLeadScore({ answers, step: stepsNames["more-info"] });
        calculateBiddingModel({
          answers,
          step: stepsNames["more-info"],
        });
        return "create-meeting";
      },
    },
    "create-meeting": {
      next: async () => "contact-info",
    },
    "contact-info": {
      next: async ({ answers }) => {
        calculateBiddingModel({
          answers,
          step: "contact-info",
        });

        return "intake-confirmation";
      },
      isAuth: true,
      sanitize: true,
    },
    "intake-confirmation": {
      next: async () => undefined,
    },
    "out-of-scope": {
      next: async () => undefined,
    },
  },
};
