import React from "react";
import Balancer from "react-wrap-balancer";
import { Builder } from "@builder.io/react";

import { deprecatedColors } from "@/styles/deprecated-colors";

import { H1, H2, H3, H4, H5 } from "./Heading.styled";
import type { ComponentList, HeadingProps } from "./Heading.types";
import { HeadingTypes } from "./Heading.types";

const Headings: ComponentList = {
  H1,
  H2,
  H3,
  H4,
  H5,
};

export const Heading = ({
  color,
  text,
  type,
  children,
  customColor, // customColor is passed from the TextAnimation component
  balanceText,
  attributes: builderAttributes,
}: HeadingProps) => {
  const Component = Headings[type];
  const content = children ?? text;
  const { key, ...restBuilderAttributes } = builderAttributes || {};

  return (
    <Component
      color={color}
      customColor={customColor}
      key={key}
      {...restBuilderAttributes}
    >
      {balanceText ? <Balancer>{content}</Balancer> : content}
    </Component>
  );
};

Builder.registerComponent(Heading, {
  name: "Heading",
  hideFromInsertMenu: true,
  noWrap: true,
  inputs: [
    {
      name: "balanceText",
      type: "boolean",
      defaultValue: false,
      helperText:
        "Balances the number of characters on each line, enhancing layout quality and legibility",
    },
    {
      name: "text",
      type: "string",
      defaultValue: "Heading placeholder",
      helperText: "Displayed text",
    },
    {
      name: "type",
      friendlyName: "Variant",
      type: "string",
      defaultValue: "H2",
      enum: [...Object.keys(HeadingTypes)],
      helperText: "Variant of the heading",
    },
    {
      name: "color",
      type: "string",
      defaultValue: "b100",
      enum: [...Object.keys(deprecatedColors)],
      helperText: "Color of the text",
    },
  ],
});
