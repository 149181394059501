import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import { isBrowser } from "@/utils/isBrowser";

import type { SeoComponent } from "./SEO.types";
import { DEFAULT_SEO } from "./SEO.types";

type SEOProps = SeoComponent & {
  openGraphImage?: string;
  schemaMarkup?: object;
};

export const SEO = (props: SEOProps) => {
  const router = useRouter();
  const hasBrowser = isBrowser();
  const { noIndex, ...seoProps } = props;
  const seo = { ...DEFAULT_SEO, ...seoProps };
  const schemaMarkup = seo?.schemaMarkup;

  const title = seo.title || "";
  const description = seo.description || "";
  const ogImage = seo.openGraphImage || "";

  const href =
    seo.canonicalHref ||
    `${hasBrowser ? window?.location?.origin : "https://www.marble.co"}${
      router?.asPath !== "/index" ? router?.asPath : "/"
    }`;

  return (
    <Head>
      {/* Primary Meta tags */}
      <meta content={title} key="title" />
      <title>{title}</title>
      <meta name="language" content="English" key="language" />

      <meta name="description" content={description} key="description" />
      <link rel="canonical" href={href} key="canonical" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:title" content={title} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:url" content={href} key="og:url" />
      <meta property="og:image" content={ogImage} key="og:image" />
      <meta property="og:image:width" content="1500" key="og:image:width" />
      <meta property="og:image:height" content="786" key="og:image:height" />

      {/* Twitter */}
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta name="twitter:image" content={ogImage} key="twitter:image" />
      <meta property="twitter:url" content={href} key="twitter:url" />
      <meta property="twitter:card" content={title} key="twitter:card" />

      {schemaMarkup && (
        <script
          type="application/ld+json"
          // @ts-expect-error: implicit any
          key={`${schemaMarkup?.["@type"]}-jsonld`}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaMarkup) }}
        />
      )}

      {noIndex && <meta name="robots" content="noindex" key="robots" />}
    </Head>
  );
};
