import React, { useMemo } from "react";
import { Builder } from "@builder.io/react";
import { ConfigProvider } from "antd";

import { deprecatedColors } from "@/styles/deprecated-colors";

import { DesktopNavbar, MobileNavbar } from "./components";
import { NavbarBuilderProps } from "./Navbar.builder.props";
import { GlobalStyles } from "./Navbar.styled";
import type { NavbarItem, NavbarProps } from "./Navbar.types";
import { parseBuilderPropToMenuItems } from "./Navbar.utils";

export const Navbar = (props: NavbarProps) => {
  const { items, builderState } = props;
  const parsedItems = useMemo(
    () => parseBuilderPropToMenuItems(items),
    [items],
  );

  const isMobile =
    builderState.state.deviceSize === "small" ||
    builderState.state.device === "mobile";

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Roobert",
          colorPrimary: deprecatedColors.primary,
          colorText: deprecatedColors.b100,
          colorBgTextHover: deprecatedColors.g20,
          fontSize: 18,
          lineHeight: 0.64,
          controlHeightLG: 72,
          colorIcon: deprecatedColors.primary,
        },
      }}
    >
      <GlobalStyles />
      {isMobile ? (
        <MobileNavbar {...props} items={parsedItems as NavbarItem[]} />
      ) : (
        <DesktopNavbar {...props} items={parsedItems as NavbarItem[]} />
      )}
    </ConfigProvider>
  );
};

Builder.registerComponent(Navbar, {
  name: "Navbar",
  inputs: NavbarBuilderProps,
});
