import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import _omitBy from "lodash/omitBy";

import { isBrowser } from "@/utils/isBrowser";

import {
  CAMPAIGN_DATA_STORAGE_KEY,
  getParsedItem,
  setItem,
} from "../../storage";

import type {
  CampaignData,
  ParseCampaignDataParams,
} from "./campaign-data-context.types";
import {
  DEFAULT_CAMPAIGN_DATA,
  SEARCH_ENGINE_LIST,
} from "./campaign-data-context-defaults";

const paramsMapping: Record<keyof CampaignData, string[]> = {
  source: ["utm_source", "source"],
  campaignId: ["utm_campaign", "campaignId"],
  adgroupId: ["utm_adset", "adgroupid"],
  keyword: ["utm_term", "utm_keyword", "keyword", "term"],
  content: ["utm_content"],
  medium: ["utm_medium", "medium"],
  marbleCategory: ["marble_cat", "utm_marble_cat"],
  gclid: ["gclid"],
  fbclid: ["fbclid"],
  ttclid: ["ttclid"],
  msclkid: ["msclkid"],
  referrer: [],
  affiliateId: ["affiliate_id"],
  matchLocation: ["match_location"],
  matchType: ["match_type"],
  searchNetwork: ["network"],
  mcidtype: ["mcidtype"],
};

// per data team request, we are not sending the referer for any in app navigation
export const REFERRAL_INVALID_LIST = ["localhost", "www.marble.co"];

export function isUrlInvalidAsReferrer(referrer: string): boolean {
  return !!REFERRAL_INVALID_LIST.find((invalidReferer: string) =>
    referrer.includes(invalidReferer),
  );
}

export function getValidCurrentUrlForReferrer() {
  if (!isBrowser()) {
    return "";
  }
  const urlPath = document.location.href;
  const currentUrl = isUrlInvalidAsReferrer(urlPath) ? "" : urlPath;
  return currentUrl;
}

function extractCampaignDataFromUrl(): CampaignData {
  const params = new URLSearchParams(location.search);
  const extractedUrlData: CampaignData = DEFAULT_CAMPAIGN_DATA;
  Object.entries(paramsMapping).forEach(([paramName, paramUrlOptions]) => {
    paramUrlOptions.forEach((paramUrlOption) => {
      const paramValue = params.get(paramUrlOption);
      if (paramValue) {
        extractedUrlData[paramName as keyof CampaignData] = paramValue;
      }
    });
  });

  return extractedUrlData;
}

function overrideLocalStorageCampaignData(
  campaignData: Partial<CampaignData>,
): CampaignData {
  const currentCampaignData =
    getParsedItem(CAMPAIGN_DATA_STORAGE_KEY) ?? DEFAULT_CAMPAIGN_DATA;
  const updatedCampaignData: CampaignData = _merge(
    currentCampaignData,
    campaignData,
  );
  setItem(CAMPAIGN_DATA_STORAGE_KEY, JSON.stringify(updatedCampaignData));
  return updatedCampaignData;
}

function hasCampaignData(campaignData: CampaignData): boolean {
  if (!campaignData) {
    return false;
  }
  const campaignDataWithoutNull = _omitBy(campaignData, (v) => v === null);
  return !_isEmpty(campaignDataWithoutNull);
}

export function parseCampaignData({
  urlCampaignData,
  referrer,
}: ParseCampaignDataParams) {
  const storageCampaignData = getParsedItem(CAMPAIGN_DATA_STORAGE_KEY);
  const hasStorageCampaignData = hasCampaignData(storageCampaignData);
  const isFromSearchEngine = Boolean(
    SEARCH_ENGINE_LIST.find((item) => {
      return referrer?.toLowerCase().includes(item.toLowerCase());
    }),
  );
  const urlCampaignDataWithoutNull = _omitBy(
    urlCampaignData,
    (v) => v === null,
  );

  urlCampaignDataWithoutNull.referrer = referrer;

  const isPaidTraffic = urlCampaignData?.medium === "cpc";
  if (isPaidTraffic) {
    const overrideUTMData = {
      ...urlCampaignDataWithoutNull,
      source: urlCampaignData.source ? urlCampaignData.source : referrer,
    };
    const updatedCampaignData =
      overrideLocalStorageCampaignData(overrideUTMData);
    return updatedCampaignData;
  }

  const isAffiliateTraffic = urlCampaignData?.source === "affiliate";
  if (isAffiliateTraffic) {
    const overrideUTMData = {
      ...urlCampaignDataWithoutNull,
      source: "affiliate",
    };
    const updatedCampaignData =
      overrideLocalStorageCampaignData(overrideUTMData);
    return updatedCampaignData;
  }

  const isOrganicTraffic = isFromSearchEngine && !urlCampaignData?.medium;
  if (isOrganicTraffic) {
    const overrideUTMData = {
      ...urlCampaignDataWithoutNull,
      source: referrer,
      medium: "organic",
    };
    const updatedCampaignData =
      overrideLocalStorageCampaignData(overrideUTMData);
    return updatedCampaignData;
  }

  const isReferralTraffic =
    !!referrer &&
    !isFromSearchEngine &&
    (!urlCampaignData?.medium || urlCampaignData?.medium !== "cpc");
  if (isReferralTraffic) {
    const overrideUTMData = {
      ...urlCampaignDataWithoutNull,
      source: urlCampaignData.source ? urlCampaignData.source : referrer,
      medium: urlCampaignData.medium ? urlCampaignData.medium : "referral",
    };
    const updatedCampaignData =
      overrideLocalStorageCampaignData(overrideUTMData);
    return updatedCampaignData;
  }

  const isDirectTraffic =
    !referrer && !urlCampaignData?.medium && !urlCampaignData?.source;
  if (isDirectTraffic) {
    if (hasStorageCampaignData) return storageCampaignData;

    const overrideUTMData = {
      source: "direct",
      medium: "direct",
    };
    const updatedCampaignData =
      overrideLocalStorageCampaignData(overrideUTMData);
    return updatedCampaignData;
  }

  if (hasStorageCampaignData) {
    return storageCampaignData;
  }

  const updatedCampaignData = overrideLocalStorageCampaignData(
    urlCampaignDataWithoutNull,
  );
  return updatedCampaignData;
}

export function extractCampaignData(): CampaignData {
  if (!isBrowser()) {
    return DEFAULT_CAMPAIGN_DATA;
  }

  const urlCampaignData = extractCampaignDataFromUrl();
  const referrer = document.referrer;

  return parseCampaignData({ urlCampaignData, referrer });
}
