import * as n from "react";
import * as f from "react-dom";
import { S as p } from "./index-DoOsnuB6.js";
import { jsx as d } from "react/jsx-runtime";
var u = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], v = u.reduce((r, i) => {
  const t = n.forwardRef((o, e) => {
    const { asChild: a, ...s } = o, m = a ? p : i;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ d(m, { ...s, ref: e });
  });
  return t.displayName = `Primitive.${i}`, { ...r, [i]: t };
}, {});
function h(r, i) {
  r && f.flushSync(() => r.dispatchEvent(i));
}
export {
  v as P,
  h as d
};
