import { jsx as s } from "react/jsx-runtime";
import { c as o } from "../../index-DFo-3WCJ.js";
import { c as t } from "../../index-Bm_zk_a7.js";
const m = o("", {
  variants: {
    size: {
      sm: "size-s-2",
      md: "size-s-3",
      lg: "size-s-4"
    }
  }
});
function p({ className: i, name: e, size: r = "md", ...a }) {
  return /* @__PURE__ */ s(
    "svg",
    {
      className: t(
        m({ size: r, className: i }),
        // Handles the size of icons when they are used in a button that has a group class
        "group-data-[size=lg]:size-s-3 group-data-[size=md]:size-s-3 group-data-[size=sm]:size-s-2"
      ),
      ...a,
      children: /* @__PURE__ */ s("use", { href: `/sprite.svg#${e}` })
    }
  );
}
export {
  p as Icon
};
