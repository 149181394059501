import { userAgentFromString } from "next/server";

import breakpoints from "@/styles/breakpoints";

import type { FunnelAnswers } from "../config";
import type { LeadScoreParams } from "../types";

export function getLeadScoreParams(answers: FunnelAnswers) {
  const { browser, device } = userAgentFromString(window?.navigator.userAgent);

  const {
    serviceType,
    caseUrgency,
    moreInfo,
    campaignId,
    visitsCount,
    sessionStartTime,
  } = answers;

  const isDesktop = window?.innerWidth > breakpoints.sm;

  let deviceType = device.type;

  if (!deviceType) {
    deviceType = isDesktop ? "desktop" : "mobile";
  }

  const leadScoreParams: LeadScoreParams = {
    deviceType,
    deviceVendor: device.vendor,
    deviceModel: device.model,
    browser: browser.name,
    serviceType: serviceType ?? "",
    caseUrgency: caseUrgency ?? "",
    moreInfo,
    hasCampaign: Boolean(campaignId),
    visitsCount,
    sessionStartTime,
    browserVersion: browser.version,
  };

  return leadScoreParams;
}
